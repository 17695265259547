/* eslint-disable @banno/ux/element-annotated */
import {LitElement, html, css} from 'lit-element';
import {jhaStyles} from '@banno/platform-ux-shared/styles/jha-styles';
import '@banno/platform-ux-shared/components/polymer3/jha/cards/jha-card-platform';
import '@banno/platform-ux-shared/components/polymer3/jha/grid/jha-grid';
import '@banno/platform-ux-shared/components/polymer3/jha/buttons/jha-link-button';

const styles = css`
  :host {
    border-radius: 6px;
  }
  .bold {
    font-size: 13px;
    font-weight: 500;
  }
  .statement-date {
    margin-left: auto;
  }
  #statement-image-wrapper > div {
    font-size: 11px;
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 6px 6px 0 0;
  }
  #statement-image-wrapper img {
    width: 100%;
  }
  #statement-card article {
    padding-top: 20px;
    margin-bottom: 0;
  }
  header {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }
  p {
    margin: 0;
  }
  footer {
    text-align: left;
    font-weight: 500;
    border-top: 1px solid var(--jha-border-color);
    font-size: 14px;
  }
`;

class StatementCard extends LitElement {
  static get styles() {
    return [jhaStyles, styles];
  }

  static get properties() {
    return {
      loginDashboardDetails: {type: Object},
    };
  }

  _formatStatementDate(statementDate) {
    if (!statementDate) return '';
    const statementDateObj = new Date(Date.parse(statementDate));
    return statementDateObj ? `${statementDateObj.toLocaleString('default', {month: 'long', timeZone: 'UTC'})} ${statementDateObj.getUTCFullYear() || ''}` : '';
  }

  render() {
    return html`
      <jha-card-platform id="statement-card">
        <div id="statement-image-wrapper">
          ${this.loginDashboardDetails.imageUrl && html`
            <img src="${this.loginDashboardDetails.imageUrl}" alt="${this.loginDashboardDetails.title}">
          `}
        </div>
        ${this.loginDashboardDetails.title && html`
          <header>
            ${this.loginDashboardDetails.title}
          </header>
        `}
        <article>
          <p>Get caught up on the latest updates.</p>
        </article>
        <footer>
          <jha-link-button link footer href="${this.loginDashboardDetails.statementUrl}" target="_blank">
            View statement
          </jha-link-button>
        </footer>
      </jha-card-platform>
    `;
  }
}

export {styles as StatementCardStyles};

customElements.define('statement-card', StatementCard); // eslint-disable-line @banno/ux/custom-elements-define
export default StatementCard;
