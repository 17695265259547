/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconChevronBackElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-chevron-back';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.4696699,5.46966991 C13.7625631,5.1767767 14.2374369,5.1767767 14.5303301,5.46966991 C14.7965966,5.73593648 14.8208027,6.15260016 14.6029482,6.44621165 L14.5303301,6.53033009 L9.061,12 L14.5303301,17.4696699 C14.7965966,17.7359365 14.8208027,18.1526002 14.6029482,18.4462117 L14.5303301,18.5303301 C14.2640635,18.7965966 13.8473998,18.8208027 13.5537883,18.6029482 L13.4696699,18.5303301 L7.46966991,12.5303301 C7.20340335,12.2640635 7.1791973,11.8473998 7.39705176,11.5537883 L7.46966991,11.4696699 L13.4696699,5.46966991 Z" /></svg>
  `;
  }
};

customElements.define(window.JhaIconChevronBackElement.is,
    window.JhaIconChevronBackElement);
export default window.JhaIconChevronBackElement;
