import { LitElement, css, html } from 'lit-element';
import { dialogStyles } from '@banno/platform-ux-shared/styles/dialog.styles.js';
import { fillStyles } from '@banno/platform-ux-shared/styles/fill.styles';

class BaseDialog extends LitElement {
  static get is() {
    return 'base-dialog';
  }

  static get styles() {
    return [
      fillStyles,
      dialogStyles,
      css`
        .close {
          position: absolute;
          right: 24px;
          cursor: pointer;
        }
        .close-icon {
          width: 18px;
          float: right;
        }
        .push-right {
          display: inline-block;
          margin-right: 20px;
        }
        .caption {
          text-align: left;
          margin-bottom: 15px;
          text-transform: uppercase;
          font-weight: 500;
        }
        a {
          color: var(--primaryAppColor, #006ee4);
          display: inline-block;
          cursor: pointer;
        }
      `,
    ];
  }

  _handleClose() {
    this.dispatchEvent(
      new CustomEvent('closeModal', { bubbles: true, composed: true })
    );
  }

  render() {
    return html`
      <header>
        <div class="push-right">
          <slot class="dialog_title" name="header"></slot>
        </div>
        <jha-button class="close" icon sync @click=${this._handleClose}>
          <jha-icon-close class="u-fill-muted close-icon"></jha-icon-close>
        </jha-button>
      </header>
      <slot name="well"></slot>
      <article>
        <slot name="content"></slot>
      </article>
      <slot name="footer"></slot>
    `;
  }
}

window.customElements.define(BaseDialog.is, BaseDialog);
export default BaseDialog;
