import {LitElement, html, css} from 'lit-element';
import {jhaStyles} from '@banno/platform-ux-shared/styles/jha-styles';
import DialogMixin from '@banno/platform-ux-shared/mixins/dialog.js';
import store from '@banno/platform-ux-shared/session-storage/store';
import '@banno/platform-ux-shared/components/polymer3/jha/avatars/jha-avatar';
import '@banno/platform-ux-shared/components/polymer3/jha/progress/jha-progress';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-chevron-back';
import '@banno/platform-ux-shared/components/polymer3/user-avatar/user-avatar';
import './jha-enterprise-institution-selector.js';
import './mobile-header';
import './navigation/jha-enterprise-nav';
import './navigation/jha-enterprise-nav-menu-button';
import './navigation/navigation-documentation-dialog.js';
import './navigation/institution-and-user-details-dialog.js';

const styles = css`
  :host {
    display: flex;
    width: 100vw;
  }
  header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  #app-wrapper {
    width: calc(100% - 320px);
    left: 320px;
    position: relative;
  }
  #app-wrapper[secondary-nav-hidden=true] {
    left: 96px;
    width: calc(100% - 96px);
  }
  #app-wrapper[builder-mode] {
    left: 0px;
    top: 0px;
    width: 100%;
    display: block;
    min-height: 100vh;
    position: absolute;
  }
  mobile-header {
    display: none;
  }
  h4 {
    flex: 1;
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    margin: 0;
  }
  :host([dashboard]) h4 {
    display: none;
  }
  #avatar-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 34px;
  }
  @media (max-width: 1024px) {
    mobile-header {
      display: block;
      padding: 16px;
    }
    header jha-enterprise-nav-menu-button,
    header > div {
      flex: 1;
      flex-grow: 0;
    }
    h4 {
      flex-grow: 1;
    }
    :host([dashboard]) mobile-header {
      background-color: var(--jha-text-theme);
      color: var(--jha-text-white);
    }
    mobile-header[fixed='true'] {
      animation: inDown ease-in-out 0.3s;
      position: fixed;
      top: 0px;
      z-index: 100;
      width: 100%;
      background-color: var(--jha-background-color);
      color: var(--jha-text-base);
    }
    :host([dashboard]) mobile-header[fixed='true'] {
      background-color: var(--jha-background-color);
      color: var(--jha-text-base);
    }
    :host([dashboard]) mobile-header[fixed='true'] h4 {
      display: block;
    }
    #app-wrapper,
    #app-wrapper[secondary-nav-hidden=true] {
      left: 0px;
      width: 100%;
    }
  }
`;

class EnterpriseWrapper extends DialogMixin(LitElement) {
  static get styles() {
    return [jhaStyles, styles];
  }

  static get properties() {
    return {
      mobileNavOpen: {type: Boolean},
      headerTitle: {type: String},
      loggedInUser: {type: Object},
      selectedInstitution: {type: Object},
      institutionSelected: {type: Boolean},
      dashboard: {type: Boolean},
      isSecondaryNavHidden: {type: Boolean},
      waitingSelectedInstitution: {type: Boolean},
      showBackButton: {type: Boolean},
      backButtonPath: {type: String},
      fixedMenu: {type: Boolean},
      isBuilderMode: {type: Boolean}
    };
  }

  constructor() {
    super();
    this.institutionSelected = false;
    this.mobileNavOpen = false;
    this.selectedInstitution = {};
    this.dashboard = false;
    this.waitingSelectedInstitution = false;
    this.fixedMenu = false;
    this.isBuilderMode = false;
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('toggle-mobile-nav', this._handleToggleMobileNav);
    this.addEventListener('open-nav-documentation', this._handleOpenNavDocumentation);
    this.addEventListener('open-institution-and-user-details', this._handleInstitutionAndUserDetails);
    this.addEventListener('selected-project-change', this._handleSelectedProjectChanged);
    this.addEventListener('hide-subnav', this._handleIsSecondaryNavHidden);
    this.addEventListener('toggle-builder-mode', this._handleToggleBuilderMode);
    this.isSecondaryNavHidden = this.dashboard;
    const storedSelectedInstitution = store.getSelectedInstitution();
    this.loggedInUser = store.user || {};
    if (storedSelectedInstitution) {
      this.selectedInstitution = storedSelectedInstitution;
      this.institutionSelected = true;
    } else {
      if (this.dashboard) {
        setTimeout(() => {
          this._openInstitutionSelector();
        }, 100);
      } else {
        this.waitingSelectedInstitution = true;
      }
    }
    this._boundScrollThrottle = this._scrollThrottle.bind(this);
    document.addEventListener('scroll', this._boundScrollThrottle, false);
    document.addEventListener('avatar-url-updated', this.avatarUrlUpdated_.bind(this));
  }

  _scrollThrottle() {
    clearTimeout(this.wait);
    this.wait = setTimeout(() => {
      this._checkScrollPosition();
    }, 50); // eslint-disable-line no-magic-numbers
  }

  _checkScrollPosition() {
    const mobileHeaderEl = this.shadowRoot.querySelector('mobile-header');
    if (mobileHeaderEl && window.document.scrollingElement.scrollTop > mobileHeaderEl.clientHeight) {
      this.fixedMenu = true;
    } else {
      this.fixedMenu = false;
    }
  }

  everythingIsLoaded() {
    const storedSelectedInstitution = store.getSelectedInstitution();
    if (storedSelectedInstitution) {
      this.selectedInstitution = storedSelectedInstitution;
      this.institutionSelected = true;
      this.waitingSelectedInstitution = false;
      this.loggedInUser = store.user || {};
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('toggle-mobile-nav', this._handleToggleMobileNav);
    this.removeEventListener('open-nav-documentation', this._handleOpenNavDocumentation);
    this.removeEventListener('open-institution-and-user-details', this._handleInstitutionAndUserDetails);
    this.removeEventListener('selected-project-change', this._handleSelectedProjectChanged);
    this.removeEventListener('hide-subnav', this._handleIsSecondaryNavHidden);
    this.removeEventListener('toggle-builder-mode', this._handleToggleBuilderMode);
    document.removeEventListener('scroll', this._boundScrollThrottle, false);
    document.removeEventListener('avatar-url-updated', this.avatarUrlUpdated_);
  }

  _openInstitutionSelector() {
    const institutionSelectorElement = document.createElement('jha-enterprise-institution-selector');
    institutionSelectorElement.addEventListener('close-institution-selector', () => {
      this.dispatchEvent(new CustomEvent('closeModal', {
        bubbles: true,
        composed: true
      }));
    });
    this.dispatchEvent(new CustomEvent('openModal', {
      detail: {
        el: institutionSelectorElement,
        disableBackdropClick: true
      },
      bubbles: true,
      composed: true
    }));
  }

  _handleToggleMobileNav() {
    this.mobileNavOpen = !this.mobileNavOpen;
  }

  _handleOpenNavDocumentation() {
    const navDocDialog = document.createElement('navigation-documentation-dialog');
    this.dispatchEvent(new CustomEvent('openModal', {
      detail: {el: navDocDialog},
      bubbles: true,
      composed: true
    }));
  }

  _handleInstitutionAndUserDetails() {
    const institutionAndUserDialog = document.createElement('institution-and-user-details-dialog');
    institutionAndUserDialog.loggedInUser = this.loggedInUser;
    institutionAndUserDialog.selectedInstitution = this.selectedInstitution;
    this.dispatchEvent(new CustomEvent('openModal', {
      detail: {el: institutionAndUserDialog},
      bubbles: true,
      composed: true
    }));
  }

  _handleSelectedProjectChanged(evt) {
    this.isSecondaryNavHidden = false;
  }

  _handleIsSecondaryNavHidden({detail: {hide}}) {
    this.isSecondaryNavHidden = hide;
  }


  _handleToggleBuilderMode() {
    this.isBuilderMode = true;
  }

  _goBack() {
    if (this.backButtonPath) {
      window.location.href = this.backButtonPath;
    } else {
      window.history.back();
    }
  }

  avatarUrlUpdated_(evt) {
    this._avatar = evt.detail.url;
  }


  renderNav() {
    // Hide all nav for builder mode (used in marketing and CMS)
    if (this.isBuilderMode && this.institutionSelected) {
      return html`
        <div id="app-wrapper" builder-mode>
          <slot name="app"></slot>
        </div>`;
    }

    return html`
      ${this.institutionSelected ? html`
        <jha-enterprise-nav
          .loggedInUser="${this.loggedInUser}"
          ?mobileNavOpen="${this.mobileNavOpen}"
          .selectedInstitution="${this.selectedInstitution}">
        </jha-enterprise-nav>
        <div id="app-wrapper" secondary-nav-hidden="${this.isSecondaryNavHidden}">
          <mobile-header fixed="${this.fixedMenu}">
            <header slot="header">
              ${this.showBackButton ?
                html`
                  <jha-button icon sync type="button" @click=${this._goBack}>
                    <jha-icon-chevron-back></jha-icon-chevron-back>
                  </jha-button>` :
                html`
                  <jha-enterprise-nav-menu-button ?dashboard="${(this.dashboard && !this.fixedMenu)}"></jha-enterprise-nav-menu-button>
              `}
              <h4>
                ${this._avatar ? html`
                  <user-avatar mobile-header src=${this._avatar}></user-avatar>
                ` : null}
                ${this.headerTitle}
              </h4>
              <div><slot name="mobile-header-button"></slot></div>
            </header>
          </mobile-header>
          <slot name="app"></slot>
        </div>
      ` : null}
    `;
  }
  render() {
    return html`${this.waitingSelectedInstitution ? html`<jha-progress card></jha-progress>` : this.renderNav()}`;
  }
}

export {styles as EnterpriseWrapperStyles};

customElements.define('enterprise-wrapper', EnterpriseWrapper); // eslint-disable-line @banno/ux/custom-elements-define
export default EnterpriseWrapper;
