/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconMenuElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-menu';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M19,8H5A1,1,0,0,1,5,6H19a1,1,0,0,1,0,2Z"/>
    <path d="M19,13H5a1,1,0,0,1,0-2H19a1,1,0,0,1,0,2Z"/>
    <path d="M19,18H5a1,1,0,0,1,0-2H19a1,1,0,0,1,0,2Z"/>
    </svg>
  `;
  }
};

customElements.define(window.JhaIconMenuElement.is,
    window.JhaIconMenuElement);
export default window.JhaIconMenuElement;
