/* jshint ignore:start  */
import {
  APP_KEYS_PATH,
  CONSUMER_PATH,
  DASHBOARD_PATH,
  DISPLAY_TEXT_PATH,
  GATEWAY_PATH,
  GRIP_ADMIN_PATH,
  OFFLINE_STATUS_PATH,
  REG_D_PATH,
  DISPLAY_TEXT_V1_PATH,
  PRINCIPAL_PAYMENTS_PATH,
  SYMX_CONFIGS
} from '../paths.js';

/* jshint ignore:end */
import JhaFetch from '@banno/platform-ux-shared/services/jha-fetch.js';
import XhrPromise from '@banno/banno-web/src/js/api/xhr-promise.js';

const microDepositConfigUrl =
  (institutionId) => `${CONSUMER_PATH}/v0/transfers/accounts/external/institution/${institutionId}/setup/config`;

export default class SettingsService {
  static getContact(institutionId) {
    const url = `${GRIP_ADMIN_PATH}/v1/institutions/${institutionId}/contact-info`;
    return JhaFetch.get(url);
  }

  static updateContact(institutionId, contact) {
    const url = `${GRIP_ADMIN_PATH}/v1/institutions/${institutionId}/contact-info`;
    return JhaFetch.put(url, contact);
  }

  static getEula(institutionId) {
    const url = `${GRIP_ADMIN_PATH}/institutions/${institutionId}/eula`;
    return JhaFetch.get(url, true);
  }

  static updateEula(institutionId, eula) {
    const url = `${GRIP_ADMIN_PATH}/institutions/${institutionId}/eula`;
    return JhaFetch.put(url, eula);
  }

  static updateEulaCustom(institutionId, eula) {
    const url = `${GRIP_ADMIN_PATH}/institutions/${institutionId}/eula/custom`;
    return JhaFetch.post(url, eula);
  }

  static getEulaHistory(institutionId) {
    const url = `${GRIP_ADMIN_PATH}/institutions/${institutionId}/eula/history`;
    return JhaFetch.get(url);
  }

  static getInstitutionDetails(institutionId) {
    const url = `${CONSUMER_PATH}/institutions/${institutionId}`;
    return JhaFetch.get(url);
  }

  static getInstitutionConfig(institutionId) {
    const url = `${SYMX_CONFIGS}/v0/platform/institutions/${institutionId}`;
    return JhaFetch.get(url);
  }

  static updateInstitutionConfig(institutionId, config) {
    const url = `${SYMX_CONFIGS}/v0/platform/institutions/${institutionId}`;
    return JhaFetch.patch(url, config);
  }

  static getInstitutionMicroDepositConfig(institutionId) {
    return JhaFetch.get(microDepositConfigUrl(institutionId));
  }

  static updateMicroDepositConfig(institutionId, config) {
    return JhaFetch.put(microDepositConfigUrl(institutionId), config);
  }

  static updateExternalTransferSettings(institutionId, externalSettings) {
    const url = `${GATEWAY_PATH}/v1/institutions/${institutionId}/transfer/settings/external`;
    return JhaFetch.put(url, externalSettings);
  }

  static updateInternalTransferSettings(institutionId, internalSettings) {
    const url = `${GATEWAY_PATH}/v1/institutions/${institutionId}/transfer/settings/internal`;
    return JhaFetch.put(url, internalSettings);
  }
  /**
   * @typedef {Object} PrincipalPaymentSettings
   * @param {Object} obj - An object.
   * @param {boolean} obj.enabled whether or not Principal payments are enabled for the institution
   * @param {string[]} obj.allowedLoans list of allowed loan types for the institution
   */

  /**
   * @param {string} institutionId
   * @returns {PrincipalPaymentSettings}
   */
  static getInstitutionPrincipalPayments(institutionId) {
    return JhaFetch.get(`${PRINCIPAL_PAYMENTS_PATH}/institutions/${institutionId}/transfer/principal/loan/configurability/settings`);
  }

  /**
   * @param {string} institutionId
   * @param {PrincipalPaymentSettings} settings
   */
  static updateInstitutionPrincipalPayments(institutionId, settings) {
    const url = `${PRINCIPAL_PAYMENTS_PATH}/institutions/${institutionId}/transfer/principal/loan/configurability/settings`;
    return JhaFetch.put(url, settings);
  }

  static getInstitutionMemoModeConfig(institutionId) {
    return JhaFetch.get(`${PRINCIPAL_PAYMENTS_PATH}/institutions/${institutionId}/transfer/external-transfers-allowed-during-memo-mode`);
  }

  static updateInstitutionMemoModeConfig(institutionId, settings) {
    const url = `${PRINCIPAL_PAYMENTS_PATH}/institutions/${institutionId}/transfer/external-transfers-allowed-during-memo-mode`;
    return JhaFetch.put(url, settings);
  }

  static getStopPaymentsDisclaimer(institutionId) {
    const url = `${GRIP_ADMIN_PATH}/institutions/${institutionId}/stops/disclaimer`;
    return JhaFetch.get(url);
  }

  static updateStopPaymentsDisclaimer(institutionId, disclaimer) {
    const url = `${GRIP_ADMIN_PATH}/institutions/${institutionId}/stops/disclaimer`;
    return JhaFetch.put(url, disclaimer);
  }

  static getStopPaymentsDisclaimerHistory(institutionId) {
    const url = `${GRIP_ADMIN_PATH}/institutions/${institutionId}/stops/disclaimer/history`;
    return JhaFetch.get(url);
  }

  static getRegDMessage(institutionId) {
    const url = `${REG_D_PATH}/institutions/${institutionId}/limitstext`;
    return JhaFetch.get(url);
  }

  static updateRegDMessage(institutionId, message, userId) {
    const url = `${REG_D_PATH}/institutions/${institutionId}/limitstext?userId=${userId}`;
    return JhaFetch.put(url, message);
  }

  static getDisplayTextMessage(institutionId, messageName) {
    const url = `${DISPLAY_TEXT_PATH}/institutions/${institutionId}/messages/${messageName}`;
    return JhaFetch.get(url);
  }

  static getDisplayTextMessageV1(institutionId, messageName) {
    const url = `${DISPLAY_TEXT_V1_PATH}/v1/institutions/${institutionId}/messages/${messageName}`;
    return JhaFetch.get(url);
  }

  static getOfflineStatus(institutionId) {
    const url = `${CONSUMER_PATH}/offline-status/institutions/${institutionId}`;
    const config = {
      api: {
        url: url,
      },
      method: 'GET',
      ignoreCache: true
    };
    return JhaFetch.fetch(config);
  }

  static updateOfflineStatus(institutionId, payload) {
    const url = `${OFFLINE_STATUS_PATH}/institutions/${institutionId}/status`;
    return JhaFetch.post(url, payload);
  }

  static getOfflineHistory(institutionId) {
    const url = `${OFFLINE_STATUS_PATH}/institutions/${institutionId}/history`;
    return JhaFetch.get(url);
  }

  static postDisplayTextMessage(institutionId, messageId, payload) {
    const url = `${DISPLAY_TEXT_PATH}/institutions/${institutionId}/messages/${messageId}/customized-messages`;
    return JhaFetch.post(url, payload);
  }

  static postDisplayTextMessageV1(institutionId, messageId, payload) {
    const url = `${DISPLAY_TEXT_V1_PATH}/api/institutions/${institutionId}/messages/${messageId}/customized-messages`;
    return JhaFetch.post(url, payload);
  }

  static getDocumentsDisclosures(institutionId) {
    const url = `${GRIP_ADMIN_PATH}/institutions/${institutionId}/documents/disclosures`;
    return JhaFetch.get(url);
  }

  static postDocumentsDisclosures(institutionId, payload) {
    const url = `${GRIP_ADMIN_PATH}/institutions/${institutionId}/documents/disclosures`;
    return JhaFetch.post(url, payload);
  }

  static getPasswordResetExpiration(institutionId) {
    const url = `/a/password-reset/api/institutions/${institutionId}/settings`;
    return JhaFetch.get(url);
  }

  static updatePasswordResetExpiration(institutionId, payload) {
    const url = `/a/password-reset/api/institutions/${institutionId}/settings`;
    return JhaFetch.put(url, payload);
  }

  static getPscuSettings(institutionId) {
    const url = `${GATEWAY_PATH}/v0/institutions/${institutionId}/pscu/credit-unions/account-types`;
    return JhaFetch.get(url);
  }

  static updatePscuSettings(institutionId, payload) {
    const url = `${GATEWAY_PATH}/v0/institutions/${institutionId}/pscu/credit-unions/account-types`;
    return JhaFetch.put(url, payload);
  }

  static getRDCConfiguration(institutionId) {
    const url = `/a/node-rdc/api/v0/ensenta/institutions/${institutionId}/configuration`;
    return JhaFetch.get(url);
  }

  static setRDCConfiguration(institutionId, body) {
    const url = `/a/node-rdc/api/v0/ensenta/institutions/${institutionId}/configuration`;
    return JhaFetch.post(url, body);
  }

  static updateRDCConfiguration(institutionId, body) {
    const url = `/a/node-rdc/api/v0/ensenta/institutions/${institutionId}/configuration`;
    return JhaFetch.put(url, body);
  }

  static getDashboardSettings(institutionId) {
    const url = `${DASHBOARD_PATH}/v0/institutions/${institutionId}/configuration/default`;
    return JhaFetch.get(url);
  }

  static deleteDashboardSettings(institutionId) {
    const url = `${DASHBOARD_PATH}/v0/institutions/${institutionId}/configuration/default`;
    return JhaFetch.delete(url);
  }

  static getDashboardCards(institutionId) {
    const url = `${DASHBOARD_PATH}/v0/institutions/${institutionId}/cards`;
    return JhaFetch.get(url);
  }

  static updateDashboardCard(institutionId, cardId, changeObject) {
    const url = `${DASHBOARD_PATH}/v0/institutions/${institutionId}/cards/${cardId}`;
    return JhaFetch.put(url, changeObject);
  }

  static deleteDashboardCard(institutionId, cardId) {
    const url = `${DASHBOARD_PATH}/v0/institutions/${institutionId}/cards/${cardId}`;
    return JhaFetch.delete(url);
  }

  static setDashboardConfiguration(institutionId, dashboardConfiguration) {
    const url = `${DASHBOARD_PATH}/v0/institutions/${institutionId}/configuration/default`;
    return JhaFetch.put(url, dashboardConfiguration);
  }

  static createPluginCard(institutionId, pluginCard) {
    const url = `${DASHBOARD_PATH}/v0/institutions/${institutionId}/cards`;
    return JhaFetch.post(url, pluginCard);
  }

  static getUserPluginCards(institutionId, userId) {
    const url = `${DASHBOARD_PATH}/v0/institutions/${institutionId}/users/${userId}/cards`;
    return JhaFetch.get(url);
  }

  static updateUserPluginCards(institutionId, userId, cardId, cardObject) {
    const url = `${DASHBOARD_PATH}/v0/institutions/${institutionId}/users/${userId}/cards/${cardId}`;
    return JhaFetch.put(url, cardObject);
  }

  static getAndroidUploadKeyCompletionStatus(institutionId) {
    const url = `${APP_KEYS_PATH}/institutions/${institutionId}/appkeys/android/upload/completion`;
    return JhaFetch.get(url);
  }

  static setAndroidUploadKeyMigrationComplete(institutionId) {
    const url = `${APP_KEYS_PATH}/institutions/${institutionId}/appkeys/android/upload/completion`;
    return JhaFetch.post(url);
  }

  static setAndroidUploadKeyMigrationIncomplete(institutionId) {
    const url = `${APP_KEYS_PATH}/institutions/${institutionId}/appkeys/android/upload/completion`;
    return JhaFetch.delete(url);
  }

  static setAndroidPublicSigningKeyCertificate(institutionId, certificate) {
    const url = `${APP_KEYS_PATH}/institutions/${institutionId}/appkeys/android/signing/public`;
    return JhaFetch.post(url, certificate);
  }

  static setAndroidSigningKeyFingerprint(institutionId, fingerprint) {
    const url = `${APP_KEYS_PATH}/institutions/${institutionId}/appkeys/android/signing/fingerprint`;
    return JhaFetch.post(url, fingerprint);
  }

  static createAndroidSigningKey(institutionId) {
    const url = `${APP_KEYS_PATH}/institutions/${institutionId}/appkeys/android/signing`;
    return JhaFetch.post(url);
  }

  static downloadAndroidSigningKey(institutionId) {
    const url = `${APP_KEYS_PATH}/institutions/${institutionId}/appkeys/android/signing`;
    return this._downloadKey(url);
  }

  static createAndroidUploadKey(institutionId) {
    const url = `${APP_KEYS_PATH}/institutions/${institutionId}/appkeys/android/upload`;
    return JhaFetch.post(url);
  }

  static downloadAndroidUploadKey(institutionId) {
    const url = `${APP_KEYS_PATH}/institutions/${institutionId}/appkeys/android/upload`;
    return this._downloadKey(url);
  }

  static getAndroidStoreKey(institutionId) {
    const url = `${APP_KEYS_PATH}/institutions/${institutionId}/appkeys/android/store`;
    return JhaFetch.get(url);
  }

  static updateAndroidStoreKey(institutionId, androidStoreKey) {
    const url = `${APP_KEYS_PATH}/institutions/${institutionId}/appkeys/android/store`;
    return JhaFetch.put(url, androidStoreKey);
  }

  static _downloadKey(url) {
    const xhrWrapper = new XhrPromise();
    // wait for OPENED state to set the responseType (this is to prevent errors in IE)
    xhrWrapper.xhr.addEventListener('readystatechange', function stateChanged(evt) {
      if (xhrWrapper.xhr.readyState === XMLHttpRequest.OPENED) {
        xhrWrapper.xhr.responseType = 'blob';
        xhrWrapper.xhr.removeEventListener('readystatechange', stateChanged);
      }
    });
    return /** @type{!Promise<!Blob|!File>} */ (xhrWrapper.get(url, 60000));
  }

  static getEnrollmentPrompt(institutionId) {
    const url = `${GRIP_ADMIN_PATH}/institutions/${institutionId}/documents/enrollment/prompt`;
    return JhaFetch.get(url);
  }

  static updateEnrollmentPrompt(institutionId, body) {
    const url = `${GRIP_ADMIN_PATH}/institutions/${institutionId}/documents/enrollment/prompt`;
    return JhaFetch.put(url, body);
  }

  static getUserEnrollmentPrompt(institutionId, userId) {
    const url = `${GRIP_ADMIN_PATH}/institutions/${institutionId}/users/${userId}/documents/enrollment/prompt`;
    return JhaFetch.get(url);
  }

  static updateUserEnrollmentPrompt(institutionId, userId, body) {
    const url = `${GRIP_ADMIN_PATH}/institutions/${institutionId}/users/${userId}/documents/enrollment/prompt`;
    return JhaFetch.put(url, body);
  }

  static deleteUserEnrollmentPropmpt(institutionId, userId) {
    const url = `${GRIP_ADMIN_PATH}/institutions/${institutionId}/users/${userId}/documents/enrollment/prompt`;
    return JhaFetch.delete(url);
  }
}
