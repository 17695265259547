import {LitElement, html, css} from 'lit-element';
import {jhaStyles} from '@banno/platform-ux-shared/styles/jha-styles';
import '../jha-enterprise-fi-name.js';
import store from '@banno/platform-ux-shared/session-storage/store.js';
import DialogMixin from '@banno/platform-ux-shared/mixins/dialog.js';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-fi';

const styles = css`
  :host {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  #institution-switcher a {
    width: 80px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  #institution-switcher a:hover {
    background-color: var(--jha-color-gray-light);
    font-weight: 400;
    border-radius: 6px;
    text-decoration: none;
  }
  img, #icon-wrapper {
    width: 48px;
    height: 48px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.3);
  }
  #icon-wrapper {
    background-color: var(--jha-text-base, #707070);
    display: flex;
    align-items: center;
  }
  jha-icon-fi {
    width: 36px;
    height: 36px;
    fill: var(--jha-text-white, #ffffff);
    margin: auto;
  }
  @media (min-width: 1025px) {
    jha-enterprise-fi-name {
      display: none;
    }
  }
  @media (max-width: 1024px) {
    :host {
      width: 242px;
    }
    img {
      width: 40px;
      height: 40px;
    }
    #institution-switcher a {
      width: 40px;
      height: 46px;
    }
  }
`;

class JhaEnterpriseNavFiLogo extends DialogMixin(LitElement) {
  static get styles() {
    return [jhaStyles, styles];
  }

  constructor() {
    super();
    this.userInstitutions = store.institutions;
    this._setSelectedInstitution(store.getSelectedInstitution());
    this._boundInstitutionChanged = this._handleInstitutionChanged.bind(this);
  }

  static get properties() {
    return {
      institutionHasIcon: {type: Boolean},
      institutionIconUrl: {type: String},
      selectedInstitution: {type: Object},
      userInstitutions: {type: Array},
    };
  }

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('institution-changed', this._boundInstitutionChanged);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('institution-changed', this._boundInstitutionChanged);
  }

  async _setSelectedInstitution(selectedInstitution) {
    if (!(selectedInstitution && selectedInstitution.institution && selectedInstitution.institution.institutionId)) return;
    const {institution: {shortId}} = selectedInstitution;
    if ((window.location.pathname + '/').includes(`/${shortId}/`)) {
      this.selectedInstitution = selectedInstitution;
    }

    this.institutionIconUrl = `/a/consumer/api/v0/institutions/${selectedInstitution.institution.institutionId}/themes/default/light/icon`;
    this.institutionHasIcon = false;
    try {
      const response = await fetch(this.institutionIconUrl);
      if (response.status === 200) {
        this.institutionHasIcon = true;
      }
    } catch (e) {
      // couldn't find the institution icon, we'll show the default svg instead
    }
  }

  _handleInstitutionChanged({detail: {selectedInstitution}}) {
    if (selectedInstitution !== this.selectedInstitution) {
      this._setSelectedInstitution(selectedInstitution);
    }
  }

  render() {
    if (!this.selectedInstitution || !this.selectedInstitution.institution) return null;
    return html`
      <div id="institution-switcher">
        <a href="/a/${this.selectedInstitution.institution.shortId}">
          ${this.institutionHasIcon ? html`
            <img
              src="${this.institutionIconUrl}"
              alt="${this.selectedInstitution.institution.name}"></img>` :
            html`
              <div id="icon-wrapper">
                <jha-icon-fi aria-label="${this.selectedInstitution.institution.name}"></jha-icon-fi>
              </div>
            `}
        </a>
      </div>
      <jha-enterprise-fi-name></jha-enterprise-fi-name>
    `;
  }
}

export {styles as JhaEnterpriseNavFiLogoStyles};

customElements.define('jha-enterprise-nav-fi-logo', JhaEnterpriseNavFiLogo); // eslint-disable-line @banno/ux/custom-elements-define
export default JhaEnterpriseNavFiLogo;
