/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconUploadElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-upload';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M19.62,20.75H4.38c-1.2,0-2.13-1.19-2.13-2.71V15a.75.75,0,0,1,1.5,0v3c0,.74.38,1.21.63,1.21H19.62c.26,0,.63-.47.63-1.21V15a.75.75,0,0,1,1.5,0v3C21.75,19.56,20.81,20.75,19.62,20.75Z"/>
    <path d="M16.53,8.47,12,3.94,7.47,8.47A.75.75,0,0,0,8.53,9.53l2.72-2.72V16a.75.75,0,0,0,1.5,0V6.81l2.72,2.72a.75.75,0,1,0,1.06-1.06Z"/>
    </svg>
  `;
  }
};

customElements.define(window.JhaIconUploadElement.is,
    window.JhaIconUploadElement);
export default window.JhaIconUploadElement;
