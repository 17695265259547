/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconMarketingElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-marketing';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <circle cx="12" cy="12" r="2.5"/>
    <path d="M21,11.25H19.71a7.76,7.76,0,0,0-7-7V3a.75.75,0,0,0-1.5,0V4.29a7.76,7.76,0,0,0-7,7H3a.75.75,0,0,0,0,1.5H4.29a7.76,7.76,0,0,0,7,7V21a.75.75,0,0,0,1.5,0V19.71a7.76,7.76,0,0,0,7-7H21a.75.75,0,0,0,0-1.5Zm-8.25,7V18a.75.75,0,0,0-1.5,0v.2A6.26,6.26,0,0,1,5.8,12.75H6a.75.75,0,0,0,0-1.5H5.8A6.26,6.26,0,0,1,11.25,5.8V6a.75.75,0,0,0,1.5,0V5.8a6.26,6.26,0,0,1,5.45,5.45H18a.75.75,0,0,0,0,1.5h.2A6.26,6.26,0,0,1,12.75,18.2Z"/>
    </svg>
  `;
  }
};

customElements.define(window.JhaIconMarketingElement.is,
    window.JhaIconMarketingElement);
export default window.JhaIconMarketingElement;
