/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconDocumentElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-document';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M18,20.75H6A.75.75,0,0,1,5.25,20V4A.75.75,0,0,1,6,3.25H18a.75.75,0,0,1,.75.75V20A.75.75,0,0,1,18,20.75ZM6.75,19.25h10.5V4.75H6.75Z"/>
    <path d="M15,8.75H9a.75.75,0,0,1,0-1.5h6a.75.75,0,0,1,0,1.5Z"/>
    <path d="M15,12.75H9a.75.75,0,0,1,0-1.5h6a.75.75,0,0,1,0,1.5Z"/>
    <path d="M15,16.75H9a.75.75,0,0,1,0-1.5h6a.75.75,0,0,1,0,1.5Z"/>
    </svg>
  `;
  }
};

customElements.define(window.JhaIconDocumentElement.is,
    window.JhaIconDocumentElement);
export default window.JhaIconDocumentElement;
