const schemasAllowedList = [
  'b62cd284-50c1-1031-8a90-fd05a7b98aff', // Abby Wood
  'f502bb70-aee9-1039-890a-bdf4f3d35ca6', // Brad McCormick
  'babf47ce-c105-1035-93bc-473bdaf916c3', // Bryan Coulter
  'cb0ca9fc-4835-1031-9bb8-4bbe815f6bda', // Chad Callahan
  '3c6ad2b0-133e-1034-936f-473bdaf916c3', // Chad Killingsworth
  'b8ae5980-2d53-1039-9f5c-198a47df2db6', // Dan Seawel
  'f679b464-bb68-1034-9385-473bdaf916c3', // Kean Drake
  'a9481ade-dc42-1032-9c6d-e1e4d656fae2', // Sheena Deutch
  'a8c90732-f4df-1034-9399-473bdaf916c3', // John Robinson
];
export default schemasAllowedList;
