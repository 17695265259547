/* eslint-disable valid-jsdoc */
/** @interface */
const DialogInterface = class {
  /**
   * @description Event handler for the 'onModal' event
   * @param {!Event} event
   */
  onOpenModal(event) {}
  /**
   * @description Event handler for the 'closeModal' event
   * @param {!Event} event
   */
  onCloseModal(event) {}
};

/**
 * @extends {CustomEvent<{
 *   el: (!HTMLElement|undefined),
 *   element: (!HTMLElement|undefined),
 *   useDiv: (boolean|undefined),
 *   disableBackdropClick: (boolean|undefined),
 *   attributes: (!Array<!Array<string>>|undefined),
 *   dialogAttributes: (!Array<string>|undefined),
 * }>}
 */
class OpenModalEvent extends CustomEvent {}
/** @type {!HTMLElement|undefined} */
OpenModalEvent.prototype.element;

/** @type {!Array<!Array<string>>|undefined} */
OpenModalEvent.prototype.attributes;

/**
 * @mixinFunction
 * @polymer
 * @param {function(new:Polymer.Element)} Superclass
 */
function dialogMixin(Superclass) {
  /**
   * @mixinClass
   * @polymer
   * @implements {DialogInterface}
   */
  class Dialog extends Superclass {
    constructor() {
      super();
      this.onOpenModalListener_ = (evt) => {
        this.onOpenModal(evt);
      };
      this.onCloseModalListener_ = (evt) => {
        this.onCloseModal(evt);
      };
      this.fadeOutDialog_ = (dialog) => {
        if (!dialog) {
          return;
        }
        dialog.classList.add("hide");
        if (dialog.close) {
          dialog.close();
        } else if (dialog.remove) {
          dialog.remove();
        }
        let fadeOut = (evt) => {
          dialog.classList.remove("hide");
          dialog.removeEventListener("animationend", fadeOut);
          document.body.removeAttribute("open-dialog");
        };
        dialog.addEventListener("animationend", fadeOut, false);
        document.body.removeAttribute("open-dialog");
      };
    }
    connectedCallback() {
      super.connectedCallback();
      this.addEventListener("openModal", this.onOpenModalListener_, false);
      this.addEventListener("closeModal", this.onCloseModalListener_, false);
    }
    disconnectedCallback() {
      super.disconnectedCallback();
      this.removeEventListener("closeModal", this.onCloseModalListener_, false);
      this.removeEventListener("openModal", this.onOpenModalListener_, false);
    }
    onOpenModal(event) {
      const openModalEvent = /** @type {!OpenModalEvent} */ (event);
      /** @type {HTMLElement}} */
      const element = openModalEvent.detail.el ||
          openModalEvent.element ||
          openModalEvent.detail.element;
      this.dialog = /** @type {HTMLDialogElement} */ !openModalEvent.detail.useDiv
        ? document.body.querySelector("dialog")
        : document.body.querySelector(".dialog");
      this.staticDialog = element.hasAttribute("static");
      if (this.dialog === null) {
        this.dialog = document.createElement(openModalEvent.detail.useDiv ? "div" : "dialog");
        // if dialog polyfill is used, we have to register our dialog
        if ("dialogPolyfill" in window && !openModalEvent.detail.useDiv) {
          window["dialogPolyfill"]["registerDialog"](this.dialog);
        }
        this.dialog.addEventListener("closeModal", (event) => {
          this.fadeOutDialog_(this.dialog);
        });
        this.dialog.addEventListener("cancel", (event) => {
          event.preventDefault();
          if (!this.staticDialog) {
            this.fadeOutDialog_(this.dialog);
          }
        });
        document.body.appendChild(this.dialog);
        if (openModalEvent.detail.useDiv) {
          this.dialog.classList.add("dialog", "is-visible");
        }
      }
      // Close the dialog when clicking on the backdrop.
      // Clicking on the backdrop pseudo element actually registers as a click inside
      // the dialog element - with no way to differentiate them.
      // We have to fall back to detecting if the clicked point falls within the dialog
      // bounding box.
      // See http://stackoverflow.com/a/26984690/1211524
      if (!openModalEvent.detail.disableBackdropClick) {
        this.dialog.addEventListener("click", (event) => {
          if (this.staticDialog || !this.dialog.open) {
            return;
          } else {
            const rect = this.dialog.getBoundingClientRect();
            const isInDialog =
              rect.top <= event.clientY &&
              event.clientY <= rect.top + rect.height &&
              rect.left <= event.clientX &&
              event.clientX <= rect.left + rect.width;
            if (!isInDialog) {
              const isDescendant = this.dialog.shadowRoot
                ? this.dialog.shadowRoot.contains(event.target)
                : false;
              if (!isDescendant) {
                this.fadeOutDialog_(this.dialog);
              }
            }
          }
        });
      }
      if (this.dialog.hasAttribute("open")) {
        // We can't use the onCloseModal method because because we
        // don't want the scroll style to be recalculated here.
        this.dialog.close();
      }
      // remove all children
      while (this.dialog.firstChild) {
        this.dialog.removeChild(this.dialog.firstChild);
      }
      // append new element
      this.dialog.appendChild(element);
      // remove any existing attributes
      while (!openModalEvent.detail.useDiv && this.dialog.attributes.length) {
        this.dialog.removeAttribute(this.dialog.attributes[0].name);
      }
      // add any desired attributes
      if (openModalEvent.attributes) {
        // Banno Online OpenModalEvent objects have an attributes property on the event
        for (const [key, value] of openModalEvent.attributes) {
          this.dialog.setAttribute(key, value || '');
        }
      } else if (openModalEvent.detail.dialogAttributes) {
        openModalEvent.detail.dialogAttributes.forEach((attr) => {
          this.dialog.setAttribute(attr, true);
        });
      }
      // disable scrolling when the dialog is open
      // document.body.setAttribute('open-dialog', '');
      // show the modal
      this.dialog.showModal && this.dialog.showModal();
    }
    onCloseModal(event) {
      const dialog = /** @type {HTMLDialogElement} */ (document.body.querySelector(
        "dialog"
      ));
      if (dialog && dialog.open) {
        this.fadeOutDialog_(dialog);
      }
      // Closing the dialog disables scrolling in chrome.
      // We need to toggle the scroll style to re-enable it.
      // See https://bugs.chromium.org/p/chromium/issues/detail?id=633520
      if (!("dialogPolyfill" in window)) {
        this.style.overflow = "scroll";
        window.requestAnimationFrame(() => {
          this.style.overflow = "";
        });
      }
    }
  }
  return Dialog;
}
export default dialogMixin;
export { DialogInterface as Type };
