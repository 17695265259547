import JhaFetch from '../services/jha-fetch';
import SitesApi from '../services/sites/sites-api';

export class SitesController {
  static getEnvironment() {
    const hostname = document.location.hostname;
    // In the site model, "staging" refers to the development environment,
    // while the staging environment is referred to as "uat".
    if (hostname.includes('local') || hostname.includes('staging')) {
      return 'uat';
    } else if (hostname.includes('development')) {
      return 'staging';
    } else {
      return 'production';
    }
  }

  static getSite(institutionId) {
    const getPrimaryDomain = (siteModel) => {
      const environment = this.getEnvironment();
      return siteModel.preferredDomains[environment];
    };

    return JhaFetch.fetch({
      api: SitesApi.siteFactory(),
      urlArguments: {institutionId},
      method: 'GET'
    }).then(data => {
      return data.map(site => {
        return {name: site.name, domain: getPrimaryDomain(site)};
      })
    }).catch((error) => Promise.reject(error));
  }

  static getSites(institutionId) {
    return JhaFetch.buildFetch({
      api: SitesApi.siteFactory(),
      urlArguments: { institutionId },
      method: 'GET'
    })
        .then(({url}) => JhaFetch.get(url))
        .catch((error) => Promise.reject(error));
  }

  static getDomain(domain) {
    return JhaFetch.fetch({
      api: SitesApi.domainFactory(),
      urlArguments: {domain},
      method: 'GET'
    }).catch(error => Promise.reject(error));
  }

  static updateDomain(domain, body) {
    return JhaFetch.fetch({
      api: SitesApi.domainFactory(),
      urlArguments: {domain},
      method: 'PUT',
      body
    }).catch(error => Promise.reject(error));
  }

  static getPages(domain) {
    return JhaFetch.fetch({
      api: SitesApi.pagesFactory(),
      urlArguments: {domain},
      method: 'GET'
    }).catch((error) => Promise.reject(error));
  }

  static getPage(domain, pageId) {
    return JhaFetch.fetch({
      api: SitesApi.pageFactory(),
      urlArguments: {domain, pageId},
      method: 'GET'
    }).catch((error) => Promise.reject(error));
  }

  static getPageUrl(domain, pageId) {
    return SitesController.getPage(domain, pageId).then(page => {
      return `https://${domain}/${page.url}`;
    }).catch((error) => Promise.reject(error));
  }

  static getSharedContentGroups(domain) {
    return JhaFetch.fetch({
      api: SitesApi.sharedContentGroupsFactory(),
      urlArguments: {domain},
      method: 'GET'
    }).catch((error) => Promise.reject(error));
  }

  static createSharedContentGroup(domain, body) {
    return JhaFetch.fetch({
      api: SitesApi.sharedContentGroupsFactory(),
      urlArguments: {domain},
      body,
      method: 'POST'
    }).catch((error) => Promise.reject(error));
  }

  static saveSharedContentGroup(domain, body) {
    const contentId = body.id;
    return JhaFetch.fetch({
      api: SitesApi.sharedContentGroupFactory(),
      urlArguments: {domain, contentId},
      body,
      method: 'PUT'
    }).catch((error) => Promise.reject(error));
  }

  static deleteSharedContentGroup(domain, contentId) {
    return JhaFetch.fetch({
      api: SitesApi.sharedContentGroupFactory(),
      urlArguments: {domain, contentId},
      method: 'DELETE'
    }).catch((error) => Promise.reject(error));
  }

  static getCategories(domain) {
    return JhaFetch.fetch({
      api: SitesApi.categoryFactory(),
      urlArguments: {domain},
      method: 'GET'
    }).catch((error) => Promise.reject(error));
  }

  static getTemplates(domain) {
    return JhaFetch.fetch({
      api: SitesApi.templateFactory(),
      urlArguments: {domain},
      method: 'GET'
    }).catch((error) => Promise.reject(error));
  }

  static getTemplatesAssets(domain) {
    return JhaFetch.fetch({
      api: SitesApi.templateFactoryAssets(),
      urlArguments: {domain},
      method: 'GET'
    }).catch((error) => Promise.reject(error));
  }

  static deleteTemplateAssets(domain, templateId) {
    return JhaFetch.fetch({
      api: SitesApi.templateFactoryAsset(),
      urlArguments: {domain, templateId},
      method: 'DELETE'
    }).catch((error) => Promise.reject(error));
  }

  static activateTemplateAssets(domain, templateId) {
    return JhaFetch.fetch({
      api: SitesApi.activateTemplateFactoryAsset(),
      urlArguments: {domain, templateId},
      method: 'PUT'
    }).catch((error) => Promise.reject(error));
  }

  static getMemberGroups(domain) {
    return JhaFetch.fetch({
      api: SitesApi.memberGroupFactory(),
      urlArguments: {domain},
      method: 'GET'
    }).catch((error) => Promise.reject(error));
  }

  static saveMemberGroup(domain, group) {
    return JhaFetch.fetch({
      api: SitesApi.memberGroupFactory(),
      urlArguments: {domain},
      method: 'POST',
      body: group
    }).catch((error) => Promise.reject(error));
  }

  static deleteMemberGroup(domain, groupId) {
    return JhaFetch.fetch({
      api: SitesApi.memberGroupsFactory(),
      urlArguments: {domain, groupId},
      method: 'DELETE',
    }).catch((error) => Promise.reject(error));
  }

  static getPermissions(domain) {
    return JhaFetch.fetch({
      api: SitesApi.permissionsFactory(),
      urlArguments: {domain},
      method: 'GET'
    }).catch((error) => Promise.reject(error));
  }

  static getCmsRedirects(institutionId, domain, suffix) {
    return JhaFetch.fetch({
      api: SitesApi.cmsRedirectFactory(),
      urlArguments: {institutionId, domain, suffix},
      method: 'GET'
    }).catch((error) => Promise.reject(error));
  }

  static updateCmsRedirects(institutionId, domain, body) {
    return JhaFetch.fetch({
      api: SitesApi.cmsRedirectFactory(),
      urlArguments: {institutionId, domain},
      method: 'PUT',
      body
    }).catch((error) => Promise.reject(error));
  }

  static createCmsRedirects(institutionId, domain, body) {
    return JhaFetch.fetch({
      api: SitesApi.cmsRedirectFactory(),
      urlArguments: {institutionId, domain},
      method: 'POST',
      body
    }).catch((error) => Promise.reject(error));
  }

  static getDisclaimers(domain) {
    return JhaFetch.fetch({
      api: SitesApi.disclaimersFactory(),
      urlArguments: {domain},
      method: 'GET'
    }).catch((error) => Promise.reject(error));
  }

  static getSiteSettings(domain) {
    return JhaFetch.fetch({
      api: SitesApi.siteSettingsFactory(),
      urlArguments: {domain},
      method: 'GET'
    }).catch((error) => Promise.reject(error));
  }

  static saveSiteSettings(domain, body) {
    return JhaFetch.fetch({
      api: SitesApi.siteSettingsFactory(),
      urlArguments: {domain},
      method: 'POST',
      body
    }).catch((error) => Promise.reject(error));
  }

  static saveDisclaimer(domain, disclaimer) {
    const disclaimerId = disclaimer.id;
    if (disclaimerId) {
      return JhaFetch.fetch({
        api: SitesApi.disclaimerFactory(),
        urlArguments: {domain, disclaimerId},
        body: disclaimer,
        method: 'PUT'
      }).catch((error) => Promise.reject(error));
    } else {
      return JhaFetch.fetch({
        api: SitesApi.disclaimersFactory(),
        urlArguments: {domain},
        body: disclaimer,
        method: 'POST'
      }).catch((error) => Promise.reject(error));
    }
  }

  static deleteDisclaimer(domain, disclaimerId) {
    return JhaFetch.fetch({
      api: SitesApi.disclaimerFactory(),
      urlArguments: {domain, disclaimerId},
      method: 'DELETE'
    }).catch((error) => Promise.reject(error));
  }

  static getSiteAtms(domain, lat, lng, range) {
    return JhaFetch.fetch({
      api: SitesApi.siteAtmsFactory(),
      urlArguments: {domain, lat, lng, range},
      method: 'GET'
    }).catch((error) => Promise.reject(error));
  }

  static saveAtm(domain, atm, networkId) {
    if (atm.id) {
      return JhaFetch.fetch({
        api: SitesApi.siteAtmFactory(),
        urlArguments: {domain, networkId, id: atm.id},
        body: atm,
        method: 'PUT'
      }).catch((error) => Promise.reject(error));
    } else {
      return JhaFetch.fetch({
        api: SitesApi.siteAtmFactory(),
        urlArguments: {domain, networkId},
        body: atm,
        method: 'POST'
      }).catch((error) => Promise.reject(error));
    }
  }

  static getSiteBranches(domain, lat, lng, range) {
    return JhaFetch.fetch({
      api: SitesApi.siteBranchesFactory(),
      urlArguments: {domain, lat, lng, range},
      method: 'GET'
    }).catch((error) => Promise.reject(error));
  }

  static saveBranch(domain, branch) {
    let method = 'PUT';
    if (!branch.id) method = 'POST';
    let api = SitesApi.siteBranchFactory();
    let urlArguments = {
      domain,
      id: (branch.id ? branch.id : branch.imageId)
    };
    if (branch.id && branch.imageId && branch.imageId.length === 8) {
      api = SitesApi.siteBranchImageFactory();
      urlArguments.imageId = branch.imageId;
    }
    return JhaFetch.fetch({
      api,
      urlArguments,
      body: branch,
      method
    }).catch((error) => Promise.reject(error));
  }

  static getSiteAtmNetworks(domain) {
    return JhaFetch.fetch({
      api: SitesApi.siteAtmNetworksFactory(),
      urlArguments: {domain},
      method: 'GET'
    }).catch((error) => Promise.reject(error));
  }

  static getFormTypes(domain) {
    return JhaFetch.fetch({
      api: SitesApi.formsFactory(),
      urlArguments: {domain},
      method: 'GET'
    }).catch((error) => Promise.reject(error));
  }

  static saveFormType(domain, body) {
    return JhaFetch.fetch({
      api: SitesApi.formsFactory(),
      urlArguments: {domain},
      method: 'PUT',
      body
    }).catch((error) => Promise.reject(error));
  }

  static getEntries(domain, formId, start) {
    const maxResults = 20;
    return JhaFetch.fetch({
      api: SitesApi.formFactory(),
      urlArguments: {domain, formId, maxResults, start},
      method: 'GET'
    }).catch((error) => Promise.reject(error));
  }

  static newEntries(domain, date) {
    return JhaFetch.fetch({
      api: SitesApi.modifiedFactory(),
      urlArguments: {domain, date},
      method: 'GET'
    }).catch((error) => Promise.reject(error));
  }

  static saveEntry(domain, entry) {
    return JhaFetch.fetch({
      api: SitesApi.entryFactory(),
      urlArguments: {domain, entryId: entry.id},
      method: 'PUT',
      body: entry
    }).catch((error) => Promise.reject(error));
  }

  static getEntryHTML(domain, entryId) {
    return JhaFetch.fetch({
      api: SitesApi.entryFactory(),
      urlArguments: {domain, entryId},
      method: 'GET',
      headers: {
        accept: 'text/html'
      }
    }).catch((error) => Promise.reject(error));
  }

  static deleteEntry(domain, entry) {
    return JhaFetch.fetch({
      api: SitesApi.entryFactory(),
      urlArguments: {domain, entryId: entry.id},
      method: 'DELETE'
    }).catch((error) => Promise.reject(error));
  }
}

export default SitesController;
