export class SitesApi {
  static get SITE_PATH() {
    return '/a/cms/api/site';
  }
  static get DOMAIN_PATH() {
    return '/a/cms/api/site/:domain';
  }
  static get PAGES_PATH() {
    return '/a/cms/api/site/:domain/page';
  }
  static get PAGE_PATH() {
    return '/a/cms/api/site/:domain/page/:pageId';
  }
  static get CMS_REDIRECT() {
    return '/a/cms-redirect/api/v0';
  }

  static siteFactory() {
    const factory = {
      url: (institutionId) => {
        return `${SitesApi.SITE_PATH}?institutionId=${institutionId}`;
      },
      arguments: ['institutionId'],
      requestMethods: ['GET']
    };
    return factory;
  }

  static domainFactory() {
    const factory = {
      url: (domain) => {
        return `${SitesApi.SITE_PATH}/${domain}`;
      },
      arguments: ['domain'],
      requestMethods: ['GET']
    };
    return factory;
  }

  static pagesFactory() {
    const factory = {
      url: (domain) => {
        return `${SitesApi.SITE_PATH}/${domain}/page`;
      },
      arguments: ['domain'],
      requestMethods: ['GET']
    };
    return factory;
  }

  static pageFactory() {
    const factory = {
      url: (domain, pageId) => {
        return `${SitesApi.SITE_PATH}/${domain}/page/${pageId}`;
      },
      arguments: ['domain', 'pageId'],
      requestMethods: ['GET']
    };
    return factory;
  }

  static sharedContentGroupsFactory() {
    const factory = {
      url: (domain) => {
        return `${SitesApi.SITE_PATH}/${domain}/shared-content-group`;
      },
      arguments: ['domain'],
      requestMethods: ['GET', 'POST']
    };
    return factory;
  }

  static sharedContentGroupFactory() {
    const factory = {
      url: (domain, contentId) => {
        return `${SitesApi.SITE_PATH}/${domain}/shared-content-group/${contentId}`;
      },
      arguments: ['domain', 'contentId'],
      requestMethods: ['PUT', 'DELETE']
    };
    return factory;
  }

  static categoryFactory() {
    const factory = {
      url: (domain) => {
        return `${SitesApi.SITE_PATH}/${domain}/category`;
      },
      arguments: ['domain'],
      requestMethods: ['GET']
    };
    return factory;
  }

  static templateFactory() {
    const factory = {
      url: (domain) => {
        return `${SitesApi.SITE_PATH}/${domain}/template`;
      },
      arguments: ['domain'],
      requestMethods: ['GET']
    };
    return factory;
  }

  static templateFactoryAssets() {
    const factory = {
      url: (domain) => {
        return `${SitesApi.SITE_PATH}/${domain}/template-assets`;
      },
      arguments: ['domain'],
      requestMethods: ['GET']
    };
    return factory;
  }

  static templateFactoryAsset() {
    const factory = {
      url: (domain, templateId) => {
        return `${SitesApi.SITE_PATH}/${domain}/template-assets/${templateId}`;
      },
      arguments: ['domain', 'templateId'],
      requestMethods: ['DELETE']
    };
    return factory;
  }

  static activateTemplateFactoryAsset() {
    const factory = {
      url: (domain, templateId) => {
        return `${SitesApi.SITE_PATH}/${domain}/template-assets/${templateId}/activate`;
      },
      arguments: ['domain', 'templateId'],
      requestMethods: ['PUT']
    };
    return factory;
  }

  static memberGroupFactory() {
    const factory = {
      url: (domain) => {
        return `${SitesApi.SITE_PATH}/${domain}/members/group`;
      },
      arguments: ['domain'],
      requestMethods: ['GET']
    };
    return factory;
  }

  static memberGroupsFactory() {
    const factory = {
      url: (domain, groupId) => {
        return `${SitesApi.SITE_PATH}/${domain}/members/group/${groupId}`;
      },
      arguments: ['domain', 'groupId'],
      requestMethods: ['DELETE']
    };
    return factory;
  }

  static membersFactory() {
    const factory = {
      url: (domain) => {
        return `${SitesApi.SITE_PATH}/${domain}/members/member`;
      },
      arguments: ['domain'],
      requestMethods: ['GET', 'POST']
    };
    return factory;
  }

  static memberFactory() {
    const factory = {
      url: (domain, memberId) => {
        return `${SitesApi.SITE_PATH}/${domain}/members/member/${memberId}`;
      },
      arguments: ['domain', 'memberId'],
      requestMethods: ['GET', 'PUT', 'DELETE']
    };
    return factory;
  }

  static permissionsFactory() {
    const factory = {
      url: (domain) => {
        return `${SitesApi.SITE_PATH}/${domain}/user/me/permission`;
      },
      arguments: ['domain'],
      requestMethods: ['GET']
    };
    return factory;
  }

  static redirectFactory() {
    const factory = {
      url: (domain, suffix) => {
        return `${SitesApi.SITE_PATH}/${domain}/redirects${suffix || ''}`;
      },
      arguments: ['domain', 'suffix'],
      requestMethods: ['GET', 'PUT', 'POST']
    };
    return factory;
  }

  static cmsRedirectFactory() {
    const factory = {
      url: (institutionId, domain, suffix) => {
        return `${SitesApi.CMS_REDIRECT}/institutions/${institutionId}/site/${domain}/redirects${suffix || ''}`;
      },
      arguments: ['institutionId', 'domain', 'suffix'],
      requestMethods: ['GET', 'PUT', 'POST']
    };
    return factory;
  }

  static disclaimersFactory() {
    const factory = {
      url: (domain) => {
        return `${SitesApi.SITE_PATH}/${domain}/disclaimer`;
      },
      arguments: ['domain'],
      requestMethods: ['GET', 'POST']
    };
    return factory;
  }

  static disclaimerFactory() {
    const factory = {
      url: (domain, disclaimerId) => {
        return `${SitesApi.SITE_PATH}/${domain}/disclaimer/${disclaimerId}`;
      },
      arguments: ['domain', 'disclaimerId'],
      requestMethods: ['PUT', 'DELETE']
    };
    return factory;
  }

  static siteSettingsFactory() {
    const factory = {
      url: (domain) => {
        return `${SitesApi.SITE_PATH}/${domain}/sitesettings`;
      },
      arguments: ['domain'],
      requestMethods: ['GET']
    };
    return factory;
  }

  static siteAtmFactory() {
    const factory = {
      url: (domain, networkId, id) => {
        if (id) {
          return `${SitesApi.SITE_PATH}/${domain}/atms/${id}/network/${networkId}`;
        } else {
          return `${SitesApi.SITE_PATH}/${domain}/atms/network/${networkId}`;
        }
      },
      arguments: ['domain', 'networkId', 'id'],
      requestMethods: ['GET', 'POST', 'PUT']
    };
    return factory;
  }

  static siteAtmsFactory() {
    const factory = {
      url: (domain, lat, lng, range) => {
        return `${SitesApi.SITE_PATH}/${domain}/atms/${lat}/${lng}/${range}?removed`;
      },
      arguments: ['domain', 'lat', 'lng', 'range'],
      requestMethods: ['GET']
    };
    return factory;
  }

  static siteAtmNetworksFactory() {
    const factory = {
      url: (domain) => {
        return `${SitesApi.SITE_PATH}/${domain}/atm/networks`;
      },
      arguments: ['domain'],
      requestMethods: ['GET']
    };
    return factory;
  }

  static siteBranchFactory() {
    const factory = {
      url: (domain, id) => {
        return `${SitesApi.SITE_PATH}/${domain}/branches${id ? `/${id}` : ''}`;
      },
      arguments: ['domain', 'id'],
      requestMethods: ['GET', 'POST', 'PUT']
    };
    return factory;
  }

  static siteBranchImageFactory() {
    const factory = {
      url: (domain, id, imageId) => {
        return `${SitesApi.SITE_PATH}/${domain}/branchimage/${id}/${imageId}`;
      },
      arguments: ['domain', 'id', 'imageId'],
      requestMethods: ['GET', 'POST', 'PUT']
    };
    return factory;
  }

  static siteBranchesFactory() {
    const factory = {
      url: (domain, lat, lng, range) => {
        return `${SitesApi.SITE_PATH}/${domain}/branches/${lat}/${lng}/${range}?removed`;
      },
      arguments: ['domain', 'lat', 'lng', 'range'],
      requestMethods: ['GET']
    };
    return factory;
  }

  static formsFactory() {
    const factory = {
      url: (domain) => {
        return `${SitesApi.SITE_PATH}/${domain}/form`;
      },
      arguments: ['domain'],
      requestMethods: ['GET', 'PUT']
    };
    return factory;
  }

  static formFactory() {
    const factory = {
      url: (domain, formId, maxResults, start) => {
        return `${SitesApi.SITE_PATH}/${domain}/form/${formId}/entriesPagination?maxResults=${maxResults}&start=${start}`;
      },
      arguments: ['domain', 'formId', 'maxResults', 'start'],
      requestMethods: ['GET']
    };
    return factory;
  }

  static modifiedFactory() {
    const factory = {
      url: (domain, date) => {
        return `${SitesApi.SITE_PATH}/${domain}/form/modified?date=${date}`;
      },
      arguments: ['domain', 'date'],
      requestMethods: ['GET']
    };
    return factory;
  }

  static entryFactory() {
    const factory = {
      url: (domain, entryId) => {
        return `${SitesApi.SITE_PATH}/${domain}/form/entries/${entryId}`;
      },
      arguments: ['domain', 'entryId'],
      requestMethods: ['PUT', 'DELETE']
    };
    return factory;
  }

}

export default SitesApi;
