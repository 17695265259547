import { LitElement, html, css } from 'lit-element';
import { dialogStyles } from '@banno/platform-ux-shared/styles/dialog.styles.js';
import '@banno/platform-ux-shared/components/dialogs/base.dialog';
import '@banno/platform-ux-shared/components/polymer3/jha/buttons/jha-button';
class SegmentedPermissionsLogoutDialog extends LitElement {
  static get is() {
    return 'segmented-permissions-logout-dialog';
  }

  static get properties() {
    return {
      segment: {type: Object}
    };
  }

  static get styles() {
    return [
      dialogStyles,
      css`
        header {
          padding: 37px 24px 29px 24px !important;
          position: relative;
        }
        .content {
          display: flex;
          margin: 24px;
        }
        .confirm {
          margin-right: 8px !important;
        }
        .deny {
          --jha-button-link-text: rgb(0, 110, 228) !important;
        }
        @media only screen and (max-width: 600px) {
          header {
            width: calc(100vw - 48px);
          }
          .content {
            width: 100vw;
            overflow-y: scroll;
          }
        }
      `
    ];
  }

  logout() {
    const a = document.createElement('a');
    a.href = '/a/login/logout';
    a.click();
    this.close();
  }

  close() {
    this.dispatchEvent(new CustomEvent('closeModal', {
      bubbles: true,
      composed: true
    }));
  }

  render() {
    return html`
      <header>
        <div>Are you sure you want to sign out?</div>
      </header>
      <div class="content">
        Signing out while permissions are updating can result in needing to re-start the process. Are you sure you want to proceed?
      </div>
      <footer>
        <jha-button primary class="confirm" sync @click=${this.logout}>Sign out</jha-button>
        <jha-button link class="deny" sync @click=${this.close}>Close</jha-button>
      </footer>
    `;
  }
}
export default SegmentedPermissionsLogoutDialog;
window.customElements.define(SegmentedPermissionsLogoutDialog.is, SegmentedPermissionsLogoutDialog);
