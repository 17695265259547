export const CONSUMER_PATH = '/a/consumer/api';
export const GRIP_ADMIN_PATH = '/a/mobile/api';
export const GRIP_ADMIN_BASE_PATH = '/a/people/';
export const CONVERSATIONS_PATH = '/a/conversations/api/enterprise';
export const REG_D_PATH = '/a/regulation-d/api/v1';
export const DISPLAY_TEXT_PATH = '/a/display-text/api';
export const OFFLINE_STATUS_PATH = '/a/offline-status/api';
export const TOTP_PATH = '/a/totp-service/api';
export const DASHBOARD_PATH = '/a/dashboard-cards/api';
export const GATEWAY_PATH = '/a/gateway/api';
export const APP_KEYS_PATH = '/a/app-keys/api';
export const AVIATO_PATH = '/a/configs/api';
export const DISPLAY_TEXT_V1_PATH = '/a/display-text';
export const ACH_PATH = '/a/ach/api/v0/institutions';
export const PRINCIPAL_PAYMENTS_PATH ='/a/transfer-configs-institution/api/v0';
export const SYMX_CONFIGS = '/a/symx-configs/api';
export const ACCOUNTS_CONFIG_PATH = '/a/accounts/api/v0/institutions';
export const RAPID_TRANSFERS = '/a/api/rapid-transfers';
