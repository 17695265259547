import querystring from 'query-string';

'use strict';
class SentinelApi {
    static get SENTINEL_PATH() {
        return '/a/sentinel/api';
    }
    static acknowledgementsFactory() {
        return {
            url: function (institutionId, monitorId, jobId) {
                return `${SentinelApi.SENTINEL_PATH}/institutions/${institutionId}/monitors/${monitorId}/runs/${jobId}/acknowledgements`;
            },
            arguments: ['institutionId', 'monitorId', 'jobId'],
            requestMethods: ['POST']
        };
    }
    static alertsFactory() {
        return {
            url: function (institutionId, monitorId, jobId) {
                return `${SentinelApi.SENTINEL_PATH}/institutions/${institutionId}/monitors/${monitorId}/runs/${jobId}/alerts`;
            },
            arguments: ['institutionId', 'monitorId', 'jobId'],
            requestMethods: ['GET']
        };
    }
    static alertSettingsFactory() {
        return {
            url: function (institutionId, monitorId) {
                return `${SentinelApi.SENTINEL_PATH}/institutions/${institutionId}/monitors/${monitorId}/settings`;
            },
            arguments: ['institutionId', 'monitorId'],
            requestMethods: ['GET', 'POST']
        };
    }
    static checkSummaryFactory() {
        return {
            url: function (institutionId, userId) {
                return `${SentinelApi.SENTINEL_PATH}/institutions/${institutionId}/user/${userId}/report/checkSummary`;
            },
            arguments: ['institutionId', 'userId'],
            requestMethods: ['GET']
        };
    }
    static maintenanceFactory() {
        return {
            url: (institutionId, maintenanceId, url) => {
                return `${SentinelApi.SENTINEL_PATH}/institutions/${institutionId}/maintenance${maintenanceId ? '/' + maintenanceId : ''}${url ? url : ''}`;
            },
            arguments: ['institutionId', 'maintenanceId', 'url'],
            requestMethods: ['GET', 'POST']
        };
    }
    static diffFactory() {
        return {
            url: function (oldResponseId, newResponseId) {
                return `${SentinelApi.SENTINEL_PATH}/response/${oldResponseId}?diff=${newResponseId}`;
            },
            arguments: ['oldResponseId', 'newResponseId'],
            requestMethods: ['GET']
        };
    }
    static monitorsFactory() {
        return {
            url: function (institutionId) {
                return `${SentinelApi.SENTINEL_PATH}/institutions/${institutionId}/monitors`;
            },
            arguments: ['institutionId'],
            requestMethods: ['GET', 'POST']
        };
    }
    static monitorAcknowledgeFactory() {
        return {
            url: function (institutionId, monitorId) {
                return `${SentinelApi.SENTINEL_PATH}/institutions/${institutionId}/monitors/${monitorId}/acknowledgements`;
            },
            arguments: ['institutionId', 'monitorId'],
            requestMethods: ['POST']
        };
    }
    static monitorFactory() {
        return {
            url: function (institutionId, monitorId) {
                return `${SentinelApi.SENTINEL_PATH}/institutions/${institutionId}/monitors/${monitorId}`;
            },
            arguments: ['institutionId', 'monitorId'],
            requestMethods: ['GET', 'PUT', 'DELETE']
        };
    }
    static reportFactory() {
        return {
            url: function (institutionId, userId) {
                return `${SentinelApi.SENTINEL_PATH}/institutions/${institutionId}/user/${userId}/report`;
            },
            arguments: ['institutionId', 'userId'],
            requestMethods: ['PUT']
        };
    }
    static runFactory() {
        return {
            url: function (institutionId, monitorId, jobId) {
                return `${SentinelApi.SENTINEL_PATH}/institutions/${institutionId}/monitors/${monitorId}/runs/${jobId}`;
            },
            arguments: ['institutionId', 'monitorId', 'jobId'],
            requestMethods: ['GET']
        };
    }
    static runsFactory() {
        return {
            url: function (institutionId, monitorId, filter, count, offset) {
                const queryString = querystring.stringify({ filter, count, offset });
                return `${SentinelApi.SENTINEL_PATH}/institutions/${institutionId}/monitors/${monitorId}/runs?${queryString}`;
            },
            arguments: ['institutionId', 'monitorId', 'filter', 'count', 'offset'],
            requestMethods: ['GET']
        };
    }
}
export default SentinelApi;
