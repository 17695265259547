/* jshint ignore:start  */
import JhaFetch from '@banno/platform-ux-shared/services/jha-fetch';
/* jshint ignore:end */
import ApiPaths from '../api-paths';

export default class SitesService {
  static getSites(institutionId) {
    return JhaFetch.get(`${ApiPaths.CMS_PATH}/site`, {institutionId}, true);
  }
}
