/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconPeopleElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-people';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M18.78,13.2a6.79,6.79,0,0,1-1.07-.36.25.25,0,0,1-.13-.11,4.56,4.56,0,0,0,1.15-2.85c0-3.41-2.22-3.77-3.17-3.77s-3.17.37-3.17,3.77a4.57,4.57,0,0,0,1.11,2.8.47.47,0,0,1-.09.16,6.72,6.72,0,0,1-1.07.36l-.34.1-.33-.1a6.79,6.79,0,0,1-1.07-.36.25.25,0,0,1-.13-.11,4.56,4.56,0,0,0,1.15-2.85c0-3.41-2.22-3.77-3.17-3.77s-3.17.37-3.17,3.77a4.57,4.57,0,0,0,1.11,2.8.47.47,0,0,1-.09.16,6.79,6.79,0,0,1-1.07.36c-1.18.33-3,.84-3,2.7v1.23a.75.75,0,0,0,1.5,0V15.9c0-.62.49-.87,1.88-1.26A7.83,7.83,0,0,0,7,14.18a2.13,2.13,0,0,0,1-1.62L8,12.14l-.32-.27a3,3,0,0,1-.92-2c0-2,.89-2.27,1.67-2.27s1.67.26,1.67,2.27a3,3,0,0,1-.93,2l-.29.24,0,.37a1.84,1.84,0,0,0,1,1.69,7.83,7.83,0,0,0,1.34.46c1.39.39,1.88.64,1.88,1.26v1.23a.75.75,0,0,0,1.5,0V15.9a2.14,2.14,0,0,0-.72-1.66l.16-.07a2.12,2.12,0,0,0,1-1.62l.06-.42-.32-.27a3,3,0,0,1-.92-2c0-2,.89-2.27,1.67-2.27s1.67.26,1.67,2.27a3,3,0,0,1-.93,2l-.29.24,0,.37a1.84,1.84,0,0,0,1,1.69,7.83,7.83,0,0,0,1.34.46c1.39.39,1.88.64,1.88,1.26v1.23a.75.75,0,1,0,1.5,0V15.9C21.75,14,20,13.54,18.78,13.2Z"/>
    </svg>
  `;
  }
};

customElements.define(window.JhaIconPeopleElement.is,
    window.JhaIconPeopleElement);
export default window.JhaIconPeopleElement;
