/* eslint-disable @banno/ux/element-annotated */
import {LitElement, html, css} from 'lit-element';
import {routingMixin as RouterMixin} from '@jack-henry/web-component-router';
import {jhaStyles} from '@banno/platform-ux-shared/styles/jha-styles';
import store from '@banno/platform-ux-shared/session-storage/store';
import {setInstitutionConfig} from '@banno/platform-ux-shared/session-storage/institution-store-helper';
import '@banno/platform-ux-shared/components/polymer3/avatar/avatar-upload.js';
import '@banno/platform-ux-shared/components/polymer3/jha/avatars/jha-avatar';
import '@banno/platform-ux-shared/components/polymer3/jha/flex-wrapper/jha-flex-wrapper';
import '@banno/platform-ux-shared/components/polymer3/user-details/user-avatar-dropdown';
import '@banno/platform-ux-shared/components/polymer3/avatar/delete-avatar-dialog.js';
import '@banno/enterprise-ux/src/components/enterprise-wrapper';
import '@banno/enterprise-ux/src/components/jha-enterprise-fi-name';
import {getInstitutionNavigation} from '@banno/enterprise-ux/src/components/navigation/helpers/navigation-helper';
import UsersController from '@banno/platform-ux-shared/controllers/users-controller.js';
import DialogMixin from '@banno/platform-ux-shared/mixins/dialog.js';
import './knowledge-base-card';
import './statement-card';

const styles = css`
  :host {
    display: block
  }
  #dashboard-wrapper {
    width: 100%;
    height: 100vh;
    padding-bottom: 48px;
  }
  header.main {
    background-color: var(--jha-color-primary);
    padding: 60px 40px 120px;
    color: var(--jha-text-white);
  }
  .content-wrapper {
    align-items: flex-start;
  }
  h1 {
    margin: 0;
    color: var(--jha-text-white);
  }
  .welcome-msg {
    font-size: 14px;
  }
  jha-enterprise-fi-name {
    display: inline-block;
    text-decoration: underline;
    color: var(--jha-text-white, #ffffff);
  }
  avatar-upload {
    margin-right: 8px;
  }
  article.main {
    margin-top: -90px;
    padding: 0 40px;
  }
  statement-card,
  knowledge-base-card {
    margin: 0;
    width: 50%;
    min-width: 320px;
  }
  statement-card {
    align-self: flex-start;
    margin-right: 16px;
  }
  @media (max-width: 1024px) {
    .desktop-only {
      display: none;
    }
    header.main {
      padding: 0 20px 120px;
      position: relative;
    }
    jha-enterprise-fi-name {
      margin-left: 0px;
    }
    article.main {
      padding: 0 20px 16px;
    }
    statement-card,
    knowledge-base-card {
      z-index: 0;
      position: relative;
      width: 100%;
    }
  }
  @media (min-width: 992px) {
    .content-wrapper {
      max-width: 1170px;
      margin: auto;
      padding: 0 60px 0 40px;
    }
  }
  @media (max-width: 739px) {
    header.main::after {
      content: '';
      position: absolute;
      width: 100vw;
      height: 60px;
      left: 0px;
      top: 100px;
      border-radius: 100vw / 50px;
      background-color: var(--jha-color-primary);
      z-index: 0;
    }
    article.main {
      position: relative;
      margin-top: -100px;
    }
    div[slot="mobile-header-button"] {
      right: 4px;
      position: relative;
    }
    jha-flex-wrapper {
      display: block;
    }
    statement-card,
    knowledge-base-card {
      margin: 0;
      z-index: 0;
      position: relative;
      width: 100%;
      margin: 0 0 24px 0;
    }
  }
`;
class DashboardContainer extends DialogMixin(RouterMixin(LitElement)) {
  static get styles() {
    return [jhaStyles, styles];
  }

  static get properties() {
    return {
      loginDashboardDetails: {type: Object},
      projectList: {type: Array, value: () => []},
      selectedInstitution: {type: Object},
      title: {type: String},
      avatarImgSrc: {type: String},
      profile: {
        type: Object,
        value: () => {},
      },
      imageUri: {type: String},
      avatarExists: {type: Boolean},
      canRemoveAvatar: {type: Boolean},
    };
  }

  constructor() {
    super();
    this.title = this._getTitle();
    this.selectedInstitution = store.getSelectedInstitution();
    this.loginDashboardDetails = {};
  }

  connectedCallback() {
    super.connectedCallback();
    this._getLoginDashboardDetailsFromStore();
    this._getProjectList();
    this._getUserProfile(store.user.userId);
    this.boundUpdateAvatar_ = this._updateAvatarImg.bind(this);
    this.addEventListener('removeAvatar', this._removeAvatar);
    this.addEventListener('update-avatar', this._updateAvatarImg);
    window.addEventListener('avatarRemoved', this.boundUpdateAvatar_);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('removeAvatar', this._removeAvatar);
    this.removeEventListener('update-avatar', this._updateAvatarImg);
    window.removeEventListener('avatarRemoved', this.boundUpdateAvatar_);
  }

  _refreshAvatar() {
    this.avatarImgSrc = this._getAvatarSrc();
  }

  _getAvatarSrc() {
    let src;
    if (this.imageUri) {
      const timestamp = new Date().getTime();
      src = this.imageUri + '?' + timestamp;
    } else {
      src = '/a/consumer/api/node/profile-photo/0';
    }
    return src;
  }

  _updateAvatarImg(e) {
    if (e.detail && e.detail.dataURL) {
      this.canRemoveAvatar = true;
      this.avatarImgSrc = e.detail.dataURL;
      this._uploadAvatar(e.detail.blob);
    } else {
      this.avatarImgSrc = this._getAvatarSrc();
      this.canRemoveAvatar = false;
    }
  }

  _uploadAvatar(blob) {
    const uploadAvatar = new XMLHttpRequest();
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', blob);
      const url = '/a/sentry/api/users/' + this.profile.userId + '/profile-image';
      uploadAvatar.open('PUT', url, true);
      uploadAvatar.send(formData);
      uploadAvatar.onload = () => {
        if (uploadAvatar.status === 200) {
          resolve(uploadAvatar.response);
          window.dispatchEvent(new CustomEvent('avatarRemoved', {
            bubbles: true,
            composed: true
          }));
        } else {
          reject(uploadAvater.response);
        }
      };
    });
  }

  _removeAvatar() {
    const el = document.createElement('delete-avatar-dialog');
    el.userId = this.profile.userId;
    el.identifier = 'your';
    el.avatarReplacement = 'a default avatar';
    el.isEnterpriseUser = true;
    this.dispatchEvent(new CustomEvent('openModal', {
      composed: true,
      bubbles: true,
      detail: {el}
    }));
  }

  async _getUserProfile(userId) {
    let profile;
    try {
      profile = await UsersController.getUser(userId);
    } catch (e) {
      console.error(e);
    }
    try {
      this.avatarExists = await UsersController.adminAvatarExists(userId);
    } catch (e) {
      console.error(e);
    }
    if (profile && profile.email) {
      this.profile = profile;
      this._makeImageUri();
      this.canRemoveAvatar = this.avatarExists;
    } else {
      this.profileUnavailable = true;
    }
  }

  _makeImageUri() {
    if (this.profile && this.profile.imageUri) {
      this.imageUri = this.profile.imageUri;
    } else if (profile.userId) {
      this.imageUri = '/a/sentry/api/identicons/' + profile.userId;
    }
    this._refreshAvatar();
  }

  _getTitle() {
    return `Hi, ${store.user.firstName}`;
  }

  async _getLoginDashboardDetailsFromStore() {
    const institutionId = this.selectedInstitution.institution.institutionId;
    if (!store.institutionConfig || !store.institutionConfig[institutionId]) {
      await setInstitutionConfig();
    }
    this.loginDashboardDetails = store.institutionConfig[institutionId].enterpriseConfig.LoginDashboard;
  }

  async _getProjectList() {
    this.projectList =
      (await getInstitutionNavigation()).projects.map((p) => p.name.toUpperCase());
  }

  render() {
    return html`
      <enterprise-wrapper headerTitle="Dashboard" ?dashboard="${true}">
        <div slot="mobile-header-button">
          <user-avatar-dropdown slot="mobile-header-button" loggedInUserId=${store.user.userId} selectedInstitutionShortId=${this.selectedInstitution.institution.shortId}></user-details-dropdown>
        </div>
        <div id="dashboard-wrapper" slot="app">
          <header class="main">
            <jha-flex-wrapper class="content-wrapper">
              <div class="desktop-only">
                <avatar-upload small slot="avatar-upload" avatar-img-src=${this.avatarImgSrc} has-avatar=${true} avatar-exists=${this.avatarExists} user-id=${store.user.userId} show-upload-dropdown=${true} can-remove=${this.canRemoveAvatar}></avatar-upload>
              </div>
              <div>
                <h1>${this.title}</h1>
                <span class="welcome-msg">Welcome to </span>
                <jha-enterprise-fi-name dashboard></jha-enterprise-fi-name>
              </div>
            </jha-flex-wrapper>
          </header>
          <article class="main">
            <jha-flex-wrapper class="content-wrapper">
              <statement-card .loginDashboardDetails=${this.loginDashboardDetails}></statement-card>
              <knowledge-base-card .projectList=${this.projectList}></knowledge-base-card>
            </jha-flex-wrapper>
          </article>
        </div>
      </enterprise-wrapper>
    `;
  }
}

export {styles as DashboardContainerStyles};

customElements.define('dashboard-container', DashboardContainer); // eslint-disable-line @banno/ux/custom-elements-define
export default DashboardContainer;
