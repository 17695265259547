import store from '@banno/platform-ux-shared/session-storage/store';
import {setInstitutionConfig} from '@banno/platform-ux-shared/session-storage/institution-store-helper';
import UserAccessController from '@banno/platform-ux-shared/controllers/user-access-controller';
import SitesService from '@banno/platform-ux-shared/services/sites/sites-service';
import schemasAllowedList from '@banno/platform-ux-shared/utils/schemasAllowedList.js';
import SitesController from '@banno/platform-ux-shared/controllers/sites-controller.js';
import {instHasBannoOnline} from '@banno/platform-ux-shared/utils/banno-online';
import {getCopyOfCoreNav, HELP_LINK} from './core-navigation';

export const getInstitutionNavigation = async () =>
  await evaluatePermissions(getCoreNavigation());

const evaluatePermissions = async baseNavigation => await evaluateUserPermissions(
    await evaluateInstitutionPermissions(baseNavigation)
);

const evaluateInstitutionPermissions = async baseNavigation => {
  const {institution: {institutionId, shortId}} = store.getSelectedInstitution();
  const loggedInUser = store.user;
  if (!store.apps || !store.apps[institutionId]) {
    await store.setInstitutionApps(institutionId);
  }
  const institutionApps = store.apps[institutionId];
  if (!store.institutionConfig || !store.institutionConfig[institutionId]) {
    await setInstitutionConfig();
  }
  const institutionConfig = store.institutionConfig[institutionId];
  const hasBannoOnline = instHasBannoOnline(institutionId);
  const isCreditUnion = evaluateIsCreditUnion(institutionConfig);
  const isAggFi = evaluateIsAggFi(institutionConfig);
  const hasNudetect = evaluateHasNudetect(institutionConfig.webServerConfig);

  if (!institutionApps || !loggedInUser) {
    return {projects: []};
  }

  if (loggedInUser.internal && schemasAllowedList.includes(loggedInUser.userId)) {
    institutionApps.push('schemas');
  }

  // filter projects based on institutionApps
  const institutionNavigation = baseNavigation;
  if (institutionApps[0] !== '*') {
    let appsList = institutionApps;
    if (appsList.includes('forms')) {
      // add support app if forms is in app list
      if (!appsList.includes('mailboxes')) appsList.push('mailboxes');

      // don't duplicate cms and forms apps in navigation
      if (appsList.includes('cms')) appsList = appsList.filter(name => name !== 'forms');
    }
    institutionNavigation.projects =
      baseNavigation.projects.filter(project => {
        if (project.internalOnly && !loggedInUser.internal) {
          return false;
        }
        if (project.institutionShortIds) {
          return project.institutionShortIds.includes(shortId);
        }
        if (project.enterpriseFlag) {
          try {
            return institutionConfig.enterpriseConfig[project.enterpriseFlag.group][project.enterpriseFlag.flag];
          } catch (err) {
            console.log(institutionConfig);
            console.log(project);
            console.error(err);
            return false;
          }
        }
        return appsList.includes(project.name);
      });
  }

  // Set active project based on URL
  const urlParts = (window.location.pathname + '/').split(`/${shortId}/`);
  let activeProject = urlParts[0].replace('/a/', '').replace(/\//g, '');

  // If the active project isn't in their list of projects, they're using a bad URL (or maybe they switched institutions)
  if (!institutionNavigation.projects.map(p => (p.route || p.name)).includes(activeProject)) {
    activeProject = null;
  }

  // Get CMS (Content) site information
  const siteDetails = await getSiteDetails(institutionNavigation, activeProject, institutionId, shortId);

  // filter sub-nav(s) based on institutionAbilities, featureFlag, other
  const checkSubNavItemPermissions = subNavItem => {
    if (activeProject === 'cms' && siteDetails.pendingSelection) return false;
    if (subNavItem.isHeader) return true;
    if (subNavItem.adminsCanView && loggedInUser.internal) return true;
    if (!subNavItem.enableOnInstitutionAbilityOrFeatureFlag && subNavItem.featureFlag && !institutionConfig.featureFlags[subNavItem.featureFlag]) return false;
    if (subNavItem.featureFlagOff && institutionConfig.featureFlags[subNavItem.featureFlagOff]) return false;
    if (subNavItem.enterpriseFlag && !institutionConfig.enterpriseConfig[subNavItem.enterpriseFlag.group][subNavItem.enterpriseFlag.flag]) return false;
    if (subNavItem.internalOnly && !loggedInUser.internal) return false;
    if (subNavItem.hasBannoOnline && !hasBannoOnline) return false;
    if (subNavItem.isCreditUnion && !isCreditUnion) return false;
    if (subNavItem.disableForAgg && isAggFi) return false;
    if (subNavItem.hasNudetect && !hasNudetect) return false;
    const hasEnterpriseConfigSetting = configSetting => {
      let configValue = institutionConfig.enterpriseConfig[configSetting];
      const configSettingKeys = configSetting.split('.');
      if (configSettingKeys.length > 1) {
        configValue = institutionConfig.enterpriseConfig[configSettingKeys[0]][configSettingKeys[1]];
      }
      return configValue;
    };
    if (subNavItem.enterpriseConfig) {
      const configArray =
        Array.isArray(subNavItem.enterpriseConfig) ? subNavItem.enterpriseConfig : [subNavItem.enterpriseConfig];
      let hasEachConfig = true;
      configArray.map(configItem => {
        if (!hasEnterpriseConfigSetting(configItem)) hasEachConfig = false;
      });
      if (!hasEachConfig) return false;
    }
    if (subNavItem.siteFeature && !siteDetails.siteFeatures.includes(subNavItem.siteFeature)) return false;
    if (subNavItem.siteFeatureConfig && subNavItem.siteFeatureConfig) {
      return siteDetails.siteFeaturesConfig[subNavItem.siteFeatureConfig];
    }
    if (subNavItem.siteRequired && (siteDetails.sites || []).length === 0) return false;
    if (subNavItem.institutionAbility) {
      if (subNavItem.institutionOverride && institutionConfig.institutionOverrides &&
        institutionConfig.institutionOverrides.externalTransfers !== undefined) {
        return institutionConfig.institutionOverrides.externalTransfers;
      }
      let hasAbility = false;
      const abilitiesArray =
        Array.isArray(subNavItem.institutionAbility) ? subNavItem.institutionAbility : [subNavItem.institutionAbility];
      abilitiesArray.map(ability => {
        const instAbilities = {...institutionConfig.institutionAbilities, ...institutionConfig.institutionAbilitiesV1};
        if (instAbilities && instAbilities[ability]) {
          if (subNavItem.institutionAbilityExcludeList) {
            hasAbility = ![subNavItem.institutionAbilityExcludeList]
                .includes(instAbilities[ability]);
          } else {
            hasAbility = true;
          }
        }
      });
      if (!hasAbility) {
        if (!subNavItem.enableOnInstitutionAbilityOrFeatureFlag) {
          return false;
        }
        if (subNavItem.featureFlag && !institutionConfig.featureFlags[subNavItem.featureFlag]) {
          return false;
        }
      }
    }
    if (subNavItem.institutionInEnvironmentConfig)  {
      return institutionConfig.environmentConfig &&
        institutionConfig.environmentConfig.enterprise &&
        institutionConfig.environmentConfig.enterprise.institutions &&
        institutionConfig.environmentConfig.enterprise.institutions.hasOwnProperty(institutionId);
    }
    return true;
  };

  // recursively check all institution permissions for each nav item on each nav level
  const checkSubNavPermissions = navItem => {
    if (navItem.subNav) {
      navItem.subNav = navItem.subNav.filter(subNavItem => checkSubNavItemPermissions(subNavItem));
      navItem.subNav.forEach(subNavItem => checkSubNavPermissions(subNavItem));
    }
  };
  institutionNavigation.projects.forEach(project => {
    project.isActive = (project.route || project.name) === activeProject;
    checkSubNavPermissions(project);
  });

  // Remove any sub-nav headers if no sub-nav items remain under them
  institutionNavigation.projects.forEach(project => {
    if (project.subNav) {
      project.subNav = project.subNav.filter(subNavItem =>
        !subNavItem.subNav || (subNavItem.subNav && subNavItem.subNav.length > 0));
    }
  });

  // Add help link
  const KNOWLEDGE_BASE_URL = 'https://knowledge.banno.com';
  const helpLink = HELP_LINK;
  const selectedProject = institutionNavigation.projects.find(p => p.isActive);
  helpLink.path = KNOWLEDGE_BASE_URL;
  if (selectedProject && selectedProject.helpLink) {
    helpLink.path += selectedProject.helpLink;
  }
  institutionNavigation.projects.push(helpLink);

  // determine active nav items
  if (activeProject) {
    updateActiveNavItems(institutionNavigation, shortId);
  }
  return institutionNavigation;
};

export const updateActiveNavItems = (navigation, shortId) => {
  const urlParts = window.location.pathname.split(`/${shortId}/`);
  const activeRouteParts = (urlParts[1] || '').split('/');
  const setSubNavActive = subNavItem => {
    if (!subNavItem.route) return;
    const subNavRoutePartsCount = subNavItem.route.split('/').length;
    if (
      activeRouteParts.length >= subNavRoutePartsCount &&
      subNavItem.route === activeRouteParts.slice(0, subNavRoutePartsCount).join('/')
    ) {
      subNavItem.isActive = true;
    } else if (subNavItem.isActive) {
      subNavItem.isActive = false;
    }
  };
  const activeProjectSubNav = navigation.projects.find(p => p.isActive).subNav;
  if (activeProjectSubNav) {
    activeProjectSubNav.forEach(subNav => {
      if (subNav.subNav) {
        subNav.subNav.forEach(navItemLvl3 => setSubNavActive(navItemLvl3));
      } else {
        setSubNavActive(subNav);
      }
    });
  }
  return navigation;
};

const evaluateIsCreditUnion = ({settingsDetails: {aggregationType}}) => aggregationType === 'SymXchange';

const evaluateIsAggFi = ({settingsDetails: {aggregationType}}) => aggregationType === 'Online Banking';

const evaluateHasNudetect = (webServerConfig) => webServerConfig.hasOwnProperty('ndsConfig') && webServerConfig.ndsConfig.enabled;

const evaluateUserPermissions = async navigation => {
  const {institution: {institutionId}} = store.getSelectedInstitution();
  const institutionApps = store.apps[institutionId];
  let key = 0;

  // function creates a privilege request item AND a way to tie the privilege key and the nav item for use later
  const getPrivilegesRequestKey = ({
    privilegeRequired: {application, privilege = 'read', resource = '*', resourceId = '*', property = '*'},
    navId,
  }) => {
    const privilegesKey = {
      institutionId,
      privilege,
      application,
      resource,
      resourceId,
      property,
      key: key.toString(),
    };
    const keyToNavId = {key, navId};
    key = ++key;
    return {
      privilegesKey,
      keyToNavId,
    };
  };

  // recursively request user privileges for each nav item that requires them
  const privilegesObj = [];
  const privKeysToNavId = [];
  const checkForPrivReqs = navItem => {
    if (navItem.privilegeRequired) {
      const {navId} = navItem;
      const privsToCheck =
        Array.isArray(navItem.privilegeRequired) ? navItem.privilegeRequired : [navItem.privilegeRequired];
      privsToCheck.forEach(privilegeRequired => {
        const {privilegesKey, keyToNavId} = getPrivilegesRequestKey({privilegeRequired, navId});
        privilegesObj.push(privilegesKey);
        privKeysToNavId.push(keyToNavId);
      });
    }
    if (navItem.subNav) navItem.subNav.forEach(subNavItem => checkForPrivReqs(subNavItem));
  };
  navigation.projects.forEach(project => checkForPrivReqs(project));

  // add permissions for support (mailboxes) and forms apps
  privilegesObj.push(...[
    {
      institutionId,
      privilege: 'read',
      application: 'mailboxes',
      resource: 'conversations',
      resourceId: '*',
      property: '*',
      key: 'mailboxes',
    },
    {
      institutionId,
      privilege: 'read',
      application: 'forms',
      resource: 'triage',
      resourceId: '*',
      property: '*',
      key: 'forms',
    }]);

  const permissionsResults = await UserAccessController.validatePermissions(privilegesObj);
  const notAllowedPermissionsResults = permissionsResults.filter(p => (p.key !== 'forms' && p.key !== 'mailboxes') && p.result !== 'allowed');

  // if user can only access forms, remove support links from nav they shouldn't be able to see
  const supportPermissionAllowed = key => permissionsResults.find(res => res.key === key).result === 'allowed';
  const userHasFormsOnlyAccess = !supportPermissionAllowed('mailboxes') && supportPermissionAllowed('forms');
  if (userHasFormsOnlyAccess) {
    navigation.projects.forEach(project => {
      if (project.subNav) {
        project.subNav = project.subNav.filter(navItem => !navItem.hideFormsOnlyAccess);
      }
    });
  }

  // remove all navigation items the user is not "allowed" to see
  if (notAllowedPermissionsResults.length > 0) {
    // Go through each privilege that is NOT allowed and remove those items from navigation
    notAllowedPermissionsResults.forEach(permission => {
      const navIdToRemove = privKeysToNavId.find(k => k.key.toString() === permission.key).navId;
      navigation.projects = navigation.projects.filter(p => {
        if (userHasFormsOnlyAccess && p.showFormsOnlyAccess) return true;
        return p.navId !== navIdToRemove;
      });
      navigation.projects.forEach(project => {
        if (project.subNav) {
          project.subNav = project.subNav.filter(navItem => {
            if (navItem.alwaysShowWithApp && institutionApps.includes(navItem.alwaysShowWithApp)) {
              return true;
            }
            return navItem.navId !== navIdToRemove;
          });
          project.subNav.forEach(subNavItem => {
            if (subNavItem.subNav) {
              subNavItem.subNav = subNavItem.subNav.filter(navItem => navItem.navId !== navIdToRemove);
            }
          });
        }
      });
    });
  }
  return cleanUpEmptyNavGroups(navigation);
};

const removeHeadersFromEmptyGroups = subNav => subNav.filter(subNavItem => {
  if (!subNavItem.header) {
    if (subNavItem.subNav) {
      subNavItem.subNav = removeHeadersFromEmptyGroups(subNavItem.subNav);
    }
    return true;
  }
  const totalItemsUnderHeader = subNav.filter(otherSubNavItem =>
    !otherSubNavItem.header && otherSubNavItem.headerGroup === subNavItem.headerGroup);
  return totalItemsUnderHeader.length > 0;
});

const cleanUpEmptyNavGroups = navigation => {
  navigation.projects.forEach(project => {
    if (project.subNav) {
      project.subNav = removeHeadersFromEmptyGroups(project.subNav);
    }
  });
  return navigation;
};

const getCoreNavigation = () => {
  const {institution: {shortId, institutionId}} = store.getSelectedInstitution();
  const navigationObj = getCopyOfCoreNav();
  const loggedInUserId = store.user.userId;
  // set all paths (or urls) for each nav item
  const getPath = (projectRoute, route) => {
    // set conversations route with basic parameters to avoid showing
    // conversation when leaving Support Settings
    if (route === 'conversations') {
      return `/a/${projectRoute}/${shortId}/${route}?select=true&assignedTo=${loggedInUserId}&status=new%2Copen%2Cpending`;
    }
    // new platform uses the institutionId instead of the shortId
    if (projectRoute === 'back-office') {
      return `/a/${projectRoute}/${institutionId}${route ? `/${route}` : ''}`;
    }
    if (projectRoute === 'tooling') {
      return `/a/${projectRoute}/institutions?fiid=${institutionId}`;
    }
    return `/a/${projectRoute}/${shortId}${route ? `/${route}` : ''}`;
  };
  const setSubNavPaths = (projectRoute, navItem) => {
    if (navItem.header) return;
    navItem.path = getPath(projectRoute, navItem.route);
    navItem.navId = `${projectRoute}-${navItem.route.replace('/', '-')}`;
    if (navItem.subNav) {
      navItem.subNav.forEach(subNavItem => setSubNavPaths(projectRoute, subNavItem));
    }
  };
  navigationObj.projects.forEach(project => {
    if (!project.path) {
      if (project.hardRedirect || (project.secondary && project.route)) {
        project.path = getPath(project.route);
      }
    }
    project.navId = project.name;
    if (project.subNav) {
      project.subNav.forEach(subNavItem => setSubNavPaths((project.route || project.name), subNavItem));
    }
  });
  return navigationObj;
};

const getSiteDetails = async (institutionNavigation, activeProject, institutionId, shortId) => {
  // Get sites if Content (or CMS) is in the projects list
  let sites = [];
  let siteFeatures = [];
  let siteFeaturesConfig = {};
  let adminSettingsUrl = '';
  let pendingSelection = false;
  const cmsProjectNav = institutionNavigation.projects.find(project => project.name === 'cms');
  if (cmsProjectNav && activeProject === 'cms') {
    sites = await SitesService.getSites(institutionId);
    if (sites.length > 0) {
      let site; let siteDomain;
      if (sites.length > 1) {
        const addSiteToPaths = (domain, nav) => {
          nav.forEach(navItem => {
            if (navItem.path && !navItem.ignoreSelectedSite) {
              navItem.path = navItem.path.concat(`/site/${domain}`);
            }
            if (navItem.subNav) {
              addSiteToPaths(domain, navItem.subNav);
            }
          });
        };
        siteDomain = sessionStorage.getItem('cms-domain');
        site = sites.find(site => site.domains.includes(siteDomain));
        if (site) {
          addSiteToPaths(siteDomain, cmsProjectNav.subNav);
        } else {
          pendingSelection = true;
        }
      } else {
        site = sites[0];
        siteDomain = site.preferredDomains[SitesController.getEnvironment()];
      }

      if (site) {
        siteFeatures = site.features;
        siteFeaturesConfig = site.config;

        // set Admin Settings URL in CMS navigation
        adminSettingsUrl = `/a/cms/api/site/${siteDomain}/settings/site`;
      } else {
        cmsProjectNav.hardRedirect = true;
        cmsProjectNav.path = `/a/cms/${shortId}`;
      }
      cmsProjectNav.subNav.find(subNav => subNav.subNav).subNav
          .find(subNav => subNav.adminSettingsRoute).path = adminSettingsUrl;
    } else {
      cmsProjectNav.hardRedirect = true;
      cmsProjectNav.path = `/a/cms/${shortId}`;
    }
  } else if (cmsProjectNav) {
    cmsProjectNav.hardRedirect = true;
    cmsProjectNav.path = `/a/cms/${shortId}`;
  }
  return {
    sites,
    siteFeatures,
    siteFeaturesConfig,
    pendingSelection
  };
};
