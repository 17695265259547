/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconMonitorElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-monitor';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M12,18.7V12H6.72C7.89,17.17,11,18.43,12,18.7Z"/>
    <path d="M12,12h5.28a18.64,18.64,0,0,0,.43-3.2L12,5.58Z"/>
    <path d="M20.37,6.85l-8-4.5a.75.75,0,0,0-.74,0l-8,4.5a.75.75,0,0,0-.38.65,23.93,23.93,0,0,0,.4,4.5A13.07,13.07,0,0,0,7.5,19.49a8.81,8.81,0,0,0,4.41,2.25h.19a8.81,8.81,0,0,0,4.41-2.25A13.07,13.07,0,0,0,20.35,12a23.93,23.93,0,0,0,.4-4.5A.75.75,0,0,0,20.37,6.85ZM18.82,12c-1.43,7-6.07,8.11-6.82,8.24h0C11.24,20.1,6.62,19,5.18,12a21.67,21.67,0,0,1-.43-4.06L12,3.86l7.25,4.08A21.67,21.67,0,0,1,18.82,12Z"/>
    </svg>
  `;
  }
};

customElements.define(window.JhaIconMonitorElement.is,
    window.JhaIconMonitorElement);
export default window.JhaIconMonitorElement;
