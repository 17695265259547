import {LitElement, html, css} from 'lit-element';
import {jhaStyles} from '@banno/platform-ux-shared/styles/jha-styles';
import {getInstitutionNavigation} from './helpers/navigation-helper';
import './jha-enterprise-nav-primary';

const styles = css`
  :host {
    visibility: visible;
    box-sizing: border-box;
    position: fixed;
    z-index: 9;
  }
  @media (max-width: 1024px) {
    :host {
      display: block;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1039;
      height: 100vh;
      visibility: hidden;
      opacity: 0;
      outline: 0;
      transition: opacity 0.3s 0s,visibility 0s 0.3s;
      background: rgba(34, 34, 34, 0.6);
    }
    :host([mobilenavopen]) {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.3s 0s,visibility 0s 0s;
    }
    jha-enterprise-nav-primary {
      transform: translateX(-100%);
      transition-property: transform;
      transition-duration: .3s;
      transition-timing-function: cubic-bezier(.1,.5,.1,1);
    }
    :host([mobilenavopen]) jha-enterprise-nav-primary {
      transform: translateX(0);
    }
  }
`;

class JhaEnterpriseNav extends LitElement {
  static get styles() {
    return [jhaStyles, styles];
  }

  constructor() {
    super();
    this.boundHandleNavClick_ = this._handleNavClick.bind(this);
    this.boundHandleWindowClick_ = this._handleWindowClick.bind(this);
    this._boundInstitutionChanged = this._handleInstitutionChanged.bind(this);
    this._boundCimsOnlyChanged = this._handleCimsOnly.bind(this);
  }

  static get properties() {
    return {
      activeProjectName: {type: String},
      activeProjectNavigation: {type: Object},
      loggedInUser: {type: Object},
      mobileNavOpen: {type: Boolean},
      navigation: {type: Object},
      selectedInstitution: {type: Object},
      navigationLoaded: {type: Boolean},
      routeChangedByAppUrl: {type: String}
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.navigationLoaded = false;
    this.getNavigation();
    this.addEventListener('selected-project-change', this._handleSelectedProjectChanged);
    document.addEventListener('institution-changed', this._boundInstitutionChanged);
    document.addEventListener('cims-only', this._boundCimsOnlyChanged);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('selected-project-change', this._handleSelectedProjectChanged);
    document.removeEventListener('institution-changed', this._boundInstitutionChanged);
    document.removeEventListener('cims-only', this._boundCimsOnlyChanged);
  }

  updated(changedProperties) {
    changedProperties.forEach((oldValue, propName) => {
      switch (propName) {
        case 'mobileNavOpen':
          if (this.mobileNavOpen && !oldValue) {
            this._listenForClickOutside();
          }
          if (!this.mobileNavOpen && oldValue) {
            this._removeListenForClickOutside();
          }
          break;
      }
    });
  }

  _listenForClickOutside() {
    this.shadowRoot.getElementById('jha-enterprise-nav-primary')
        .addEventListener('click', this.boundHandleNavClick_, false);
    window.requestAnimationFrame(() => window.addEventListener('click', this.boundHandleWindowClick_));
  }

  _removeListenForClickOutside() {
    this.shadowRoot.getElementById('jha-enterprise-nav-primary')
        .removeEventListener('click', this.boundHandleNavClick_, false);
    window.removeEventListener('click', this.boundHandleWindowClick_);
  }

  _handleInstitutionChanged({detail: {selectedInstitution}}) {
    if (selectedInstitution && selectedInstitution !== this.selectedInstitution) {
      this.selectedInstitution = selectedInstitution;
      this.getNavigation();
    }
  }

  _handleNavClick(event) {
    const isLink = event.path && event.path[0].tagName === 'A' && event.path[0].href;
    if (!isLink) {
      event.stopPropagation();
    }
  }

  _handleWindowClick() {
    this.dispatchEvent(new CustomEvent('toggle-mobile-nav', {
      bubbles: true,
      composed: true
    }));
  }

  _handleSelectedProjectChanged({detail: {project}}) {
    this.activeProjectNavigation = project;
    this.navigation.projects.forEach(p => p.isActive = (project.name === p.name));
    this.activeProjectName = (project.route || project.name);
    this.navigationLoaded = true;
  }

  _handleCimsOnly() {
    this.cimsOnly = true;
    this._checkCimsNavigation();
  }

  _checkCimsNavigation() {
    if (this.activeProjectName === 'people' && this.activeProjectNavigation && this.cimsOnly) {
      const cimsSubNav = this.activeProjectNavigation.subNav.filter((subNav) => {
        return subNav.displayName === 'Users';
      });
      cimsSubNav[0].path = cimsSubNav[0].path + '-cims';
      this.activeProjectNavigation = Object.assign({}, this.activeProjectNavigation, {subNav: cimsSubNav});
    }
  }

  async getNavigation() {
    this.navigation = await getInstitutionNavigation();
    const newlyActiveProject = this.navigation.projects.find(p => p.isActive);
    if (!this.activeProjectNavigation ||
        (newlyActiveProject && this.activeProjectName.name !== newlyActiveProject.name)) {
      this.activeProjectNavigation = this.navigation.projects.find(p => p.isActive) || {};
    }
    if (this.activeProjectNavigation) {
      const {name, route} = this.activeProjectNavigation;
      if (name === 'cms' && this.activeProjectNavigation.subNav.length > 0) {
        sessionStorage.setItem('cms-default-route', this.activeProjectNavigation.subNav[0].route);
      }
      this.activeProjectName = route || name;
      this._checkCimsNavigation();
    }
  }

  _projectIsSelected(activeProjectName, activeProjectNavigation) {
    return activeProjectName && activeProjectNavigation.subNav && this.activeProjectNavigation.subNav.length > 0;
  }

  render() {
    return html`
      <div flex>
        <jha-enterprise-nav-primary
          .activeProjectName=${this.activeProjectName}
          .activeProjectNavigation=${this.activeProjectNavigation}
          id="jha-enterprise-nav-primary"
          .loggedInUser=${this.loggedInUser}
          ?mobileNavOpen=${this.mobileNavOpen}
          .navigation=${this.navigation}
          ?projectSelected=${this._projectIsSelected(this.activeProjectName, this.activeProjectNavigation)}
          .routeChangedByAppUrl=${this.routeChangedByAppUrl}>
        </jha-enterprise-nav-primary>
      </div>
    `;
  }
}

export {styles as JhaEnterpriseNavStyles};

customElements.define('jha-enterprise-nav', JhaEnterpriseNav); // eslint-disable-line @banno/ux/custom-elements-define
export default JhaEnterpriseNav;
