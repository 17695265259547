/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconBrowserElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-browser';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M20,5.25H4A.75.75,0,0,0,3.25,6V19a.75.75,0,0,0,.75.75H20a.75.75,0,0,0,.75-.75V6A.75.75,0,0,0,20,5.25Zm-.75,1.5v2.5H4.75V6.75ZM4.75,18.25v-7.5h14.5v7.5Z"/>
    <rect x="6" y="7.5" width="2" height="1"/>
    <rect x="9" y="7.5" width="2" height="1"/>
    </svg>
  `;
  }
};

customElements.define(window.JhaIconBrowserElement.is,
    window.JhaIconBrowserElement);
export default window.JhaIconBrowserElement;
