"use strict";
const _userImageFactory = {
  url: (userId) => `${SentryApi.SENTRY_PATH}/users/${userId}/profile-image`,
  arguments: ["userId"],
  requestMethods: ["DELETE", "GET", "PUT"],
};
const _userImageExistsFactory = {
  url: (userId) =>
    `${SentryApi.SENTRY_PATH}/users/${userId}/profile-image/exists`,
  arguments: ["userId"],
  requestMethods: ["GET"],
};
class SentryApi {
  static get SENTRY_PATH() {
    return "/a/sentry/api";
  }
  // Application Service
  static applicationsFactory() {
    return {
      url: `${SentryApi.SENTRY_PATH}/applications`,
      requestMethods: ["GET"],
    };
  }
  static applicationResourcesFactory() {
    return {
      url: `${SentryApi.SENTRY_PATH}/applications/resources`,
      requestMethods: ["GET"],
    };
  }
  // Group Privileges Service
  static groupPrivilegesFactory() {
    return {
      url: function (institutionId, groupId) {
        return `${SentryApi.SENTRY_PATH}/institutions/${institutionId}/groups/${groupId}/privileges`;
      },
      arguments: ["institutionId", "groupId"],
      requestMethods: ["GET", "PUT", "DELETE"],
    };
  }
  // Group Service
  static institutionGroupsFactory() {
    return {
      url: function (institutionId) {
        return `${SentryApi.SENTRY_PATH}/institutions/${institutionId}/groups`;
      },
      arguments: ["institutionId"],
      requestMethods: ["GET", "POST"],
    };
  }
  static institutionGroupFactory() {
    return {
      url: function (institutionId, groupId) {
        return `${SentryApi.SENTRY_PATH}/institutions/${institutionId}/groups/${groupId}`;
      },
      arguments: ["institutionId", "groupId"],
      requestMethods: ["GET", "PUT", "DELETE"],
    };
  }
  static institutionGroupUsersFactory() {
    return {
      url: function (institutionId, groupId) {
        return `${SentryApi.SENTRY_PATH}/institutions/${institutionId}/groups/${groupId}/users`;
      },
      arguments: ["institutionId", "groupId"],
      requestMethods: ["GET"],
    };
  }
  // Institution Application Service
  static institutionApplicationsFactory() {
    return {
      url: function (institutionId) {
        return `${SentryApi.SENTRY_PATH}/institutions/${institutionId}/applications`;
      },
      arguments: ["institutionId"],
      requestMethods: ["GET"],
    };
  }
  static institutionApplicationResourcesFactory() {
    return {
      url: function (institutionId) {
        return `${SentryApi.SENTRY_PATH}/institutions/${institutionId}/applications/resources`;
      },
      arguments: ["institutionId"],
      requestMethods: ["GET"],
    };
  }
  static institutionApplicationFactory() {
    return {
      url: function (institutionId, applicationId) {
        return `${SentryApi.SENTRY_PATH}/institutions/${institutionId}/applications/${applicationId}`;
      },
      arguments: ["institutionId", "applicationId"],
      requestMethods: ["PUT"],
    };
  }
  static institutionApplicationUsersFactory() {
    return {
      url: function (institutionId, applicationId) {
        return `${SentryApi.SENTRY_PATH}/institutions/${institutionId}/applications/${applicationId}/users`;
      },
      arguments: ["institutionId", "applicationId"],
      requestMethods: ["GET"],
    };
  }
  // Login Service
  static sentryLoginFactory() {
    return {
      url: `${SentryApi.SENTRY_PATH}/login`,
      requestMethods: ["GET", "POST", "DELETE"],
    };
  }
  static sentryPasswordResetFactory() {
    return {
      url: `${SentryApi.SENTRY_PATH}/forgot-password`,
      requestMethods: ["POST"],
    };
  }
  static sentryPasswordSetFactory() {
    return {
      url: `${SentryApi.SENTRY_PATH}/passwords`,
      requestMethods: ["POST"],
    };
  }
  static sentryPasswordCheckFactory() {
    return {
      url: `${SentryApi.SENTRY_PATH}/passwords/validate`,
      requestMethods: ["PUT"],
    };
  }
  // User Application Service
  static userApplicationsFactory() {
    return {
      url: function (userId) {
        return `${SentryApi.SENTRY_PATH}/users/${userId}/applications`;
      },
      arguments: ["userId"],
      requestMethods: ["GET"],
    };
  }
  // User Institution Service
  static userInstitutionsFactory() {
    return {
      url: function (userId) {
        return `${SentryApi.SENTRY_PATH}/users/${userId}/institutions`;
      },
      arguments: ["userId"],
      requestMethods: ["GET", "PUT", "DELETE"],
    };
  }
  static userInstitutionFactory() {
    return {
      url: function (userId, institutionId) {
        return `${SentryApi.SENTRY_PATH}/users/${userId}/institutions/${institutionId}`;
      },
      arguments: ["userId", "institutionId"],
      requestMethods: ["DELETE"],
    };
  }
  // User Privileges Service
  static userPrivilegesFactory() {
    return {
      url: function (userId) {
        return `${SentryApi.SENTRY_PATH}/users/${userId}/privileges`;
      },
      arguments: ["userId"],
      requestMethods: ["GET"],
    };
  }
  // User Selected Institution Service
  static userSelectedInstitutionsFactory() {
    return {
      url: function (userId) {
        return `${SentryApi.SENTRY_PATH}/users/${userId}/selected-institutions`;
      },
      arguments: ["userId"],
      requestMethods: ["PUT", "DELETE"],
    };
  }
  static userSelectedInstitutionFactory() {
    return {
      url: function (userId, institutionId) {
        return `${SentryApi.SENTRY_PATH}/users/${userId}/selected-institutions/${institutionId}`;
      },
      arguments: ["userId", "institutionId"],
      requestMethods: ["DELETE"],
    };
  }
  // User Service
  static usersFactory() {
    return {
      url: `${SentryApi.SENTRY_PATH}/users`,
      requestMethods: ["GET", "POST"],
    };
  }
  static userFactory() {
    return {
      url: function (userId) {
        return `${SentryApi.SENTRY_PATH}/users/${userId}`;
      },
      arguments: ["userId"],
      requestMethods: ["GET", "PUT", "DELETE"],
    };
  }
  static userLockoutFactory() {
    return {
      url: function (userId) {
        return `${SentryApi.SENTRY_PATH}/users/${userId}/lockouts`;
      },
      arguments: ["userId"],
      requestMethods: ["DELETE"],
    };
  }
  static institutionUsersFactory() {
    return {
      url: function (institutionId) {
        return `${SentryApi.SENTRY_PATH}/institutions/${institutionId}/users`;
      },
      arguments: ["institutionId"],
      requestMethods: ["GET"],
    };
  }
  static userGroupsFactory() {
    return {
      url: function (userId) {
        return `${SentryApi.SENTRY_PATH}/users/${userId}/groups`;
      },
      arguments: ["userId"],
      requestMethods: ["GET", "PUT", "DELETE"],
    };
  }
  static userGroupFactory() {
    return {
      url: function (userId, groupId) {
        return `${SentryApi.SENTRY_PATH}/users/${userId}/groups/${groupId}`;
      },
      arguments: ["userId", "groupId"],
      requestMethods: ["DELETE"],
    };
  }
  static userNotificationHistoryFactory() {
    return {
      url: function (userId, institutionId, offset, count) {
        return `${SentryApi.SENTRY_PATH}/users/${userId}/institutions/${institutionId}/notifications?offset=${offset}&count=${count}`;
      },
      arguments: ["userId", "institutionId", "offset", "count"],
      requestMethods: ["GET"],
    };
  }
  static userNotificationFactory() {
    return {
      url: function (userId, institutionId, emailId) {
        return `${SentryApi.SENTRY_PATH}/users/${userId}/institutions/${institutionId}/notifications/${emailId}`;
      },
      arguments: ["userId", "institutionId", "emailId"],
      requestMethods: ["GET"],
    };
  }
  static userImageFactory() {
    return _userImageFactory;
  }
  static userImageExistsFactory() {
    return _userImageExistsFactory;
  }
}
export default SentryApi;
