/* eslint-disable @banno/ux/element-annotated */
import {LitElement, html, css} from 'lit-element';
import {jhaStyles} from '@banno/platform-ux-shared/styles/jha-styles';
import '@banno/platform-ux-shared/components/polymer3/jha/cards/jha-card-platform';
import '@banno/platform-ux-shared/components/polymer3/jha/grid/jha-grid';
import '@banno/platform-ux-shared/components/polymer3/jha/buttons/jha-link-button';
import {KNOWLEDGE_BASE_LINKS} from './knowledge-base-card-config';

const styles = css`
  :host {
    border-radius: 6px;
    padding-bottom: 40px;
  }
  jha-card-platform {
    margin: 0;
    width: 100%;
    min-width: 320px;
  }
  jha-card-platform header {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }
  article {
    padding: 0;
    margin: 0 0 0 24px;
  }
  jha-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px 0;
    padding: 24px 0 0;
  }
  jha-grid > div {
    border-bottom: 1px solid var(--jha-border-color);
    margin-bottom: 16px;
    padding-right: 16px;
    padding-bottom: 8px;
  }
  jha-grid > div[final-row] {
    border-bottom: none;
    margin-bottom: 0;
  }
  div[flex] > *:first-child {
    fill: var(--jha-text-base);
    margin-right: 16px;
  }
  h4 {
    margin: 0;
  }
  li {
    list-style: none;
  }
  jha-link-button {
    --jha-link-button-text-align: left;
    --jha-button-line-height: 24px;
    --jha-link-button-white-space: wrap;
    --jha-button-link-padding-vertical: 4px;
  }
  footer {
    text-align: left;
    font-weight: 500;
    border-top: 1px solid var(--jha-border-color);
    font-size: 14px;
  }
  @media (max-width: 1024px) {
    jha-card-platform {
      margin: 0;
      width: 80%;
      z-index: 0;
      position: relative;
      width: 100%;
      margin: 0 0 16px 0;
    }
    jha-grid {
      grid-template-columns: 1fr;
    }
    jha-grid > div[final-row] {
      border-bottom: 1px solid var(--jha-border-color);
      margin-bottom: 32px;
      padding-right: 16px;
      padding-bottom: 8px;
    }
    jha-grid > div[final-row]:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
`;

class KnowledgeBaseCard extends LitElement {
  static get styles() {
    return [jhaStyles, styles];
  }

  static get properties() {
    return {
      activeProjectLinks: {type: Array},
      projectList: {type: Array, value: () => []},
    };
  }

  updated(changedProperties) {
    changedProperties.forEach((oldValue, propName) => {
      if (propName === 'projectList' && !oldValue && this.projectList) {
        this._setActiveProjectLinks();
      }
    });
  }

  _setActiveProjectLinks() {
    const institutionProjects = ['MOBILE_AND_ONLINE', ...this.projectList.map((project) => project.toUpperCase())];
    this.activeProjectLinks = [];
    Object.keys(KNOWLEDGE_BASE_LINKS).map((key) => {
      if (institutionProjects.includes(key)) {
        this.activeProjectLinks.push(KNOWLEDGE_BASE_LINKS[key]);
      };
    });
    const lastProjectsRowTotalItems = this.activeProjectLinks.length % 2 === 1 ? 1 : 2;
    for (let i = 1; i < lastProjectsRowTotalItems + 1; i++) {
      this.activeProjectLinks[this.activeProjectLinks.length - i].isFinalRow = true;
    }
  }

  render() {
    return html`
      <jha-card-platform id="knowledge-base">
        <header>
          Knowledge Base
        </header>
        <article>
          ${this.activeProjectLinks && this.activeProjectLinks.length > 0 ? html`
            <jha-grid>
              ${this.activeProjectLinks.map((project) => html`
                <div ?final-row=${project.isFinalRow}>
                  <div flex>
                    ${project.icon}
                    <h4> ${project.name}</h4>
                  </div>
                  <ul>
                    ${project.links.map((link) => html`
                      <li>
                        <jha-link-button link footer href=${link.href} target="_blank" ?semi-bold=${link.isSemiBold}>
                          ${link.name}
                        </jha-link-button>`)}
                      </li>
                  </ul>
                </div>
              `)}
            </jha-grid>
          ` : null}
        </article>
        <footer>
          <jha-link-button link footer href="https://knowledge.banno.com/" target="_blank">
            View all articles on the Knowledge Base
          </jha-link-button>
        </footer>
      </jha-card-platform>
    `;
  }
}

export {styles as KnowledgeBaseCardStyles};

customElements.define('knowledge-base-card', KnowledgeBaseCard); // eslint-disable-line @banno/ux/custom-elements-define
export default KnowledgeBaseCard;
