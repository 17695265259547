import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import Cropper from 'cropperjs';
import '@banno/platform-ux-shared/components/polymer3/jha/buttons/jha-button.js';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-close.js';

/**
 * @polymer
 * @customElement
 */
class AvatarCroppingElement extends PolymerElement {
  static get is() {
    return 'avatar-cropping';
  }

  static get properties() {
    return {
      avatarType: String,
    };
  }

  _cancel() {
    this.dispatchEvent(new CustomEvent('closeModal', {
      bubbles: true,
      composed: true
    }));
  }

  _cropAndUpload() {
    const croppedImage = this.cropper.getCroppedCanvas({
      maxWidth: 500,
      maxHeight: 500
    });
    this.$['canvas-container'].removeAttribute('cropping');
    croppedImage.toBlob((blob) => {
      this.dispatchEvent(new CustomEvent('cropped-avatar', {
        detail: {
          blob: blob,
          dataURL: croppedImage.toDataURL()
        }
      }));
      this.cropper.destroy();
    });
  }

  startCropping(file) {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.$['canvas-container'].setAttribute('cropping', true);
      const ctx = this.$.cropper.getContext('2d');
      this.img = new Image();
      this.img.addEventListener('load', (e) => {
        this.$.cropper.height = this.img.height;
        this.$.cropper.width = this.img.width;
        ctx.drawImage(this.img, 0, 0);
        this.cropper = new Cropper(this.$.cropper, {
          'initialAspectRatio': 4 / 3,
          'aspectRatio': 1,
          'dragMode': 'move',
          'movable': false,
          'viewMode': 2,
          'restore': false,
          'guides': true,
          'center': true,
          'zoomable': false,
          'cropBoxMovable': true,
          'cropBoxResizable': true,
          'toggleDragModeOnDblclick': false,
          'checkOrientation': false
        });
      });
      this.img.src = e.target.result;
    };
    fileReader.readAsDataURL(file);
  }

  static get template() {
    return html`
    <style>
      :host {
        display: flex;
        flex-direction: column;
      }
      header {
        padding: 32px;
        border-bottom: 1px solid var(--jha-border-color);
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        color: var(--jha-text-dark);
      }
      header jha-button {
        position: absolute;
        top: 30px;
        right: 24px;
        opacity: 0.6;
        transition: opacity linear 0.3s;
      }
      header jha-button:hover {
        opacity: 1;
      }
      jha-icon-close {
        width: 18px;
        height: 18px;
        fill: var(--jha-color-base);
      }
      article {
        max-height: calc(100vh - 275px);
        overflow-y: auto;
        color: var(--jha-text-base);
        padding: 15px;
      }
      footer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 16px;
      }
      #cropper {
        max-width: 100%;
      }
      #canvas-container {
        display: none;
      }
      #canvas-container[cropping] {
        display: block;
      }
      polymer-file-uploader {
        padding: 0;
      }
      jha-dropdown-menu {
        right: auto;
        left: 40px;
      }
      jha-dropdown-menu-item {
        padding: 10px 16px;
      }
      .upload {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 28px;
        width: 28px;
        position: absolute;
        left: 40px;
        bottom: 0;
        border-radius: 50%;
        background-color: #ffffff;
      }
      jha-icon-edit {
        fill: #6b757b;
        width: 16px;
        height: 16px;
      }
      jha-icon-camera {
        fill: var(--jha-color-primary);
        width: 16px;
        height: 16px;
      }
      .cropper-container {
        direction: ltr;
        font-size: 0;
        line-height: 0;
        position: relative;
        -ms-touch-action: none;
        touch-action: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      .cropper-container img {
        /* Avoid margin top issue (Occur only when margin-top <= -height) */
        display: block;
        height: 100%;
        image-orientation: 0deg;
        max-height: none !important;
        max-width: none !important;
        min-height: 0 !important;
        min-width: 0 !important;
        width: 100%;
      }
      .cropper-wrap-box,
      .cropper-canvas,
      .cropper-drag-box,
      .cropper-crop-box,
      .cropper-modal {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }
      .cropper-wrap-box,
      .cropper-canvas {
        overflow: hidden;
      }
      .cropper-drag-box {
        background-color: #fff;
        opacity: 0;
      }
      .cropper-modal {
        background-color: #000;
        opacity: .5;
      }
      .cropper-view-box {
        display: block;
        border-radius: 50%;
        border: 1px solid #39f;
        height: 100%;
        overflow: hidden;
        width: 100%;
      }
      .cropper-dashed {
        border: 0 dashed #eee;
        display: block;
        opacity: .5;
        position: absolute;
      }
      .cropper-dashed.dashed-h {
        border-bottom-width: 1px;
        border-top-width: 1px;
        height: 33.33333%;
        left: 3%;
        top: 33.33333%;
        width: 94%;
      }
      .cropper-dashed.dashed-v {
        border-left-width: 1px;
        border-right-width: 1px;
        height: 94%;
        left: 33.33333%;
        top: 3%;
        width: 33.33333%;
      }
      .cropper-center {
        display: block;
        height: 0;
        left: 50%;
        opacity: .75;
        position: absolute;
        top: 50%;
        width: 0;
      }
      .cropper-center:before,
      .cropper-center:after {
        background-color: #eee;
        content: ' ';
        display: block;
        position: absolute;
      }
      .cropper-center:before {
        height: 1px;
        left: -3px;
        top: 0;
        width: 7px;
      }
      .cropper-center:after {
        height: 7px;
        left: 0;
        top: -3px;
        width: 1px;
      }
      .cropper-face,
      .cropper-line,
      .cropper-point {
        height: 100%;
        opacity: .1;
        position: absolute;
        width: 100%;
      }
      .cropper-face {
        border-radius: 50%;
        background-color: #fff;
        left: 0;
        top: 0;
      }
      .cropper-line {
        display: none!important;
      }
      .cropper-point {
        background-color: #39f;
        height: 5px;
        opacity: .75;
        width: 5px;
      }
      .cropper-point.point-e {
        cursor: ew-resize;
        margin-top: -3px;
        right: -3px;
        top: 50%;
      }
      .cropper-point.point-n {
        cursor: ns-resize;
        left: 50%;
        margin-left: -3px;
        top: -3px;
      }
      .cropper-point.point-w {
        cursor: ew-resize;
        left: -3px;
        margin-top: -3px;
        top: 50%;
      }
      .cropper-point.point-s {
        bottom: -3px;
        cursor: s-resize;
        left: 50%;
        margin-left: -3px;
      }
      .cropper-point.point-ne {
        display: none!important;
      }
      .cropper-point.point-nw {
        display: none!important;
      }
      .cropper-point.point-sw {
        display: none!important;
      }
      .cropper-point.point-se {
        display: none!important;
      }
      .cropper-invisible {
        opacity: 0;
      }
      .cropper-bg {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC');
      }
      .cropper-hide {
        display: block;
        height: 0;
        position: absolute;
        width: 0;
      }
      .cropper-hidden {
        display: none !important;
      }
      .cropper-move {
        border-radius: 50%;
        cursor: move;
      }
      .cropper-crop {
        cursor: crosshair;
      }
      .cropper-disabled .cropper-drag-box,
      .cropper-disabled .cropper-face,
      .cropper-disabled .cropper-line,
      .cropper-disabled .cropper-point {
        cursor: not-allowed;
      }
      jha-button[link] {
        --jha-button-link-text: var(--jha-text-base);
        margin-left: 24px;
      }
      @media (max-width: 480px) {
        article {
          max-height: calc(100vh - 197px);
        }
      }
    </style>
    <header>
      Crop [[avatarType]]
      <jha-button sync="" icon="" type="button" on-click="_cancel">
        <jha-icon-close></jha-icon-close>
      </jha-button>
    </header>
    <article>
      <div id="canvas-container">
        <canvas id="cropper"></canvas>
      </div>
    </article>
    <footer>
      <jha-button primary="" wide="" type="button" on-click="_cropAndUpload" is-loading="[[isSaving]]">Save</jha-button>
      <jha-button link sync type="button" on-click="_cancel">Cancel</jha-button>
    </footer>
  `;
  }
}
customElements.define(AvatarCroppingElement.is, AvatarCroppingElement);
export default AvatarCroppingElement;
