import {LitElement, html, css} from 'lit-element';
import {jhaStyles} from '@banno/platform-ux-shared/styles/jha-styles';
import store from '@banno/platform-ux-shared/session-storage/store.js';
import '@banno/platform-ux-shared/components/polymer3/jha/avatars/jha-avatar.js';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-circle-user.js';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-logout.js';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-switch.js';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-chevron-up';
import '@banno/platform-ux-shared/components/segments/segmented-permissions-logout-dialog.js';
import '../jha-enterprise-institution-selector.js';
import DialogMixin from '@banno/platform-ux-shared/mixins/dialog.js';

const styles = css`
  #user-menu-desktop {
    margin-bottom: 24px;
  }
  #user-menu-mobile {
    display: none;
  }
  a,
  button {
    display: flex;
    align-items: flex-start;
    color: var(--jha-text-dark);
    margin-bottom: 8px;
  }
  a:last-of-type {
    margin-bottom: 0;
  }
  a:hover,
  button:hover {
    color: var(--jha-text-dark);
    background-color: var(--jha-color-gray-light);
    font-weight: 400;
    border-radius: 6px;
    text-decoration: none;
  }
  jha-icon-circle-user,
  jha-icon-switch,
  jha-icon-logout {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  .sub-title {
    font-size: 11px;
    color: var(--jha-text-base);
  }
  .dropdown {
    display: none;
    position: absolute;
    min-width: 220px;
    max-width: 350px;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 2px 6px 0px rgba(51, 51, 51, 0.16);
    padding: 8px;
    z-index: 1010;
    text-align: left;
  }
  .dropdown[show] {
    display: block;
  }
  #user-menu-desktop .dropdown {
    left: 76px;
    bottom: 24px;
  }
  button {
    width: 100%;
  }
  .dropdown a,
  .dropdown button {
    font-size: 12px;
    padding: 8px;
  }
  #avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    font-size: 12px;
    font-weight: 600;
    margin-top: 24px;
    background-color: #d8d8d8;
    border-radius: 50%;
  }
  #avatar span, #avatar jha-icon-chevron-up {
    display: none;
  }
  jha-avatar {
    margin-right: 0;
  }
  @media (max-width: 1024px) {
    :host {
      padding: 0;
      border-top: 1px solid var(--jha-border-color);
      position: fixed;
      bottom: 0;
      z-index: 1010;
      width: 270px;
    }
    #user-menu-desktop {
      display: none;
    }
    #user-menu-mobile {
      display: block;
    }
    #user-menu-mobile-trigger {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      cursor: pointer;
      padding: 8px 8px 8px 16px;
    }
    #user-menu-mobile-options {
      opacity: 0;
      visibility: hidden;
      height: 0;
      overflow-y: hidden;
      transition: all .25s ease-in-out;
    }
    #user-menu-mobile[show] {
      box-shadow: 2px 0px 5px 0px rgba(0, 0, 0, 0.16);
    }
    #user-menu-mobile[show] #user-menu-mobile-options {
      opacity: 1;
      visibility: visible;
      height: 152px;
      padding-top: 8px;
    }
    #user-menu-mobile #user-menu-mobile-trigger jha-icon-chevron-up {
      transition: transform 0.25s ease-in-out;
      color: #191919;
    }
    #user-menu-mobile[show] #user-menu-mobile-trigger jha-icon-chevron-up {
      transform: rotate(180deg);
    }
    #user-menu-mobile-options a,
    #user-menu-mobile-options button {
      font-size: 15px;
      padding: 8px 8px 8px 16px;
    }
    button > div {
      flex: 1;
    }
    .sub-title {
      width: calc(100% - 18px);
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
    jha-avatar {
      margin-right: 12px;
    }
    span {
      color: var(--jha-text-dark);
    }
  }
`;

class JhaEnterpriseNavUserMenu extends DialogMixin(LitElement) {
  static get styles() {
    return [jhaStyles, styles];
  }

  static get properties() {
    return {
      loggedInUser: {type: Object},
      menuOptionsShown: {
        type: Boolean,
        value: false,
      },
      selectedInstitutionShortId: {type: String},
      avatarUri: {type: String},
      avatarUriFull: {type: String},
      selectedInstitution: {type: Object},
      userInstitutions: {type: Array},
      isXperience: {
        type: Boolean,
        value: false,
      },
    };
  }

  constructor() {
    super();
    this.userInstitutions = store.institutions;
    this._setSelectedInstitution(store.getSelectedInstitution());
    this._boundInstitutionChanged = this._handleInstitutionChanged.bind(this);
    this.boundHandleDropDownClick_ = this._handleDropDownClick.bind(this);
    this.boundHandleWindowClick_ = this._handleWindowClick.bind(this);
    this.selectedInstitutionShortId = store.getSelectedInstitution().institution.shortId;
  }

  connectedCallback() {
    super.connectedCallback();
    this.boundUpdateAvatar_ = this._updateAvatarImg.bind(this);
    window.addEventListener('update-avatar', this.boundUpdateAvatar_);
    window.addEventListener('avatarRemoved', this.boundUpdateAvatar_);
    this.avatarUri = `/a/sentry/api/users/${this.loggedInUser.userId}/profile-image`;
    this._updateAvatarImg();
    document.addEventListener('institution-changed', this._boundInstitutionChanged);
    this.isXperience = typeof CefSharp !== 'undefined' && CefSharp.BindObjectAsync;
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('update-avatar', this.boundUpdateAvatar_);
    window.removeEventListener('avatarRemoved', this.boundUpdateAvatar_);
    document.removeEventListener('institution-changed', this._boundInstitutionChanged);
  }

  _setSelectedInstitution(selectedInstitution) {
    if (!selectedInstitution || !selectedInstitution.institution) return;
    const {institution: {shortId}} = selectedInstitution;
    if ((window.location.pathname + '/').includes(`/${shortId}/`)) {
      this.selectedInstitution = selectedInstitution;
    }
  }
  _handleInstitutionChanged({detail: {selectedInstitution}}) {
    if (selectedInstitution !== this.selectedInstitution) {
      this._setSelectedInstitution(selectedInstitution);
    }
  }

  _handleUserMenuClick() {
    this.menuOptionsShown = !this.menuOptionsShown;
    if (this.menuOptionsShown) {
      this.shadowRoot.getElementById('user-menu-dropdown').setAttribute('show', true);
      this.shadowRoot.getElementById('user-menu-mobile').setAttribute('show', true);
      this._listenForClickOutside();
    } else {
      this.shadowRoot.getElementById('user-menu-dropdown').removeAttribute('show');
      this.shadowRoot.getElementById('user-menu-mobile').removeAttribute('show', true);
    }
  }

  _listenForClickOutside() {
    this.shadowRoot.getElementById('user-menu-dropdown')
        .addEventListener('click', this.boundHandleDropDownClick_, false);
    this.shadowRoot.getElementById('user-menu-mobile')
        .addEventListener('click', this.boundHandleDropDownClick_, false);
    window.requestAnimationFrame(() => window.addEventListener('click', this.boundHandleWindowClick_));
  }

  _handleDropDownClick(event) {
    event.stopPropagation();
  }

  _handleWindowClick() {
    this.menuOptionsShown = false;
    this.shadowRoot.getElementById('user-menu-dropdown').removeAttribute('show');
    this.shadowRoot.getElementById('user-menu-mobile').removeAttribute('show', true);
    this.shadowRoot.getElementById('user-menu-dropdown')
        .removeEventListener('click', this.boundHandleDropDownClick_, false);
    this.shadowRoot.getElementById('user-menu-mobile')
        .removeEventListener('click', this.boundHandleDropDownClick_, false);
    window.removeEventListener('click', this.boundHandleWindowClick_);
  }

  _updateAvatarImg() {
    this.avatarUriFull = this._getAvatarFullSrc();
    this.requestUpdate();
  }

  _getAvatarFullSrc() {
    let src;
    if (this.avatarUri) {
      const timestamp = new Date().getTime();
      src = this.avatarUri + '?' + timestamp;
    } else {
      src = '/a/consumer/api/node/profile-photo/0';
    }
    return src;
  }

  openInstitutionSelector() {
    const institutionSelectorElement = document.createElement('jha-enterprise-institution-selector');
    institutionSelectorElement.setAttribute('isInApp', true);
    institutionSelectorElement.addEventListener('close-institution-selector', () => {
      this.dispatchEvent(new CustomEvent('closeModal', {
        bubbles: true,
        composed: true
      }));
    });
    this.dispatchEvent(new CustomEvent('openModal', {
      detail: {
        el: institutionSelectorElement,
        disableBackdropClick: !Boolean(this.selectedInstitution.institution.institutionId)
      },
      bubbles: true,
      composed: true
    }));
  }

  checkSegmentsWorker(evt) {
    if (window.segmentsWorker && window.segmentsWorker.isProcessing()) {
      const el = document.createElement('segmented-permissions-logout-dialog');
      el.segment = window.segmentsWorker.segment;
      this.dispatchEvent(new CustomEvent('openModal', {
        composed: true,
        bubbles: true,
        detail: {el}
      }))
      return;
    }
    const a = document.createElement('a');
    a.href = '/a/login/logout'
    a.click();
  }

  render() {
    const avatarStyle = `background-image: url(${this.avatarUriFull})`;
    const userMenulinks = [
      html`<a href="/a/settings/${this.selectedInstitutionShortId}/users/${this.loggedInUser.userId}"><jha-icon-circle-user></jha-icon-circle-user> <div>View profile</div></a>`,
    ];
    if (this.selectedInstitution && this.userInstitutions && this.userInstitutions.length > 1) {
      userMenulinks.push(
          html`<button @click=${this.openInstitutionSelector}><jha-icon-switch></jha-icon-switch><div><div>Switch institutions</div><div class="sub-title">Current: ${this.selectedInstitution.institution.name}</div></div></button>`
      );
    }
    if (!this.isXperience) {
      userMenulinks.push(
          html`<a @click=${this.checkSegmentsWorker}><jha-icon-logout></jha-icon-logout> <div>Sign out</div></a>`
      );
    }
    return html`
      <div id="user-menu-desktop">
        <button @click=${this._handleUserMenuClick} id="avatar">
          <jha-avatar small="" style="${avatarStyle}"></jha-avatar>
        </button>
        <div class="dropdown" id="user-menu-dropdown">
          ${userMenulinks.map(link => link)}
        </div>
      </div>
      <div id="user-menu-mobile">
        <div id="user-menu-mobile-options">
          ${userMenulinks.map(link => link)}
        </div>
        <div @click=${this._handleUserMenuClick} id="user-menu-mobile-trigger">
          <div>
            <jha-avatar small="" style="${avatarStyle}"></jha-avatar>
            <span>${this.loggedInUser.firstName} ${this.loggedInUser.lastName}</span>
          </div>
          <jha-icon-chevron-up></jha-icon-chevron-up>
        </div>
      </div>
    `;
  }
}

export {styles as JhaEnterpriseNavUserMenuStyles};

customElements.define('jha-enterprise-nav-user-menu', JhaEnterpriseNavUserMenu); // eslint-disable-line @banno/ux/custom-elements-define
export default JhaEnterpriseNavUserMenu;
