export default class AvatarUtils {
  static drawAvatar(newImg, canvas) {
    if (newImg) {
      newImg.addEventListener('load', (e) => {
        const ctx = canvas.getContext('2d');
        canvas.height = newImg.height;
        canvas.width = newImg.width;
        ctx.drawImage(newImg, 0, 0);
      });
      if (newImg.src) {
        const ctx = canvas.getContext('2d');
        canvas.height = newImg.height;
        canvas.width = newImg.width;
        ctx.drawImage(newImg, 0, 0);
      }
    }
  }
}
