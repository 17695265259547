/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconChevronDownElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-chevron-down';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M15.9,9.4c-0.2,0-0.4,0.1-0.5,0.2l-3.5,3.3L8.4,9.7C8.1,9.5,7.8,9.6,7.6,9.8c-0.2,0.2-0.3,0.5-0.1,0.8
      l4,3.8c0.3,0.2,0.7,0.2,1,0l4-3.8c0.2-0.2,0.3-0.5,0.2-0.8S16.2,9.4,15.9,9.4L15.9,9.4z"/>
    </svg>
  `;
  }
};

customElements.define(window.JhaIconChevronDownElement.is,
    window.JhaIconChevronDownElement);
export default window.JhaIconChevronDownElement;
