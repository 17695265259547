import {DomIf} from '@polymer/polymer/lib/elements/dom-if.js';
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import './avatar-capture.js';
import './avatar-cropping.js';
import './delete-avatar-dialog';
import '@banno/platform-ux-shared/components/polymer3/polymer-file-uploader/src/components/polymer-file-uploader.js';
import '@banno/platform-ux-shared/components/polymer3/jha/buttons/jha-button.js';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-camera.js';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-delete.js';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-edit.js';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-upload.js';
import '@banno/platform-ux-shared/components/polymer3/jha/dropdowns/jha-dropdown.js';
import '@banno/platform-ux-shared/components/polymer3/jha/dropdowns/jha-dropdown-toggle.js';
import '@banno/platform-ux-shared/components/polymer3/jha/dropdowns/jha-dropdown-menu.js';
import '@banno/platform-ux-shared/components/polymer3/jha/dropdowns/jha-dropdown-menu-item.js';
import '@banno/platform-ux-shared/components/polymer3/jha/flex-wrapper/jha-flex-wrapper.js';
import '@banno/platform-ux-shared/components/polymer3/avatar/jha-default-avatar.js';
import DialogMixin from '@banno/platform-ux-shared/mixins/dialog.js';
import AvatarUtils from '@banno/platform-ux-shared/utils/avatarUtils.js';
const AvatarUploadBase = DialogMixin(PolymerElement);

/**
 * @polymer
 * @customElement
 */
class AvatarUploadElement extends AvatarUploadBase {
  static get is() {
    return 'avatar-upload';
  }

  static get properties() {
    return {
      avatarImg: {
        type: Object,
        observer: AvatarUploadElement.prototype._drawAvatar
      },
      avatarImgSrc: {
        type: Object,
        observer: AvatarUploadElement.prototype._drawAvatarBySrc
      },
      canCapture: Boolean,
      hasAvatar: {
        type: Boolean,
        value: false
      },
      showUploadDropdown: {
        type: Boolean,
        value: false
      },
      tooTall: {
        type: Boolean,
        value: false
      },
      tooWide: {
        type: Boolean,
        value: false
      },
      canRemove: Boolean,
      avatarDrawn: {
        type: Boolean,
        value: false
      },
      avatarExists: Boolean,
      small: {
        type: Boolean,
        value: false,
        reflectToAttribute: true,
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      this._checkForCamera();
    }
  }

  async _cameraCapture() {
    try {
      this.stream = await navigator.mediaDevices.getUserMedia({video: true});
      this.captureDialog = document.createElement('avatar-capture');
      this.captureDialog.setAttribute('static', true);
      this.captureDialog.stream = this.stream;
      this.captureDialog.addEventListener('capture', (e) => {
        const avatarCapture = e.detail;
        if (avatarCapture) {
          this._startCropping(avatarCapture, 'photo');
        }
      });
      this.dispatchEvent(new CustomEvent('openModal', {
        detail: {el: this.captureDialog}
      }));
      this.captureDialog.play();
    } catch (e) {
      console.error(e);
      this.canCapture = false;
    }
  }

  _cancel() {
    this.cropper.destroy();
    this._drawAvatar();
  }

  async _checkForCamera() {
    const mediaDevices = navigator.mediaDevices;

    if (mediaDevices && mediaDevices.enumerateDevices) {
      const devices = await mediaDevices.enumerateDevices();
      this.canCapture = devices.some((device) => device.kind === 'videoinput');
    } else {
      this.canCapture = false;
    }
  }

  _drawAvatar(newImg) {
    newImg.onload = () => {
      if (newImg.height > newImg.width) {
        this.tooTall = true;
        this.tooWidt = false;
      } else if (newImg.height < newImg.width) {
        this.tooWide = true;
        this.tooTall = false;
      } else {
        this.tooTall = false;
        this.tooWide = false;
      }
      AvatarUtils.drawAvatar(newImg, this.$.avatar);
      this.avatarDrawn = true;
    };
  }

  _drawAvatarBySrc(newImgSrc) {
    if (newImgSrc === 'undefined') return;
    const avatarImg = document.createElement('img');
    avatarImg.src = newImgSrc;
    this._drawAvatar(avatarImg);
  }

  _setAvatarClass(tooTall, tooWide) {
    if (tooTall && !tooWide) {
      return 'avatar-tall';
    } else if (!tooTall && tooWide) {
      return 'avatar-wide';
    }
    return 'avatar-even';
  }

  _handleUploadFile(event) {
    event.preventDefault();
    const uploadFile = event.detail.queue.pop();
    if (uploadFile.file) {
      this._startCropping(uploadFile.file, 'image');
    }
  }

  _showAvatar(hasAvatar) {
    return hasAvatar;
  }

  _startCropping(file, type) {
    this.cropperDialog = document.createElement('avatar-cropping');
    this.cropperDialog.avatarType = type;
    this.cropperDialog.addEventListener('cropped-avatar', (e) => {
      this._uploadImage(e.detail.blob, e.detail.dataURL);
    });
    this.dispatchEvent(new CustomEvent('openModal', {
      detail: {el: this.cropperDialog}
    }));
    this.cropperDialog.startCropping(file);
  }

  _uploadImage(blob, dataURL) {
    this.dispatchEvent(new CustomEvent('update-avatar', {
      bubbles: true,
      composed: true,
      detail: {
        blob: blob,
        dataURL: dataURL
      }
    }));
  }

  _removeAvatar(evt) {
    this.dispatchEvent(new CustomEvent('removeAvatar', {
      bubbles: true,
      composed: true
    }));
  }

  _showRemove(canRemove, hasAvatar, showUploadDropdown) {
    return canRemove && hasAvatar && !showUploadDropdown;
  }

  static get template() {
    return html`
    <style>
      :host {
        display: block;
      }
      canvas[hidden] {
        display: none;
      }
      polymer-file-uploader {
        --polymer-file-uploader-slot-display: flex;
        width: 100%;
      }
      jha-icon-upload {
        width: 20px;
        margin-right: 8px;
      }
      jha-dropdown-menu {
        right: auto;
        left: 104px;
        top: -34px;
      }
      :host([small]) jha-dropdown-menu {
        left: 80px;
        top: -30px;
      }
      jha-dropdown-menu-item {
        color: var(--jha-dropdown-menu-item-text-hover-color, black);
      }
      .upload {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 28px;
        width: 28px;
        position: absolute;
        right: 2px;
        bottom: 2px;
        border-radius: 50%;
        background-color: #ffffff;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
      }
      :host([small]) .upload {
        height: 24px;
        width: 24px;
        right: 0;
        bottom: 0;
      }
      jha-icon-edit {
        fill: var(--jha-color-primary);
        width: 20px;
        height: 20px;
      }
      :host([small]) jha-icon-edit {
        width: 18px;
        height: 18px;
      }
      #avatar-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 96px;
        width: 96px;
        border-radius:50%;
        overflow: hidden;
      }
      #avatar-container[hidden] {
        height: 0;
      }
      :host([fixed]) #avatar-container {
        width: 36px;
        height: 36px;
      }
      :host([small]) #avatar-container {
        height: 72px;
        width: 72px;
      }
      #remove-button {
        position: absolute;
        bottom: -27px;
        left: 0;
        display: block;
        width: 100%;
        color: var(--jha-color-primary, #006ee4);
        font-size: 12px;
        text-align: center;
        background-color: #ffffff;
        padding: 4px 0;
        cursor: pointer;
        transition: bottom 0.2s ease-in-out;
      }
      #avatar-container:hover #remove-button {
        bottom: 0;
      }
      .avatar-tall {
        width: 100%;
      }
      .avatar-wide {
        height: 100%;
      }
      .avatar-even {
        height: 100%;
      }
      :host([slot="avatar-container"]) .upload,
      :host([slot="avatar-container"]) jha-dropdown-menu {
        left: 52%;
      }
    </style>
    <div id="avatar-container" hidden$="[[!_showAvatar(hasAvatar)]]">
      <canvas id="avatar" class$="[[_setAvatarClass(tooTall, tooWide)]]" hidden$="[[!_showAvatar(hasAvatar)]]"></canvas>
      <template is="dom-if" if="[[_showRemove(canRemove, avatarDrawn, showUploadDropdown)]]">
        <div id="remove-button" on-click="_removeAvatar">Remove</div>
      </template>
    </div>
    <template is="dom-if" if="[[!_showAvatar(hasAvatar)]]">
      <jha-default-avatar></jha-default-avatar>
    </template>
    <template is="dom-if" if="[[showUploadDropdown]]">
      <jha-dropdown>
        <jha-dropdown-toggle slot="toggle">
          <div class="upload">
            <jha-icon-edit title="Upload an avatar"></jha-icon-edit>
          </div>
        </jha-dropdown-toggle>
        <jha-dropdown-menu>
          <jha-dropdown-menu-item>
            <polymer-file-uploader name="avatar" type="select" accept="image.*" on-change="_handleUploadFile">
              <jha-flex-wrapper>
                <jha-icon-upload icon title="Upload photo"></jha-icon-upload>
                <div>Upload photo</div>
              </jha-flex-wrapper>
            </polymer-file-uploader>
          </jha-dropdown-menu-item>
          <template is="dom-if" if="[[canCapture]]">
            <jha-dropdown-menu-item on-click="_cameraCapture">
              <jha-icon-camera icon title="Take photo"></jha-icon-camera>
              Take photo
            </jha-dropdown-menu-item>
          </template>
          <template is="dom-if" if="[[canRemove]]">
            <jha-dropdown-menu-item on-click="_removeAvatar">
              <jha-icon-delete icon title="Remove photo"></jha-icon-delete>
              Remove
            </jha-dropdown-menu-item>
          </template>
        </jha-dropdown-menu>
      </jha-dropdown>
    </template>
  `;
  }
}
customElements.define(AvatarUploadElement.is, AvatarUploadElement);
export default AvatarUploadElement;
