import apiPaths from "../api-paths.js";
/* eslint-disable strict */
const { MOBILE_PATH } = apiPaths;
const mobileApiUser = {
    url: (institutionShortId, userId) => `${MOBILE_PATH}/v0/institutions/${institutionShortId}/users/${userId}/details`,
    arguments: ['institutionShortId', 'userId'],
    requestMethods: ['GET'],
};
export const getUser = () => mobileApiUser;
export default {
    getUser
};
