import {LitElement, html, css} from 'lit-element';
import {jhaStyles} from '@banno/platform-ux-shared/styles/jha-styles';

const styles = css`
  :host {
    display: block;
    width: 100%;
    padding: 0 16px;
    margin: 0 auto;
    max-width: 992px;
    box-sizing: border-box;
  }
  @media (min-width: 992px) {
    :host {
      max-width: 1170px;
    }
  }
  @media (min-width: 1440px) {
    :host {
      max-width: 1286px;
    }
  }
`;

class MobileHeader extends LitElement {
  static get styles() {
    return [jhaStyles, styles];
  }

  static get properties() {
    return {
    };
  }

  render() {
    return html`<slot name="header"></slot>`;
  }
}

export {styles as MobileHeaderStyles};

customElements.define('mobile-header', MobileHeader); // eslint-disable-line @banno/ux/custom-elements-define
export default MobileHeader;
