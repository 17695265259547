/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconSwitchElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-switch';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M21.53,14.47a.75.75,0,0,0-1.06,0l-2.72,2.72V9C17.75,4.3,14,4.25,14,4.25a.75.75,0,0,0,0,1.5c.09,0,2.25,0,2.25,3.25v8.19l-2.72-2.72a.75.75,0,0,0-1.06,1.06L17,20.06l4.53-4.53A.75.75,0,0,0,21.53,14.47Z"/>
    <path d="M7,3.94,2.47,8.47A.75.75,0,0,0,3.53,9.53L6.25,6.81V15c0,4.7,3.71,4.75,3.75,4.75a.75.75,0,0,0,0-1.5c-.09,0-2.25,0-2.25-3.25V6.81l2.72,2.72a.75.75,0,0,0,1.06-1.06Z"/>
    </svg>
  `;
  }
};

customElements.define(window.JhaIconSwitchElement.is,
    window.JhaIconSwitchElement);
export default window.JhaIconSwitchElement;
