import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaDropdownToggleElement = class extends PolymerElement {
  static get is() {
    return 'jha-dropdown-toggle';
  }

  static get template() {
    return html`
    <style>
      :host {
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: var(--jha-dropdown-toggle-justify-content, center);
      }
      :host(:hover) + ::slotted(jha-dropdown-menu) {
        display: block;
      }
      :host ::slotted(jha-icon-chevron-down) {
        width: 18px;
        height: 18px;
        position: relative;
        transition: transform .3s;
        fill: var(--jha-dropdown-toggle-icon-color, var(--jha-text-base));
      }
      :host([is-active]) ::slotted(jha-icon-chevron-down) {
        transform: rotate(180deg);
      }
      :host([is-active]) {
        outline: 0;
        border-color: var(--jha-color-primary);
        box-shadow: 0px 0px 3px  var(--jha-color-primary);
      }
    </style>

    <slot></slot>

  `;
  }
};

customElements.define(window.JhaDropdownToggleElement.is, window.JhaDropdownToggleElement);
export default window.JhaDropdownToggleElement;
