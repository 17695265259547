import {LitElement, html, css} from 'lit-element';
import {jhaStyles} from '@banno/platform-ux-shared/styles/jha-styles';
import {getCopyOfCoreNav} from './helpers/core-navigation';
import '@banno/platform-ux-shared/components/polymer3/jha/dialogs/jha-dialog.js';

const styles = css`
  :host {
    display: block;
  }
  article {
    height: 80vh;
    overflow-y: auto;
  }
  h2 {
    margin-top: 40px;
    border-bottom: 1px solid #ddd;
  }
  h4 {
    margin-bottom: 4px;
  }
  p {
    margin-left: 8px;
  }
  p, code, em {
    font-size: 14px;
  }
  code {
    color: #000;
    background-color: #eee;
    padding: 2px 6px;
  }
  blockquote.privilege {
    background-color: #eee;
    font-size: 14px;
    margin-top: 2px;
  }
  blockquote {
    margin-left: 0px;
    padding-left: 20px;
    background: linear-gradient(90deg, rgb(236, 236, 236) 0%, rgba(255, 255, 255, 0) 6%, rgba(255, 255, 255, 0) 100%);
    padding: 6px 0 6px 24px;
  }
  @media (max-width: 480px) {
    article {
      padding: 32px 16px;
    }
  }
`;

class NavigationDocumentationDialog extends LitElement {
  static get styles() {
    return [jhaStyles, styles];
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('close', this._closeDialog);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('close', this._closeDialog);
  }

  static get properties() {
    return {
      navigationObj: {type: Object, value: null},
    };
  }

  constructor() {
    super();
    this.navigationObj = getCopyOfCoreNav();
  }

  _closeDialog() {
    this.dispatchEvent(new CustomEvent('closeModal', {
      bubbles: true,
      composed: true
    }));
  }

  render() {
    return html`
      <jha-dialog no-footer="" title="Enterprise Admin Navigation">
        <article dialog>
          <em>Lists each navigation item and the configuration, feature flags and user privileges required to access them.</em>
          ${this.navigationObj.projects.map(project => {
            return html`
              <h2>${project.displayName}</h2>
              ${this.renderPriviligesForNode(project)}
              ${(project.subNav || []).map(subNavItem => {
                if (!subNavItem.header) {
                  return html`
                    <h4>${subNavItem.displayName}</h4>
                    ${this.renderPriviligesForNode(subNavItem)}
                    ${subNavItem.subNav && html`
                      <blockquote>
                      ${subNavItem.subNav.map(subNavItem2 => {
                        if (!subNavItem2.header) {
                          return html`
                            <h4>${subNavItem2.displayName}</h4>
                            ${this.renderPriviligesForNode(subNavItem2)}
                          `;
                        }
                      })}
                      </blockquote>
                    `}
                  `;
                }
              })}
            `;
          })}
        </article>
      </jha-dialog>
    `;
  }

  renderPriviligesForNode(navItem) {
    const restrictionObjKeys = [
      'name',
      'privilegeRequired',
      'enterpriseConfig',
      'siteFeatureConfig',
      'institutionAbility',
      'institutionOverride',
      'featureFlag',
      'siteFeature',
      'siteRequired',
      'adminsCanView',
      'internalOnly',
      'hasBannoOnline',
    ];
    let nodeHasRestrictions = false;
    restrictionObjKeys.forEach(restrictionKey => {
      if (Object.keys(navItem).includes(restrictionKey)) {
        nodeHasRestrictions = true;
      }
    });
    if (!nodeHasRestrictions) {
      return html`<p><em>*Access determined by parent node</em></p>`;
    }
    const privsToCheck = Array.isArray(navItem.privilegeRequired) ? navItem.privilegeRequired : [navItem.privilegeRequired];
    return html`
      ${navItem.name && html`<p>App name: <code>${navItem.name}</code></p>`}
      ${privsToCheck.map(priv => {
        return priv && html`
          <p>User Privilege:</p>
          <blockquote class="privilege">
            {<br />
            ${Object.keys(priv).map(key => html`${key}: '${priv[key] || '*'}',<br />`)}
            }<br />
          </blockquote>
        `;
      })}
      ${navItem.enterpriseConfig && html`<p>Enterprise Config: <code>${navItem.enterpriseConfig}</code></p>`}
      ${navItem.siteFeatureConfig && html`<p>Site Feature Config: <code>${navItem.siteFeatureConfig}</code></p>`}
      ${navItem.institutionAbility &&
        html`<p>
          Institution Ability:
          ${(Array.isArray(navItem.institutionAbility) ? navItem.institutionAbility : [navItem.institutionAbility])
            .map(f => html`<code>${f}</code>&nbsp;`)
      }</p>`}
      ${navItem.institutionOverride &&
        html`<p>
          Institution Override:
          ${(Array.isArray(navItem.institutionOverride) ? navItem.institutionOverride : [navItem.institutionOverride])
            .map(f => html`<code>${f}</code>&nbsp;`)
      }</p>`}
      ${navItem.featureFlag &&
        html`<p>
          Feature Flag:
          ${(Array.isArray(navItem.featureFlag) ? navItem.featureFlag : [navItem.featureFlag])
            .map(f => html`<code>${f}</code>&nbsp;`)
      }</p>`}
      ${navItem.siteFeature &&
        html`<p>
          Site Feature:
          ${(Array.isArray(navItem.siteFeature) ? navItem.siteFeature : [navItem.siteFeature])
            .map(f => html`<code>${f}</code>&nbsp;`)
      }</p>`}
      ${navItem.adminsCanView && html`<p><em>adminsCanView</em></p>`}
      ${navItem.internalOnly && html`<p><em>Internal users only</em></p>`}
      ${navItem.hasBannoOnline && html`<p><em>Banno Online customers</em></p>`}
      ${navItem.siteRequired && html`<p><em>Site is required</em></p>`}
    `;
  }
}

customElements.define('navigation-documentation-dialog', NavigationDocumentationDialog); // eslint-disable-line @banno/ux/custom-elements-define
export default NavigationDocumentationDialog;
