import ApiPaths from "../api-paths.js";
'use strict';
class UsersApi {
    static institutionApplicationUsers() {
        return {
            url: (institutionId, applicationId) => `${ApiPaths.SENTRY_PATH}/institutions/${institutionId}/applications/${applicationId}/users`,
            arguments: ['institutionId', 'applicationId'],
            requestMethods: ['GET']
        };
    }
    static user() {
        return {
            url: userId => `${ApiPaths.SENTRY_PATH}/users/${userId}`,
            arguments: ['userId'],
            requestMethods: ['GET', 'PUT', 'DELETE']
        };
    }
    static userConsumer() {
        return {
            url: userId => `${ApiPaths.CONSUMER_PATH}/users/${userId}`,
            arguments: ['userId'],
            requestMethods: ['GET']
        };
    }
    static users() {
        return {
            url: institutionId => `${ApiPaths.SENTRY_PATH}/institutions/${institutionId}/users`,
            arguments: ['institutionId'],
            requestMethods: ['GET']
        };
    }
    static authzUsers() {
        return {
            url: (institutionId, userId) => `${ApiPaths.AUTH_PATH}/institutions/${institutionId}/users`,
            arguments: ['institutionId'],
            requestMethods: ['GET']
        };
    }
    static authzUser() {
        return {
            url: (institutionId, userId) => `${ApiPaths.AUTH_PATH}/institutions/${institutionId}/users/${userId}`,
            arguments: ['institutionId', 'userId'],
            requestMethods: ['GET']
        };
    }
    static unlock() {
        return {
            url: userId => `${ApiPaths.SENTRY_PATH}/users/${userId}/lockouts`,
            arguments: ['userId'],
            requestMethods: ['DELETE']
        };
    }
    static deleteAvatar() {
        return {
            url: userId => `${ApiPaths.CONSUMER_PATH}/node/profile-photo/${userId}`,
            arguments: ['userId'],
            requestMethods: ['DELETE']
        };
    }
    static avatarExists() {
        return {
            url: userId => `${ApiPaths.CONSUMER_PATH}/node/profile-photo/${userId}/exists`,
            arguments: ['userId'],
            requestMethods: ['GET']
        };
    }

    static jhidDomains() {
        return {
            url: (domain) => `${ApiPaths.JHID_PATH}/v0/domains/${domain}`,
            arguments: ['domain'],
            requestMethods: ['GET'],
        };
    }

    static jhidUsers() {
        return {
            url: `${ApiPaths.JHID_PATH}/v0/users`,
            requestMethods: ['POST'],
        };
    }

    static jhidUserInstitutions() {
        return {
            url: (userId) => `${ApiPaths.JHID_PATH}/v0/users/${userId}/institutions`,
            arguments: ['userId'],
            requestMethods: ['GET'],
        };
    }

    static jhidUserGroups() {
        return {
            url: (userId) => `${ApiPaths.JHID_PATH}/v0/users/${userId}/groups`,
            arguments: ['userId'],
            requestMethods: ['GET'],
        };
    }

    static jhidPasswordResetAsAdmin() {
        return {
            url: `${ApiPaths.JHID_PATH}/v0/password-reset/send-reset-as-admin`,
            requestMethods: ['POST'],
        };
    }
}
export default UsersApi;
