import {LitElement, html, css} from 'lit-element';
import {jhaStyles} from '@banno/platform-ux-shared/styles/jha-styles';
import {NAVIGATION_ICONS} from './helpers/core-navigation';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-view-more.js';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-chevron-down.js';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-document.js';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-people';

const styles = css`
  *[small] {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  a {
    color: var(--jha-text-dark);
    margin-bottom: 8px;
  }
  a:hover {
    color: var(--jha-text-dark);
    background-color: var(--jha-color-gray-light);
    font-weight: 400;
    border-radius: 6px;
    text-decoration: none;
  }
  a:hover *[small] {
    fill: var(--jha-text-dark);
  }
  span {
    flex: 1;
  }
  button {
    width: 80px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 12px;
    cursor: pointer;
  }
  jha-icon-view-more {
    fill: #000000;
  }
  .dropdown {
    display: none;
    position: absolute;
    min-width: 215px;
    max-width: 300px;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 2px 6px 0px rgba(51, 51, 51, 0.16);
    padding: 8px;
    z-index: 1010;
    text-align: left;
    left: 92px;
    bottom: 0;
  }
  .dropdown[show] {
    display: block;
  }
  .dropdown.top {
    bottom: auto;
    top: 0;
  }
  .dropdown a {
    display: block;
    font-size: 12px;
    padding: 8px;
    cursor: pointer;
    margin-bottom: 8px;
  }
  .dropdown a:last-of-type {
    margin-bottom: 0;
  }
  .dropdown jha-icon-chevron-right {
    width: 24px;
    height: 24px;
  }
  #more-menu-dropdown a span[mobile]{
    display: none;
  }
  jha-icon-chevron-right {
    display: none;
  }
  @media (max-width: 1024px) {
    :host {
      flex: 1;
      padding: 0;
      width: 20px;
      height: 20px;
    }
    :host,
    #more-menu {
      width: 100%;
    }
    *[small] {
      margin-right: 18px;
    }
    button {
      display: none;
    }
    .dropdown {
      display: block;
      position: relative;
      width: 100%;
      max-width: 100%;
      border-radius: none;
      box-shadow: none;
      z-index: 1;
      left: 0;
      bottom: 0;
      background-color: inherit;
      padding: 0px;
    }
    .dropdown a {
      font-size: 14px;
      padding: 6px 8px;
      padding: 9.5px 0px 9.5px 10px;
      cursor: pointer;
    }
    #more-menu-dropdown a[hasmobiledisplay] span[desktop]{
      display: none;
    }
    #more-menu-dropdown a[hasmobiledisplay] span[mobile]{
      display: block;
    }
    jha-icon-chevron-right {
      display: block;
      margin-left: auto;
    }
  }
`;

class JhaEnterpriseNavMoreMenu extends LitElement {
  static get styles() {
    return [jhaStyles, styles];
  }

  constructor() {
    super();
    this.boundHandleDropDownClick_ = this._handleDropDownClick.bind(this);
    this.boundHandleWindowClick_ = this._handleWindowClick.bind(this);
  }

  static get properties() {
    return {
      loggedInUser: {type: Object},
      menuDropdownShown: {
        type: Boolean,
        value: false,
      },
      mobileNavOpen: {type: Boolean},
      navigationProjects: {type: Array},
    };
  }

  _handleMoreMenuClick() {
    this.menuDropdownShown = !this.menuDropdownShown;
    if (this.menuDropdownShown) {
      this.shadowRoot.getElementById('more-menu-dropdown').setAttribute('show', true);
      this._listenForClickOutside();
    } else {
      this.shadowRoot.getElementById('more-menu-dropdown').removeAttribute('show');
    }
  }

  _listenForClickOutside() {
    this.shadowRoot.getElementById('more-menu-dropdown')
        .addEventListener('click', this.boundHandleDropDownClick_, false);
    window.requestAnimationFrame(() => window.addEventListener('click', this.boundHandleWindowClick_));
  }

  _handleDropDownClick(event) {
    event.stopPropagation();
  }

  _handleWindowClick() {
    this.menuDropdownShown = false;
    this.shadowRoot.getElementById('more-menu-dropdown').removeAttribute('show');
    this.shadowRoot.getElementById('more-menu-dropdown')
        .removeEventListener('click', this.boundHandleDropDownClick_, false);
    window.removeEventListener('click', this.boundHandleWindowClick_);
  }

  _setSelectedProject(project) {
    if (project.target === '_blank') {
      window.open(this._getInitialProjectPath(project));
      return;
    }
    if (window.innerWidth > 739) {
      location.href = this._getInitialProjectPath(project);
      return;
    }
    if (project.path && (!this.mobileNavOpen || (this.mobileNavOpen && !project.subNav))) {
      location.href = project.path;
      return;
    }
    this.dispatchEvent(new CustomEvent('selected-project-change', {
      detail: {project},
      bubbles: true,
      composed: true
    }));
  }

  _getInitialProjectPath(project) {
    if (project.subNav && project.subNav.length > 0) {
      return project.subNav.find(s => !s.header).path;
    }
    return project.path;
  }

  _handleNavDocumentationClick() {
    this.dispatchEvent(new CustomEvent('open-nav-documentation', {bubbles: true, composed: true}));
    this._handleMoreMenuClick();
  }

  _handleInstitutionAndUserDetailsClick() {
    this.dispatchEvent(new CustomEvent('open-institution-and-user-details', {bubbles: true, composed: true}));
    this._handleMoreMenuClick();
  }

  _showMenuDocsLink() {
    return window.location.hostname.toLowerCase() === 'localhost' && this.loggedInUser.internal;
  }

  _isTopDown(navigationProjects) {
    return navigationProjects.filter(p => !p.secondary) < 3 ? 'top' : null;
  }

  render() {
    return html`
      <div id="more-menu">
        <button @click=${this._handleMoreMenuClick}>
          <jha-icon-view-more></jha-icon-view-more>
        </button>
        <div id="more-menu-dropdown" class="dropdown ${this._isTopDown(this.navigationProjects)}">
          ${this.navigationProjects.filter(p => p.secondary).map(project => html`
            <a
              @click=${() => this._setSelectedProject(project)}
              flex
              ?hasMobileDisplay="${Boolean(project.displayNameMobile)}">
              ${NAVIGATION_ICONS[project.icon]}
              <span desktop>${project.displayName}</span>
              <span mobile>${project.displayNameMobile}</span>
              ${project.subNav && html`
                <jha-icon-chevron-right></jha-icon-chevron-right>`}
            </a>`)}
          ${this._showMenuDocsLink() ? html`
            <a
              flex
              @click=${this._handleNavDocumentationClick}>
              <jha-icon-document small></jha-icon-document>
              <span>Navigation Documentation</span>
            </a>
            <a
              flex
              @click=${this._handleInstitutionAndUserDetailsClick}>
              <jha-icon-people small></jha-icon-people>
              <span>Current institution & logged in user details</span>
            </a>
          ` : null}
        </div>
      </div>
    `;
  }
}

export {styles as JhaEnterpriseNavMoreMenuStyles};

customElements.define('jha-enterprise-nav-more-menu', JhaEnterpriseNavMoreMenu); // eslint-disable-line @banno/ux/custom-elements-define
export default JhaEnterpriseNavMoreMenu;
