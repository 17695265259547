import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaDropdownMenuElement = class extends PolymerElement {
  static get is() {
    return 'jha-dropdown-menu';
  }

  static get template() {
    return html`
    <style>
      :host {
        display: block;
        margin: 4px 0 0;
        list-style: none;
        position: absolute;
        top: 100%;
        right: 0;
        background-color: var(--jha-component-background-color, #ffffff);
        border-radius: 6px;
        min-width: 200px;
        max-width: 300px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        max-height: calc(100vh - 200px);
        min-height: 40px;
        background-clip: padding-box;
        box-shadow: var(--jha-dropdown-shadow, 0 1px 4px 0 rgba(37,49,62,.24),0 1px 3px rgba(37,49,62,.12));
        z-index: 1010;
        text-align: left;
      }
      :host ::slotted(hr) {
        border: none;
        height: 1px;
        background-color: var(--jha-border-color, #e4e7ea);
        margin: 0;
      }
      @media(max-height: 400px) {
        :host {
          max-height: calc(100vh - 20px);
        }
      }
    </style>

    <slot></slot>

  `;
  }
};

customElements.define(window.JhaDropdownMenuElement.is, window.JhaDropdownMenuElement);
export default window.JhaDropdownMenuElement;
