import EnterpriseConfigService from '@banno/platform-ux-shared/services/config/enterprise-config-service';
import EnvironmentConfigController from '@banno/platform-ux-shared/controllers/environment-config';
import InstitutionsController from '@banno/platform-ux-shared/controllers/institutions-controller';
import ReportsAppController from '../../reports/src/js/controllers/reports-app-controller';
import SettingsService from '../../people/src/js/services/settings.service';
import store from './store';
const reportsAppController = new ReportsAppController();

async function getInstitutionConfig() {
  const institutionConfig = {
    enterpriseConfig: {},
    environmentConfig: {},
    featureFlags: {},
    institutionOverrides: {},
    settingsDetails: {},
    webServerConfig: {},
  };
  const {institution: {institutionId}} = store.getSelectedInstitution();
  try {
    institutionConfig.enterpriseConfig = await EnterpriseConfigService.getUser(institutionId, store.user.userId);
  } catch (e) {
    console.error('Error retrieving enterprise config details.', e);
  }
  try {
    institutionConfig.environmentConfig = await EnvironmentConfigController.getConfig();
  } catch (e) {
    console.error('Error retrieving environment config details.', e);
  }
  institutionConfig.featureFlags = institutionConfig.environmentConfig.enterprise.featureFlags;
  if (institutionConfig.environmentConfig.enterprise &&
    institutionConfig.environmentConfig.enterprise.institutions[institutionId]) {
    institutionConfig.featureFlags =
        Object.assign(
            institutionConfig.featureFlags,
            institutionConfig.environmentConfig.enterprise.institutions[institutionId].featureFlags
        );
    institutionConfig.institutionOverrides =
        institutionConfig.environmentConfig.enterprise.institutions[institutionId];
  }
  if (institutionConfig.enterpriseConfig && institutionConfig.enterpriseConfig.featureFlags) {
    institutionConfig.featureFlags =
          Object.assign(institutionConfig.featureFlags, institutionConfig.enterpriseConfig.featureFlags);
  }
  try {
    institutionConfig.institutionAbilities =
      await reportsAppController.getInstitutionAbilities(institutionId, store.user);
  } catch (e) {
    console.error('Error retrieving institution abilities config details.', e);
  }
  try {
    institutionConfig.institutionAbilitiesV1 = await InstitutionsController.getInstitutionAbilitiesV1(institutionId);
  } catch (e) {
    console.error('Error retrieving institution abilities config details.', e);
  }
  try {
    institutionConfig.settingsDetails = await SettingsService.getInstitutionDetails(institutionId);
  } catch (e) {
    console.error('Error retrieving institution settings details.', e);
  }
  try {
    institutionConfig.webServerConfig = await InstitutionsController.getWebServerConfig(institutionId);
  } catch (e) {
    console.error('Error retrieving web server config details.', e);
  }
  return institutionConfig;
}

async function setInstitutionConfig() {
  const {institution: {institutionId}} = store.getSelectedInstitution();
  const newInstitutionConfig = {};
  newInstitutionConfig[institutionId] = await getInstitutionConfig();
  if (store.institutionConfig) {
    Object.assign(store.institutionConfig, newInstitutionConfig);
  } else {
    store.institutionConfig = newInstitutionConfig;
  }
}
export {getInstitutionConfig, setInstitutionConfig};
