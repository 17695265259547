/* istanbul ignore file */
import { css } from 'lit-element';

export const dialogStyles = css`
  :host header {
    padding: 32px;
    border-bottom: 1px solid var(--jha-border-color);
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: var(--jha-text-dark);
  }
  :host article {
    padding: 14px;
    color: var(--jha-text-base);
    overflow: auto;
  }
  footer {
    border-top: 1px solid var(--jha-border-color);
    padding: 20px 0;
  }
  footer > jha-button {
    display: block;
    width: 100%;
  }
  footer > jha-button:not(:last-child) {
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 20px;
  }
  .confirm {
    margin-right: 8px !important;
  }
  jha-button[danger] {
    margin-right: 16px;
  }
  jha-button[link]:hover {
    --jha-button-link-text-decoration: none;
  }
  .btn-group {
    display: contents;
  }
  .btn-group > jha-button {
    margin-top: 10px;
  }
  .btn-group > jha-button:not(:last-child) {
    margin-right: 12px;
  }
  @media (min-width: 300px) {
    footer {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    footer > jha-button {
      width: inherit;
    }
    footer > jha-button:not(:last-child) {
      margin: 20px;
    }
  }
  @media (min-width: 480px) {
    :host header {
      padding: 32px;
    }
    :host article {
      padding: 32px;
    }
    footer {
      padding: 10px;
    }
  }

  @media (min-width: 464px) {
    footer jha-button {
      display: inline-block;
    }
  }
`;
