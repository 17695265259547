/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconChevronUpElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-chevron-up';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M15.9,14.6c-0.2,0-0.4-0.1-0.5-0.2l-3.5-3.3l-3.5,3.3c-0.3,0.1-0.6,0.1-0.9-0.1s-0.3-0.5-0.1-0.8l4-3.8
      c0.3-0.2,0.7-0.2,1,0l4,3.8c0.2,0.2,0.3,0.5,0.2,0.8C16.5,14.4,16.2,14.6,15.9,14.6L15.9,14.6z"/>
    </svg>
  `;
  }
};

customElements.define(window.JhaIconChevronUpElement.is,
    window.JhaIconChevronUpElement);
export default window.JhaIconChevronUpElement;
