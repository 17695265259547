import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-camera.js';
import '@banno/platform-ux-shared/components/polymer3/jha/buttons/jha-button.js';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-close.js';

/**
 * @polymer
 * @customElement
 */
class AvatarCaptureElement extends PolymerElement {
  static get is() {
    return 'avatar-capture';
  }

  static get properties() {
    return {
      capturing: Boolean
    };
  }
  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('closeModal', this._stopStream);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('closeModal', this._stopStream);
  }

  _cancel() {
    this.dispatchEvent(new CustomEvent('closeModal', {
      bubbles: true,
      composed: true
    }));
  }

  _captureImage(e) {
    const canvas = document.createElement('canvas');
    canvas.height = this.video.videoHeight;
    canvas.width = this.video.videoWidth;
    const context = canvas.getContext('2d');
    context.drawImage(this.video, 0, 0, canvas.width, canvas.height);
    canvas.toBlob((blob) => {
      this.dispatchEvent(new CustomEvent('capture', {
        detail: blob
      }));
      this.$.video.removeChild(this.video);
      this.capturing = false;
      this._stopStream();
    });
  }

  play() {
    if (this.stream) {
      this.video = document.createElement('video');
      this.video.srcObject = this.stream;
      // this.video.height = 720;
      // this.video.width = 1280;
      this.capturing = true;
      this.$.video.appendChild(this.video);
      this.video.play();
    }
  }

  _stopStream() {
    if (this.stream) {
      this.stream.getVideoTracks()[0].stop();
    }
    this.addEventListener('closeModal', this._stopStream);
  }

  static get template() {
    return html`
    <style>
      :host {
        display: flex;
        flex-direction: column;
      }
      header {
        padding: 32px;
        border-bottom: 1px solid var(--jha-border-color);
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        color: var(--jha-text-dark);
      }
      header jha-button {
        position: absolute;
        top: 35px;
        right: 20px;
        opacity: 0.6;
        transition: opacity linear 0.3s;
      }
      header jha-button:hover {
        opacity: 1;
      }
      jha-icon-close {
        width: 18px;
        height: 18px;
        fill: var(--jha-color-neutral);
      }
      article {
        padding: 15px;
        color: var(--jha-text-base);
      }
      video {
        width: 100%;
        height: auto;
      }
      footer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 16px 20px;
      }
      jha-button[primary] {
        --jha-button-border: 2px solid transparent;
        margin-left: 16px;
      }
      jha-icon-camera {
        width: 32px;
        height: 32px;
        fill: #ffffff;
      }
      jha-button[link] {
        --jha-button-link-text: var(--jha-text-base);
        margin-left: 24px;
      }
    </style>
    <header>
      <jha-button sync="" icon="" type="button" on-click="_cancel">
        <jha-icon-close></jha-icon-close>
      </jha-button>
      Take photo
    </header>
    <article>
      <span id="video"></span>
    </article>
    <footer>
      <jha-button sync="" wide="" flex="" primary="" type="button" on-click="_captureImage">
        <jha-icon-camera></jha-icon-camera>
      </jha-button>
      <jha-button link sync type="button" on-click="_cancel">Cancel</jha-button>
    </footer>
  `;
  }
}
customElements.define(AvatarCaptureElement.is, AvatarCaptureElement);
export default AvatarCaptureElement;
