import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import {DomIf} from '@polymer/polymer/lib/elements/dom-if.js';
import('../icons/jha-icon-business.js');
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaAvatarElement = class extends PolymerElement {
  static get is() {
    return 'jha-avatar';
  }
  static get properties() {
    return {
      badgeurl: {
        type: String,
        notify: true,
      },
      orgBadge: {
        type: Boolean,
      },
    };
  }

  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: var(--jha-avatar-width, 48px);
        height: var(--jha-avatar-height, 48px);
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-color: transparent;
        vertical-align: middle;
        margin-right: 5px;
        position: relative;
      }
      :host([x-small]) {
        --jha-avatar-width: 24px;
        --jha-avatar-height: 24px;
      }
      :host([mobile-header]) {
        --jha-avatar-width: 30px;
        --jha-avatar-height: 30px;
      }
      :host([small]) {
        --jha-avatar-width: 36px;
        --jha-avatar-height: 36px;
      }
      :host([large]) {
        --jha-avatar-width: 72px;
        --jha-avatar-height: 72px;
      }
      :host([x-large]) {
        --jha-avatar-width: 96px;
        --jha-avatar-height: 96px;
      }
      :host([profile]) {
        --jha-avatar-border: 2px solid #fff;
      }
      div {
        display: none;
        position: absolute;
        bottom: -6px;
        right: -6px;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        background-color: #fff;
        padding: 2px;
        z-index: 2;
      }
      :host([badge]) div, :host([org-badge]) div {
        display: block;
      }
      div > img, div > jha-icon-business {
        max-width: 100%;
        height: auto;
        border-radius: 4px;
      }
      jha-icon-business {
        background: var(--jha-color-primary);
        fill: #fff;
        width: 28px;
        height: 28px;
        border-radius: 4px;
      }
      :host([x-small]) div {
        width: 12px;
        height: 12px;
        padding: 1px;
        bottom: -2px;
      }
      :host([x-small]) div > img {
        position: relative;
        top: -3px;
      }
      :host([small]) div {
        width: 16px;
        height: 16px;
        padding: 1px;
        bottom: -3px;
      }
      :host([large]) div {
        width: 24px;
        height: 24px;
        bottom: -6px;
        right: --7px;
      }
      :host([x-large]) div {
        width: 28px;
        height: 28px;
        bottom: 0px;
        right: -2px;
      }
    </style>

    <slot></slot>
    <div>
      <!-- eslint-disable @banno/ux/no-auto-binding -->
      <template is="dom-if" if="[[badgeurl]]">
        <img src="{{badgeurl::img}}" alt="">
      </template>
      <template is="dom-if" if="[[orgBadge]]">
        <jha-icon-business></jha-icon-business>
      </template>
      <!-- eslint-enable @banno/ux/no-auto-binding -->
    </div>

  `;
  }
};

customElements.define(window.JhaAvatarElement.is, window.JhaAvatarElement);
export default window.JhaAvatarElement;
