import {html} from 'lit-element';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-devices';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-people';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-marketing';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-support';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-monitor';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-browser';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-reports';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-settings';

const KNOWLEDGE_BASE_LINKS = {
  MOBILE_AND_ONLINE: {
    icon: html`<jha-icon-devices></jha-icon-devices>`,
    name: 'Mobile & Online',
    links: [
      {
        name: 'Accounts',
        href: 'https://knowledge.banno.com/apps/accounts/'
      },
      {
        name: 'Authentication & security',
        href: 'https://knowledge.banno.com/apps/authentication/'
      },
      {
        name: 'View all',
        href: 'https://knowledge.banno.com/apps/',
        isSemiBold: true
      },
    ]
  },
  PEOPLE: {
    icon: html`<jha-icon-people></jha-icon-people>`,
    name: 'People',
    links: [
      {
        name: 'Authentication & security',
        href: 'https://knowledge.banno.com/people/authentication/',
      },
      {
        name: 'Settings',
        href: 'https://knowledge.banno.com/people/settings/',
      },
      {
        name: 'View all',
        href: 'https://knowledge.banno.com/people/',
        isSemiBold: true,
      }
    ],
  },
  MAILBOXES: {
    icon: html`<jha-icon-support></jha-icon-support>`,
    name: 'Support',
    links: [
      {
        name: 'Inbox',
        href: 'https://knowledge.banno.com/support/inbox/',
      },
      {
        name: 'Settings',
        href: 'https://knowledge.banno.com/support/settings/',
      },
      {
        name: 'View all',
        href: 'https://knowledge.banno.com/support/',
        isSemiBold: true,
      }
    ],
  },
  MARKETING: {
    icon: html`<jha-icon-marketing></jha-icon-marketing>`,
    name: 'Marketing',
    links: [
      {
        name: 'Audience',
        href: 'https://knowledge.banno.com/marketing/audience/',
      },
      {
        name: 'Campaigns',
        href: 'https://knowledge.banno.com/marketing/campaigns/',
      },
      {
        name: 'Templates',
        href: 'https://knowledge.banno.com/marketing/templates/',
      },
      {
        name: 'View all',
        href: 'https://knowledge.banno.com/marketing/',
        isSemiBold: true,
      }
    ],
  },
  MONITOR: {
    icon: html`<jha-icon-monitor></jha-icon-monitor>`,
    name: 'Monitor',
    links: [
      {
        name: 'View all',
        href: 'https://knowledge.banno.com/monitor/',
        isSemiBold: true,
      }
    ],
  },
  CMS: {
    icon: html`<jha-icon-browser></jha-icon-browser>`,
    name: 'Content',
    links: [
      {
        name: 'Form builder',
        href: 'https://knowledge.banno.com/cms/form-builder/'
      },
      {
        name: 'Training videos',
        href: 'https://knowledge.banno.com/cms/training-videos/'
      },
      {
        name: 'View all',
        href: 'https://knowledge.banno.com/cms/',
        isSemiBold: true,
      }
    ],
  },
  REPORTS: {
    icon: html`<jha-icon-reports></jha-icon-reports>`,
    name: 'Reports',
    links: [
      {
        name: 'Engagement',
        href: 'https://knowledge.banno.com/service-support/banno-admin/reports/engagement/'
      },
      {
        name: 'View all',
        href: 'https://knowledge.banno.com/service-support/banno-admin/reports/',
        isSemiBold: true,
      }
    ],
  },
  SETTINGS: {
    icon: html`<jha-icon-settings></jha-icon-settings>`,
    name: 'Users & groups',
    links: [
      {
        name: 'Users',
        href: 'https://knowledge.banno.com/service-support/banno-admin/admin-settings/users-groups/',
      },
      {
        name: 'Groups',
        href: 'https://knowledge.banno.com/service-support/banno-admin/admin-settings/users-groups/',
      },
      {
        name: 'View all',
        href: 'https://knowledge.banno.com/service-support/banno-admin/admin-settings/users-groups/',
        isSemiBold: true,
      }
    ],
  },
};

export {KNOWLEDGE_BASE_LINKS};
