import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';

const SELECTORS = 'a, input[type="checkbox"], jha-button, button';

/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaDropdownMenuItemElement = class extends PolymerElement {
  static get is() {
    return 'jha-dropdown-menu-item';
  }

  constructor() {
    super();
    this.boundFocus_ = this.onFocus_.bind(this);
    this.boundClick_ = this.onClick_.bind(this);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('focus', this.boundFocus_);
    this.removeEventListener('click', this.boundClick_, true);
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('focus', this.boundFocus_);
    this.addEventListener('click', this.boundClick_, true);
  }

  onFocus_(event) {
    const focusable = this.querySelector(SELECTORS);
    focusable && focusable.focus();
  }

  /**
   * iOS Voiceover can target the wrong element.
   * Find and click the correct element.
   *
   * @param {!Event} event
   */
  onClick_(event) {
    if (event.target !== event.currentTarget) {
      return;
    }
    const elem = this.querySelector(SELECTORS);
    if (elem) {
      event.preventDefault();
      elem.click();
    }
  }

  static get template() {
    return html`
    <style>
      :host {
        display:  var(--jha-dropdown-menu-item-display, block);
        background-color: var(--jha-component-background-color);
        font-size: 13px;
        cursor: pointer;
        contain: layout;
        padding: 0;
        margin: var(--jha-dropdown-menu-item-margin, 0);
        border-radius: 6px;
        color: var(--jha-text-dark);
        align-items: center;
      }
      :host * {
        padding: 0;
        line-height: 18px;
      }
      :host(:focus),
      :host(:focus-within),
      :host(:hover) {
        outline: none;
        color: var(--jha-dropdown-menu-item-text-hover-color, var(--jha-text-base));
        background-color: var(--jha-dropdown-menu-item-background-hover, var(--jha-focus-highlight-color));
      }
      :host ::slotted(*[icon]) {
        width: 20px;
        height: 20px;
        box-sizing: initial !important;
      }
      @supports (-ms-ime-align:auto) {
        :host(:focus) {
          outline: thin dotted;
        }
      }
      @media all and (-ms-high-contrast:none) {
        :host(:focus) {
          outline: thin dotted;
        }
      }
      :host ::slotted(*) {
        /*
         * padding must be on the slotted item so that anchor tags
         * can be clicked anywhere - not just on the text content
         */
        padding: 8px;
      }
      :host ::slotted(a) {
        @apply --base-anchor-styles;
        color: var(--jha-text-base);
        display: block;
      }
      :host ::slotted(a) {
        outline: none;
      }
      :host([danger]) {
        color: var(--jha-color-danger);
      }
      :host([danger]) ::slotted(a) {
        color: var(--jha-color-danger);
      }
    </style>

    <slot></slot>

  `;
  }
};

customElements.define(window.JhaDropdownMenuItemElement.is, window.JhaDropdownMenuItemElement);
export default window.JhaDropdownMenuItemElement;
