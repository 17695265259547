// TODO: this was used with xhr responseType, which doesn't exist
// on Response. Need to look at content-type headers instead.
// ...
// const binaryTypes = ['arraybuffer', 'blob', 'document', 'json'];
const blobTypes = ['html', 'image', 'application', 'plain'];

/** @type {!Set<!XhrPromise>} */
const requestsInProgress = new Set();
const JSON_RESPONSE_EXPR_ = /^application\/json(;|$)/;

class XhrPromise {
  constructor() {
    let resolve_;
    let reject_;

    /** @type {!Promise} */
    this.promise = new Promise((resolve, reject) => {
      resolve_ = resolve;
      reject_ = reject;
    });

    /**
     * @private
     * @type {!function(*)}
     */
    this.resolve_ = resolve_;

    /**
     * @private
     * @type {!function(!Error)}
     */
    this.reject_ = reject_;

    /** @type {null|function(this:XhrPromise)} */
    this.beforeSend = null;

    // properties for logging purposes
    /** @private */
    this.method_ = null;

    /** @private */
    this.url_ = null;

    // Additional headers to add to request
    /** @type {?Map<!string, !string>} */
    this.headers_ = new Map();

    /**
     * @private
     * @type {!AbortController}
     */
    this.controller_ = new AbortController();

    /** @private */
    this.timeoutId_ = null;

    /** @type {?Response} */
    this.response = null;

    /** @type {number} */
    this.status = 0;
  }

  /**
   * Set additional request headers
   * @param {!Map<!string, !string>} value
   */
  set headers(value) {
    this.headers_ = value;
  }

  /** @param {*=} reason= */
  abort(reason) {
    this.controller_.abort(reason);
    requestsInProgress.delete(this);
  }

  get aborted() {
    return this.controller_.signal.aborted;
  }

  /**
   * @param {!string} url
   * @param {number=} timeout
   * @return {!Promise<*>}
   */
  get(url, timeout) {
    return this.sendWithData('GET', url, null, undefined, timeout);
  }

  /**
   * @param {!string} url
   * @param {(string|Array|Object)=} data
   * @param {string=} contentType
   * @param {number=} timeout
   * @return {!Promise<*>}
   */
  post(url, data = null, contentType = 'application/json', timeout) {
    return this.sendWithData('POST', url, data, contentType, timeout);
  }

  /**
   * @param {!string} url
   * @param {(string|Array|Object)=} data
   * @param {string=} contentType
   * @param {number=} timeout
   * @return {!Promise<*>}
   */
  put(url, data = null, contentType = 'application/json', timeout) {
    return this.sendWithData('PUT', url, data, contentType, timeout);
  }

  /**
   * @param {!string} url
   * @param {(string|Array|Object)=} data
   * @param {string=} contentType
   * @param {number=} timeout
   * @return {!Promise<*>}
   */
  patch(url, data = null, contentType = 'application/merge-patch+json', timeout) {
    return this.sendWithData('PATCH', url, data, contentType, timeout);
  }

  /**
   * @param {!string} url
   * @param {(string|Array|Object)=} data
   * @param {string=} contentType
   * @param {number=} timeout
   * @return {!Promise<*>}
   */
  delete(url, data = null, contentType = 'application/json', timeout) {
    return this.sendWithData('DELETE', url, data, contentType, timeout);
  }

  /**
   * @param {!string} method
   * @param {!string} url
   * @param {(string|Array|Object<string,*>)=} data
   * @param {string=} contentType
   * @param {number=} timeout
   * @return {!Promise<*>}
   */
  sendWithData(method, url, data = null, contentType = 'application/json', timeout = 30000) {
    this.method_ = method;
    this.url_ = url;

    if (contentType) {
      this.headers_.set('content-type', contentType);
    }

    if (this.beforeSend) {
      this.beforeSend();
    }

    if (data === null) {
      data = undefined;
    } else if (data && typeof data !== 'string') {
      if (!(data instanceof FormData)) {
        data = JSON.stringify(data);
      }
    }

    if (url.includes('/ensenta')) {
      const date = new Date();

      let offset = date.toString().match(/([-\+][0-9]+)\s/)[1];
      offset = `${offset.substring(0, offset.length -2)}:${offset.substring(offset.length -2, offset.length)}`;

      const parts = date.toTimeString().match( /\(([^)]+)\)/i );
      let timezone = parts[1];
      if (timezone.search( /\W/ ) >= 0) {
        timezone = timezone.match(/\b\w/g).join('').toUpperCase();
      }
      this.headers_.set('X-Ensenta-TimeZone', `${timezone} ${offset}`);
    }

    requestsInProgress.add(this);
    this.timeoutId_ = setTimeout(() => {
      this.timeout_();
    }, timeout);
    fetch(this.url_, {
      method: this.method_,
      body: data,
      signal: this.controller_.signal,
      headers: Array.from(this.headers_.entries()),
    }).then((response) => this.load_(response))
        .catch((err) => {
          const error = /** @type {!Error} */(err);
          this.reject_(error);
        });
    return this.promise;
  }

  /**
   * @param {Response} response
   * @private */
  async load_(response) {
    this.response = response;
    this.status = response.status;
    clearTimeout(this.timeoutId_);
    requestsInProgress.delete(this);
    let body;
    // some api responses do not have a content-length header set,
    // but do contain a body that should be parsed.
    // ex: GET /users/{userId}/institutions/{institutionId}
    const length = response.headers.get('content-length');
    try {
      const contentType = response.headers.get('content-type');
      if (contentType) {
        if (JSON_RESPONSE_EXPR_.test(contentType)) {
          body = await response.json();
        }  else if (blobTypes.some((b) => contentType.includes(b))) {
          body = await response.blob();
        } else {
          body = await response.text();
        }
      }
    } catch (e) {
      // only reject here if a length was provided and the body failed to parse
      if (length) {
        return this.reject_(new Error(`parsing exception: ${XhrPromise.ErrorCode.JSON_PARSE}, ${this.url_}`));
      }
    }

    if (response.ok) {
      return this.resolve_(body || {});
    }

    const err = new Error(`HTTP error status: ${response.status}`);
    err.statusCode = response.status;
    err['method'] = this.method_;
    err['url'] = response.url || this.url_;
    err['x-request-id'] = response.headers.get('x-request-id');
    err.response = body;
    return this.reject_(err);
  }

  /** @private */
  timeout_() {
    requestsInProgress.delete(this);
    const err = new Error(`Request timed out: ${XhrPromise.ErrorCode.TIMEOUT}`);
    err.errorCode = XhrPromise.ErrorCode.TIMEOUT;
    err['method'] = this.method_;
    err['url'] = this.url_;
    this.controller_.abort();
    this.reject_(err);
  }

  /** @return {!Set<!XhrPromise>} */
  static get requestsInProgress() {
    return requestsInProgress;
  }
}


/** @enum {number} */
XhrPromise.ErrorCode = {
  TIMEOUT: -2,
  NETWORK_ERROR: -3,
  JSON_PARSE: -1,
};

/** @enum */
XhrPromise.Status = {
  UNSENT: 0,
  OPENED: 1,
  HEADERS_RECEIVED: 2,
  LOADING: 3,
  DONE: 4,
};

// XhrPromise adds extra properties to the Error object
// We can't extend the native error class because that screws up stack traces

/** @type {number|undefined} */
Error.prototype.statusCode;

/** @type {?} */
Error.prototype.response;

export default XhrPromise;
