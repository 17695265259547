/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconEditElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-edit';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M21,7,17,3A.75.75,0,0,0,16,3l-2,2L4.94,14l-.47.47a.74.74,0,0,0-.21.38l-1,5a.75.75,0,0,0,.74.9h.15l5-1a.74.74,0,0,0,.38-.21l.47-.47L19.06,10l2-2A.75.75,0,0,0,21,7ZM8.63,18.31,5,19l.73-3.67L6,15.06,8.94,18ZM10,16.94,7.06,14,14,7.06,16.94,10Zm8-8L15.06,6,16.5,4.56,19.44,7.5Z"/>
    </svg>
  `;
  }
};

customElements.define(window.JhaIconEditElement.is,
    window.JhaIconEditElement);
export default window.JhaIconEditElement;
