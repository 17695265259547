/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconSupportElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-support';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M20.25,6 C21.2164983,6 22,6.78350169 22,7.75 L22,22 L16.748,18 L9.75,18 C8.78350169,18 8,17.2164983 8,16.25 L8,15.5 L9.5,15.5 L9.5,16.25 C9.5,16.3880712 9.61192881,16.5 9.75,16.5 L17.2539001,16.5 L20.5,18.982 L20.5,7.75 C20.5,7.61192881 20.3880712,7.5 20.25,7.5 L19.5,7.5 L19.5,6 L20.25,6 Z M16.25,2 C17.1681734,2 17.9211923,2.70711027 17.9941988,3.60647279 L18,3.75 L18,12.25 C18,13.1681734 17.2928897,13.9211923 16.3935272,13.9941988 L16.25,14 L7.267,14 L2,18.3392375 L2,3.75 C2,2.8318266 2.70711027,2.07880766 3.60647279,2.0058012 L3.75,2 L16.25,2 Z M16.25,3.5 L3.75,3.5 C3.63165327,3.5 3.53251318,3.58223341 3.50660268,3.69267729 L3.5,3.75 L3.5,15.161 L6.73092595,12.5 L16.25,12.5 C16.3683467,12.5 16.4674868,12.4177666 16.4933973,12.3073227 L16.5,12.25 L16.5,3.75 C16.5,3.63165327 16.4177666,3.53251318 16.3073227,3.50660268 L16.25,3.5 Z"/>
    </svg>
  `;
  }
};

customElements.define(window.JhaIconSupportElement.is,
    window.JhaIconSupportElement);
export default window.JhaIconSupportElement;
