/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconPopoutElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-popout';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M17,14.25a.75.75,0,0,0-.75.75v4.25H4.75V7.75H10a.75.75,0,0,0,0-1.5H4A.75.75,0,0,0,3.25,7V20a.75.75,0,0,0,.75.75H17a.75.75,0,0,0,.75-.75V15A.75.75,0,0,0,17,14.25Z"/>
    <path d="M13.67,3.92a.75.75,0,0,0,0,1.5h3.85L11,12A.75.75,0,1,0,12,13l6.55-6.55v3.85a.75.75,0,0,0,1.5,0V3.92Z"/>
    </svg>
  `;
  }
};

customElements.define(window.JhaIconPopoutElement.is,
    window.JhaIconPopoutElement);
export default window.JhaIconPopoutElement;
