/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconCameraElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-camera';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M20,18.75H4A1.75,1.75,0,0,1,2.25,17V8A1.75,1.75,0,0,1,4,6.25H6.54l1-2h8.93l1,2H20A1.75,1.75,0,0,1,21.75,8v9A1.75,1.75,0,0,1,20,18.75ZM4,7.75A.25.25,0,0,0,3.75,8v9a.25.25,0,0,0,.25.25H20a.25.25,0,0,0,.25-.25V8A.25.25,0,0,0,20,7.75H16.54l-1-2H8.46l-1,2Z"/>
    <path d="M12,15.75A3.75,3.75,0,1,1,15.75,12,3.75,3.75,0,0,1,12,15.75Zm0-6A2.25,2.25,0,1,0,14.25,12,2.25,2.25,0,0,0,12,9.75Z"/>
    </svg>
  `;
  }
};

customElements.define(window.JhaIconCameraElement.is,
    window.JhaIconCameraElement);
export default window.JhaIconCameraElement;
