/* eslint-disable @banno/ux/custom-element-name */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import {DomIf} from '@polymer/polymer/lib/elements/dom-if.js';

/** @enum {string} */
const UploadState = {
  UNSENT: 'UNSENT',
  SENDING: 'SENDING',
  SUCCESS: 'SUCCESS',
  CANCELLED: 'CANCELLED',
  ERROR: 'ERROR',
};

/**
 * Create a v4 UUID
 * See https://stackoverflow.com/a/2117523/1211524
 */
function generateID() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => { // eslint-disable-line space-infix-ops
    const cryptObj = typeof crypto === 'undefined' ? window['msCrypto'] : crypto;
    return (c ^ cryptObj.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16);
  });
}

class UploadFile {
  /**
   * @param {!File} file
   * @param {string=} parameterName
   */
  constructor(file, parameterName = 'file') {
    this.id = generateID();
    this.file = file;
    this.parameterName = parameterName;
  }
}

class UploadQueue {
  constructor() {
    /** @type {!Array<!UploadFile>} */
    this.queue = [];
    this.percentComplete = 0;
    this.status = UploadState.UNSENT;
    this.xhr = new XMLHttpRequest();

    this._boundProgress = (e) => this.progress(e);
    this._boundLoaded = (e) => this.loaded(e);
    this._boundError = (e) => this.error(e);
    this._boundAborted = (e) => this.aborted(e);
  }

  /**
   * Allow consumers to set custom XHR headers, events or other properties
   * after the xhr.open but before the xhr.send
   */
  beforeSend() {}

  /**
   * @param {string} method
   * @param {string} url
   * @param {!FormData=} body
   */
  upload(method, url, body) {
    this.status = UploadState.UNSENT;
    const formData = body || new FormData();
    this.queue.forEach((uploadFile) => {
      formData.append(uploadFile.parameterName, uploadFile.file);
    });


    this.xhr.upload.addEventListener('progress', this._boundProgress, false);
    this.xhr.addEventListener('abort', this._boundAborted, false);
    this.xhr.addEventListener('load', this._boundLoaded, false);
    this.xhr.addEventListener('error', this._boundError, false);
    this.xhr.open(method, url, true);
    this.beforeSend();
    this.xhr.send(formData);
    this.status = UploadState.SENDING;
  }

  /** @param {!Event} evt */
  progress(evt) {
    this.percentComplete = Math.round((evt.lengthComputable ? (evt.loaded / evt.total) : 0) * 1000) / 10;
  }

  /** @param {!Event} evt */
  loaded(evt) {
    // eslint-disable-next-line no-magic-numbers
    if (this.xhr.status < 200 || this.xhr.status >= 300) {
      this.status = UploadState.ERROR;
      this.percentComplete = 0;
    } else {
      this.status = UploadState.SUCCESS;
      this.percentComplete = 100;
    }
    this._cleanup(evt);
  }

  /** @param {!Event} evt */
  error(evt) {
    this.status = UploadState.ERROR;
    this._cleanup(evt);
  }

  /** @param {!Event} evt */
  aborted(evt) {
    this.status = UploadState.CANCELLED;
    this._cleanup(evt);
  }

  /** @param {!Event} evt */
  _cleanup(evt) {
    this.xhr.upload.removeEventListener('progress', this._boundProgress, false);
    this.xhr.removeEventListener('abort', this._boundAborted, false);
    this.xhr.removeEventListener('load', this._boundLoaded, false);
    this.xhr.removeEventListener('error', this._boundError, false);
  }
}

/**
 * prevent cursor from showing drop icon over document
 * @param {Event} event
 */
function dragDocument(event) {
  event.preventDefault();
  event.dataTransfer.dropEffect = 'none';
}

/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.PolymerFileUploader = class extends PolymerElement {
  static get is() {
    return 'polymer-file-uploader';
  }

  static get properties() {
    return {
      name: {
        type: String,
        value: 'file',
      },
      type: String,
      showSelect: {
        type: Boolean,
        value: false,
      },
      multiple: {
        type: Boolean,
        value: false,
      },
      accept: String,
      bodyData: Object,

      /** @type {!Array<!UploadFile>} */
      queue: {
        type: Array,
        value: () => [],
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();

    if (!this.type) {
      throw new Error('Please pass `type` to polymerFileUploader');
    }

    if (this.type === 'select') {
      this._generateSelect();
    }
    document.addEventListener('dragenter', dragDocument);
    document.addEventListener('dragover', dragDocument);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('dragenter', dragDocument);
    document.removeEventListener('dragover', dragDocument);
  }

  _isEqual(a, b) {
    return a === b;
  }

  _createInputFile(name, multiple, showSelect, accept) {
    const inputFile = document.createElement('INPUT');
    inputFile.setAttribute('type', 'file');
    inputFile.setAttribute('name', name);
    if (multiple) {
      inputFile.setAttribute('multiple', '');
    }
    if (!showSelect) {
      inputFile.setAttribute('style', 'display:none;');
    }
    if (accept) {
      inputFile.setAttribute('accept', accept);
    }

    return inputFile;
  }

  _generateSelect() {
    const inputFile = this._createInputFile(this.name, this.multiple, this.showSelect, this.accept);
    const render = this.shadowRoot.querySelector('.render');

    /** @param {!Event} e */
    inputFile.onchange = (e) => {
      this._processFiles([].slice.call(e.target.files));
      e.target.value = '';
    };
    render.appendChild(inputFile);
  }

  /** @param {!Array<!File>} files */
  _processFiles(files) {
    if (files.length === 0) {
      return;
    }

    if (!this.multiple) {
      files = files.slice(0, 1);
    }

    files.forEach((file) => {
      // check if filetype is invalid
      const acceptValues = this.accept && this.accept.split(',');
      const acceptFile = this.accept && acceptValues.some((acceptValue) => {
        const value = acceptValue.trim().toLowerCase();
        const extension = file.name.split('.').pop().toLowerCase();
        return file.type.match(value) || `.${extension}` === value;
      });
      if (this.accept && !acceptFile) {
        return this.dispatchEvent(new CustomEvent('file-error', {
          bubbles: true,
          composed: true,
          detail: {
            message: `${file.name} filetype doesn't match ${this.accept}`,
            filename: file.name,
            filetype: file.type,
          },
        }));
      }
      this.push(JSCompiler_renameProperty('queue', this), new UploadFile(file, this.name));
    });
    this.dispatchEvent(new CustomEvent('change', {
      bubbles: true,
      composed: true,
      detail: {
        queue: this.queue,
      },
    }));
  }

  /**
   * @param {string} method
   * @param {string} url
   * @param {!FormData=} body
   */
  upload(method, url, body) {
    this._upload(this.queue, method, url, body);
    this.queue = [];
  }

  /**
   * @param {string} id
   * @param {string} method
   * @param {string} url
   * @param {!FormData=} body
   */
  uploadFile(id, method, url, body) {
    const uploadFile = this.queue.find((uploadFile_) => uploadFile_.id === id);
    if (uploadFile) {
      this.removeFile(uploadFile);
      this._upload([uploadFile], method, url, body);
    }
  }

  /** @param {!UploadFile} uploadFile */
  removeFile(uploadFile) {
    const index = this.queue.indexOf(uploadFile);
    if (index >= 0) {
      this.splice(JSCompiler_renameProperty('queue', this), index, 1);
    }
  }

  /**
   * @param {!Array<!UploadFile>} files
   * @param {string} method
   * @param {string} url
   * @param {!FormData=} body
   */
  _upload(files, method, url, body) {
    const uploader = new UploadQueue();
    uploader.queue = files;

    uploader.error = (evt) => {
      UploadQueue.prototype.error.call(uploader, evt);
      this.dispatchEvent(new CustomEvent('change', {
        bubbles: true,
        composed: true,
        detail: {
          uploader,
        },
      }));
    };

    uploader.aborted = (evt) => {
      UploadQueue.prototype.aborted.call(uploader, evt);
      this.dispatchEvent(new CustomEvent('change', {
        bubbles: true,
        composed: true,
        detail: {
          uploader,
        },
      }));
    };

    uploader.loaded = (evt) => {
      UploadQueue.prototype.loaded.call(uploader, evt);
      this.dispatchEvent(new CustomEvent('change', {
        bubbles: true,
        composed: true,
        detail: {
          uploader,
        },
      }));
    };

    uploader.progress = (evt) => {
      UploadQueue.prototype.progress.call(uploader, evt);
      this.dispatchEvent(new CustomEvent('change', {
        bubbles: true,
        composed: true,
        detail: {
          uploader,
        },
      }));
    };

    uploader.upload(method, url, body);

    this.dispatchEvent(new CustomEvent('change', {
      bubbles: true,
      composed: true,
      detail: {
        uploader,
      },
    }));
  }

  _toggleFileHover(isHovered) {
    const dropZone = this.shadowRoot.querySelector('.drop-zone');
    dropZone.className = isHovered ? 'drop-zone file-hover' : 'drop-zone';
  }

  _handleDrop(event) {
    event.preventDefault();
    this._processFiles([].slice.call(event.dataTransfer.files));
    this._toggleFileHover(false);
  }

  _handleDragOver(event) {
    event.preventDefault();
    // ensure cursor shows drop icon over our drop zone
    event.stopImmediatePropagation();
    event.dataTransfer.dropEffect = 'copy';
  }

  _handleDragEnd(event) {
    event.dataTransfer.clearData();
  }

  _handleDragEnter(event) {
    if (!event.relatedTarget || event.relatedTarget.tagName === 'ARTICLE' || event.relatedTarget.tagName === 'ASSETS-UPLOAD-AREA') {
      this._toggleFileHover(true);
    };
  }

  _handleDragLeave(event) {
    if (!event.relatedTarget || event.relatedTarget.tagName === 'ARTICLE' || event.relatedTarget.tagName === 'ASSETS-UPLOAD-AREA') {
      this._toggleFileHover(false);
    };
  }

  _handleSlotClick(event) {
    const renderElement = event.composedPath().find((element) => element.tagName === 'DIV' && element.className.includes('render'));
    const fileInput = renderElement.querySelector('input');
    const clickEvent = new MouseEvent('click', {
      bubbles: true,
      cancelable: false,
      view: window,
    });

    fileInput.dispatchEvent(clickEvent);
  }

  static get template() {
    return html`
    <style>
      :host {
        display: block;
      }
      .drop-zone {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        padding: 40px 0;
      }
      .file-hover {
        background-color: var(--jha-border-color);
      }
      .render {
        width: 100%;
      }
      .render > div:not(.drop-zone) {
        display: var(--polymer-file-uploader-slot-display, block);
      }
      :host ::slotted(*[icon]) {
        margin-right: 8px;
        width: 20px;
        height: 20px;
        box-sizing: initial !important;
      }
    </style>
    <div class="render">
      <template is="dom-if" if="[[_isEqual(type, 'drop')]]">
        <div class="drop-zone" on-drop="_handleDrop" on-dragover="_handleDragOver" on-dragend="_handleDragEnd" on-dragenter="_handleDragEnter" on-dragleave="_handleDragLeave">
          <slot></slot>
        </div>
      </template>
      <template is="dom-if" if="[[_isEqual(type, 'select')]]">
          <slot on-click="_handleSlotClick"></slot>
        </div>
      </template>
    </div>
  `;
  }
};
/** @const */
window.PolymerFileUploader.UploadQueue = UploadQueue;

/** @const */
window.PolymerFileUploader.UploadFile = UploadFile;

customElements.define(window.PolymerFileUploader.is, window.PolymerFileUploader);
export default window.PolymerFileUploader;
