/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconSettingsElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-settings';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24">
    <path d="M19.71,12.71a6.42,6.42,0,0,0,0-1.41L22,9.6a.75.75,0,0,0,.25-.86l-.13-.35a10.2,10.2,0,0,0-1.24-2.32l-.22-.31a.75.75,0,0,0-.86-.27l-2.64.94a7.19,7.19,0,0,0-1.84-1.11L14.8,2.5a.75.75,0,0,0-.65-.62l-.37,0a9.21,9.21,0,0,0-2.58,0l-.37,0a.75.75,0,0,0-.65.62L9.71,5.31A7.19,7.19,0,0,0,7.88,6.42L5.23,5.49a.75.75,0,0,0-.86.27l-.22.31A10.23,10.23,0,0,0,2.92,8.39l-.13.35A.75.75,0,0,0,3,9.6l2.25,1.69a6.42,6.42,0,0,0,0,1.41L3,14.4a.75.75,0,0,0-.25.86l.13.35a10.2,10.2,0,0,0,1.24,2.32l.22.31a.75.75,0,0,0,.86.27l2.64-.94a7.19,7.19,0,0,0,1.84,1.11l.48,2.81a.75.75,0,0,0,.65.62l.37,0a9.41,9.41,0,0,0,2.58,0l.37,0a.75.75,0,0,0,.65-.62l.48-2.81a7.19,7.19,0,0,0,1.84-1.11l2.64.94a.75.75,0,0,0,.86-.27l.22-.31a10.23,10.23,0,0,0,1.24-2.32l.13-.35A.75.75,0,0,0,22,14.4Zm0,4.2L17.21,16a.76.76,0,0,0-.77.16,5.69,5.69,0,0,1-2.07,1.25.75.75,0,0,0-.49.58l-.46,2.68a7.52,7.52,0,0,1-1.84,0L11.12,18a.75.75,0,0,0-.49-.58,5.69,5.69,0,0,1-2.07-1.25A.75.75,0,0,0,7.79,16l-2.53.89a8.67,8.67,0,0,1-.88-1.65l2.15-1.62a.75.75,0,0,0,.29-.72,5.26,5.26,0,0,1,0-1.84.75.75,0,0,0-.29-.72L4.39,8.74a8.7,8.7,0,0,1,.88-1.65L7.79,8a.75.75,0,0,0,.77-.16,5.69,5.69,0,0,1,2.07-1.25A.75.75,0,0,0,11.12,6l.46-2.68a7.47,7.47,0,0,1,1.84,0L13.88,6a.75.75,0,0,0,.49.58,5.69,5.69,0,0,1,2.07,1.25.75.75,0,0,0,.77.16l2.53-.89a8.67,8.67,0,0,1,.88,1.65l-2.15,1.62a.75.75,0,0,0-.29.72,5.26,5.26,0,0,1,0,1.84.75.75,0,0,0,.29.72l2.15,1.62A8.7,8.7,0,0,1,19.73,16.91Z"/>
    <path d="M12.5,8.75A3.25,3.25,0,1,0,15.75,12,3.25,3.25,0,0,0,12.5,8.75Zm0,5A1.75,1.75,0,1,1,14.25,12,1.75,1.75,0,0,1,12.5,13.75Z"/>
    </svg>
  `;
  }
};

customElements.define(window.JhaIconSettingsElement.is,
    window.JhaIconSettingsElement);
export default window.JhaIconSettingsElement;
