import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/** @polymer */
class JhaDefaultAvatar extends PolymerElement {
  static get is() { return 'jha-default-avatar'; }

  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 96px;
        height: 96px;
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><circle fill="#B3BFC9" cx="18" cy="18" r="18"></circle><path fill="#EEF1F4" d="M18 0C8.1 0 0 8.1 0 18s8.1 18 18 18 18-8.1 18-18S27.9 0 18 0zm0 7.7c3 0 5.4 2.4 5.4 5.4 0 3-2.4 5.4-5.4 5.4s-5.4-2.4-5.4-5.4c0-3 2.4-5.4 5.4-5.4zM18 31c-4.5 0-8.5-2.3-10.8-5.8 0-3.6 7.2-5.5 10.8-5.5s10.7 2 10.8 5.5C26.5 28.7 22.5 31 18 31z"></path></svg>
  `;
  }
}
customElements.define(JhaDefaultAvatar.is, JhaDefaultAvatar);
export default JhaDefaultAvatar;
