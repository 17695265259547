import {LitElement, html, css} from 'lit-element';
import {jhaStyles} from '@banno/platform-ux-shared/styles/jha-styles';
import {getCopyOfCoreNav} from './helpers/core-navigation';
import '@banno/platform-ux-shared/components/polymer3/jha/dialogs/jha-dialog.js';

const styles = css`
  :host {
    display: block;
  }
  article[dialog] {
    overflow-y: auto;
  }
  h2 {
    border-bottom: 1px solid #ddd;
  }
  code {
    color: #000;
    background-color: #eee;
    padding: 2px 6px;
  }
  blockquote {
    background-color: #eee;
    font-size: 14px;
    margin-top: 2px;
    margin-left: 0px;
    padding-left: 20px;
    padding: 6px 0 6px 24px;
  }
  @media (max-width: 480px) {
    article {
      padding: 32px 16px;
    }
  }
`;

class InstitutionAndUserDetailsDialog extends LitElement {
  static get styles() {
    return [jhaStyles, styles];
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('close', this._closeDialog);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('close', this._closeDialog);
  }

  static get properties() {
    return {
      loggedInUser: {type: Object, value: null},
      selectedInstitution: {type: Object, value: null}
    };
  }

  _closeDialog() {
    this.dispatchEvent(new CustomEvent('closeModal', {
      bubbles: true,
      composed: true
    }));
  }

  render() {
    const _valueToString = (value) => {
      if (value === undefined) {
        return 'undefined';
      }
      if (value === null) {
        return 'null';
      }
      if (typeof value === 'string') {
        return `'${value}'`
      }
      return `${value}`;
    }

    return html`
      <jha-dialog no-footer title="Current institution & logged in user details">
        <article dialog style="padding-top:0;">
          <h2>Institution</h2>
          <blockquote>
            <code>
            ${Object.keys(this.selectedInstitution.institution).map((key, i, arr) => html`
              <b>${key}</b>: ${_valueToString(this.selectedInstitution.institution[key])}${arr.length - 1 > i ? ',' : null}<br />
            `)}
            </code>
          </blockquote>
          <h2>Logged in user</h2>
          <blockquote>
            <code>
              ${Object.keys(this.loggedInUser).map((key, i, arr) => html`
                <b>${key}</b>: ${_valueToString(this.loggedInUser[key])}${arr.length - 1 > i ? ',' : null}<br />
              `)}
            </code>
          </blockquote>
        </article>
      </jha-dialog>
    `;
  }
}

customElements.define('institution-and-user-details-dialog', InstitutionAndUserDetailsDialog); // eslint-disable-line @banno/ux/custom-elements-define
export default InstitutionAndUserDetailsDialog;
