/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconChevronLeftElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-chevron-left';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M14.5,15.9c0-0.2-0.1-0.4-0.2-0.5L11,11.9l3.3-3.5c0.1-0.3,0.1-0.6-0.1-0.9s-0.5-0.3-0.8-0.1l-3.8,4
      c-0.2,0.3-0.2,0.7,0,1l3.8,4c0.2,0.2,0.5,0.3,0.8,0.2C14.4,16.5,14.6,16.2,14.5,15.9L14.5,15.9z"/>
    </svg>
  `;
  }
};

customElements.define(window.JhaIconChevronLeftElement.is,
    window.JhaIconChevronLeftElement);
export default window.JhaIconChevronLeftElement;
