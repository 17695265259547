import InstitutionsController from '@banno/platform-ux-shared/controllers/institutions-controller.js';
import EnvironmentConfigController from '@banno/platform-ux-shared/controllers/environment-config.js';
import EnterpriseConfigService from '@banno/platform-ux-shared/services/config/enterprise-config-service.js';

class ReportsAppController {
  constructor() {
    this.envConfig = EnvironmentConfigController.getConfig();
  }

  static get environmentConfig() {
    return this.envConfig;
  }

  async getInstitutionAbilities(institutionId, loggedInUser) {
    const abilitiesAndOverrides = await Promise.all([
      InstitutionsController.getInstitutionAbilities(institutionId),
      InstitutionsController.getInstitutionDetails(institutionId),
    ]);

    const abilities = Object.assign(
      abilitiesAndOverrides[1].abilities,
      abilitiesAndOverrides[0]
    );

    try {
      this.envConfig = await EnvironmentConfigController.getConfig();
      this.enterpriseConfig = await EnterpriseConfigService.getUser(institutionId, loggedInUser.userId);
      const institutionOverrides = this.envConfig.enterprise.institutions;
      const hasOverrides = Object.keys(institutionOverrides).includes(institutionId);
      if ((hasOverrides && institutionOverrides[institutionId].externalTransfers) || 
        (this.enterpriseConfig && this.enterpriseConfig.ExternalTransfers.externalTransfersByUser)) {
        abilities.schedulableTransfers = true;
        abilities.externalTransferOutbound = true;
        abilities.externalTransferInbound = true;
      }
    } catch (e) {
      console.warn('Could not retrieve local config');
    }
    return abilities;
  }
}

export default ReportsAppController;
