import store from '@banno/platform-ux-shared/session-storage/store';
import {setInstitutionConfig} from '@banno/platform-ux-shared/session-storage/institution-store-helper';

export const instHasBannoOnline = async (institutionId) => {
  if (!store.institutionConfig || !store.institutionConfig[institutionId]) {
    await setInstitutionConfig();
  }
  const {
    webServerConfig,
    settingsDetails: {aggregationType},
    featureFlags,
    environmentConfig: {enterprise}} = store.institutionConfig[institutionId];
  const hasOnlineAggType = aggregationType === 'Online Banking';
  if (hasOnlineAggType) return true;
  const getWebConfigValue = (fieldName) => Boolean((webServerConfig || {})[fieldName]);
  if (!featureFlags.checkMobileOnlyForOnline) {
    return (getWebConfigValue('live') || (enterprise.institutions[webServerConfig.institutionId] || {}).eps);
  }
  return !getWebConfigValue('mobileOnly');
};
