import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import '@banno/platform-ux-shared/components/polymer3/jha/buttons/jha-button.js';
import('@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-close.js');

const EventType = {
  CLOSE: 'close',
};

/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaDialogElement = class extends PolymerElement {
  static get is() {
    return 'jha-dialog';
  }
  static get properties() {
    return {
      title: String,
      subTitle: String,
    };
  }

  _handleCloseClick() {
    this.dispatchEvent(new CustomEvent(EventType.CLOSE, {composed: true}));
  }

  static get template() {
    return html`
      <style>
        :host {
          display: block;
          overflow: hidden;
        }
        header {
          padding: 32px;
          border-bottom: 1px solid var(--jha-border-color, #e4e7ea);
          border-top-left-radius: 1px;
          border-top-right-radius: 1px;
          text-align: center;
          position: relative;
          font-size: 18px;
          font-weight: 400;
          color: var(--jha-text-dark, #191919);
        }
        #sub-title {
          font-size: 14px;
        }
        ::slotted(div[slot="extra-icon"]) {
          position: absolute;
          top: 30%;
          right: 56px;
        }
        jha-button {
          --jha-button-line-height: 12px;
          position: absolute;
          top: 30%;
          right: 20px;
          opacity: 0.6;
          transition: opacity 0.3s cubic-bezier(0.1,.5,.1,1);
        }
        jha-button:hover {
          opacity: 1;
        }
        jha-icon-close {
          width: 18px;
          height: 18px;
          fill: var(--jha-color-gray-medium, #707070);
        }
        ::slotted(article) {
          max-height: calc(100vh - 360px);
          overflow-y: auto;
        }
        :host([no-footer]) footer {
          display: none;
        }
        footer {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          border-bottom-left-radius: 1px;
          border-bottom-right-radius: 1px;
          border-top: 1px solid var(--jha-border-color, #e4e7ea);
          padding: 32px;
        }
        @media (max-width: 739px) {
          header,
          ::slotted(article),
          footer {
            padding: 16px;
          }
          jha-button {
            right: 10px;
          }
        }
        @media (max-width: 480px) {
          ::slotted(article) {
            max-height: calc(100vh - 182px);
          }
        }
        /* Edge specific styles */
        @supports (-ms-ime-align: auto) {
          header {
            text-align: center;
          }
        }
      </style>
      <header dialog>
        <slot name="header-left"></slot>
        <div id="title">[[title]]</div>
        <div id="sub-title">[[subTitle]]</div>
        <slot name="extra-icon"></slot>
        <jha-button type="button" sync icon on-click="_handleCloseClick">
          <jha-icon-close title="Close"></jha-icon-close>
        </jha-button>
      </header>
      <slot></slot>
      <footer dialog>
        <slot name="footer"></slot>
      </footer>
    `;
  }
};

customElements.define(window.JhaDialogElement.is, window.JhaDialogElement);
export default window.JhaDialogElement;
