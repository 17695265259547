import {LitElement, html, css} from 'lit-element';
import {jhaStyles} from '@banno/platform-ux-shared/styles/jha-styles';
import './jha-enterprise-institution-selector.js';
import '@banno/platform-ux-shared/components/polymer3/jha/buttons/jha-button';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-switch';
import store from '@banno/platform-ux-shared/session-storage/store.js';
import DialogMixin from '@banno/platform-ux-shared/mixins/dialog.js';

const styles = css`
  :host {
    display: block;
    background-color: transparent;
    margin-bottom: 8px;
    position: relative;
  }
  :host([dashboard]) {
    margin-bottom: 0px;
  }
  jha-button {
    --jha-button-link-padding-horizontal: 8px;
    --jha-button-justify-content: flex-start;
  }
  :host(:not([dashboard])) jha-button {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  :host([support]) {
    margin-bottom: 4px;
  }
  :host([dashboard]) jha-button {
    --jha-button-link-padding-horizontal: 0px;
    --jha-button-link-text: #ffffff;
    --jha-button-link-font-weight: 500;
    text-decoration: underline;
    text-decoration-color: var(--jha-text-white, #ffffff);
  }
  :host([dashboard]) jha-button[flex],
  :host([support]) jha-button[flex],
  :host([no-sec-nav]) jha-button[flex] {
    display: inline-block !important;
    --jha-button-slot-wrapper-display: inline-block;
  }
  :host([dashboard]) jha-button:hover,
  :host([dashboard]) jha-button:focus {
    --jha-button-link-text-hover: #ffffff;
  }
  :host([dashboard]) jha-button div {
    font-size: 14px;
    line-height: 22px;
    height: 24px;
  }
  :host([support]) jha-button,
  :host([no-sec-nav]) jha-button {
    --jha-button-link-padding-horizontal: 18px;
    display: inline-block;
  }
  :host(:not([dashboard])) div {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: left;
    line-height: 18px;
    white-space: normal;
    overflow: hidden;
    font-weight: 500;
    font-size: 13px;
  }
  jha-icon-switch {
    display: none;
  }
  @media (min-width: 1025px) {
    :host(:not([dashboard])[fi-display-only]) div {
      margin: 24px 0 0 8px;
    }
    :host(:not([fi-display-only])[support]),
    :host(:not([fi-display-only])[no-sec-nav]) {
      height: 46px;
    }
    :host([support][fi-display-only]) div {
      margin-top: 32px;
      margin-left: 20px;
    }
    :host([no-sec-nav]) {
      margin-bottom: 4px;
      margin-left: -18px;
    }
    :host([no-sec-nav][fi-display-only]) div {
      margin-top: 28px;
      margin-left: 0;
    }
  }
  @media (max-width: 1024px) {
    :host(:not([dashboard])) {
      flex: 1;
      margin-left: 16px;
      margin-bottom: 0px;
    }
    :host(:not([dashboard])) jha-button {
      --jha-button-white-space: normal;
      --jha-button-text-align: left;
      --jha-button-line-height: 18px;
      --jha-button-link-padding-vertical: 8px;
      --jha-button-flex-width: 100%;
    }
    :host([dashboard]),
    :host([support]) {
      margin-top: 0;
    }
    :host(:not([dashboard])) div {
      flex: 1;
      color: var(--jha-text-dark, #191919);
      font-size: 16px;
      line-height: 24px;
    }
    :host(:not([dashboard])) jha-button {
      position: relative;
    }
    :host([dashboard]) {
      margin-bottom: -8px;
    }
    :host([no-sec-nav]) div {
      line-height: 24px;
    }
    :host(:not([dashboard])) jha-icon-switch {
      display: block;
      width: 18px;
      height: 18px;
      margin-left: auto;
    }
  }
  @media (min-width: 740px) {
    :host([dashboard]) {
      margin-right: -18px;
    }
  }
  @media (max-width: 739px) {
    :host([dashboard]) {
      margin-left: -18px;
      margin-bottom: 0;
    }
  }
`;

class JhaEnterpriseFiName extends DialogMixin(LitElement) {
  static get styles() {
    return [jhaStyles, styles];
  }

  static get properties() {
    return {
      selectedInstitution: {type: Object},
      userInstitutions: {type: Array}
    };
  }

  constructor() {
    super();
    this.userInstitutions = store.institutions;
    this._setSelectedInstitution(store.getSelectedInstitution());
    this._boundInstitutionChanged = this._handleInstitutionChanged.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('institution-changed', this._boundInstitutionChanged);
    if (this.userInstitutions.length == 1) {
      this.setAttribute('fi-display-only', '');
    } else {
      null;
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('institution-changed', this._boundInstitutionChanged);
  }

  _setSelectedInstitution(selectedInstitution) {
    if (!selectedInstitution || !selectedInstitution.institution) return;
    const {institution: {shortId}} = selectedInstitution;
    if ((window.location.pathname + '/').includes(`/${shortId}/`)) {
      this.selectedInstitution = selectedInstitution;
    }
  }

  _handleInstitutionChanged({detail: {selectedInstitution}}) {
    if (selectedInstitution !== this.selectedInstitution) {
      this._setSelectedInstitution(selectedInstitution);
    }
  }

  openInstitutionSelector() {
    const institutionSelectorElement = document.createElement('jha-enterprise-institution-selector');
    institutionSelectorElement.setAttribute('isInApp', true);
    institutionSelectorElement.addEventListener('close-institution-selector', () => {
      this.dispatchEvent(new CustomEvent('closeModal', {
        bubbles: true,
        composed: true
      }));
    });
    this.dispatchEvent(new CustomEvent('openModal', {
      detail: {
        el: institutionSelectorElement,
        disableBackdropClick: !Boolean(this.selectedInstitution.institution.institutionId)
      },
      bubbles: true,
      composed: true
    }));
  }

  render() {
    if (!this.selectedInstitution || !this.selectedInstitution.institution) return null;
    const {name: institutionName} = this.selectedInstitution.institution;
    return html`
      ${this.userInstitutions && this.userInstitutions.length > 1 ? html`
        <jha-button link block flex sync @click=${this.openInstitutionSelector}>
          <div>${institutionName}</div>
          <jha-icon-switch title="Switch icon"></jha-icon-switch>
        </jha-button>` :
        html`<div>${institutionName}</div>`}
    `;
  }
}

export {styles as JhaEnterpriseFiNameStyles};

customElements.define('jha-enterprise-fi-name', JhaEnterpriseFiName); // eslint-disable-line @banno/ux/custom-elements-define
export default JhaEnterpriseFiName;
