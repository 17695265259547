import JhaFetch from '../services/jha-fetch.js';
import UsersApi from '../services/users/users-api.js';
import MobileApi from '../services/mobile/mobile-api.js';
import SentryApi from '../services/sentry/sentry-api.js';
import SentinelApi from '../services/sentinel/sentinel-api.js';
'use strict';
class UsersController {
  static deleteUser(userId) {
    return JhaFetch.fetch({ api: UsersApi.user(), urlArguments: { userId }, method: 'DELETE' });
  }
  static getApplicationUsers(applicationId, institutionId) {
    return JhaFetch.fetch({ api: UsersApi.institutionApplicationUsers(), urlArguments: { applicationId, institutionId } });
  }
  static getAuthzUsers(institutionId) {
    return JhaFetch.fetch({ api: UsersApi.authzUsers(), urlArguments: { institutionId } });
  }
  static getAuthzUser(institutionId, userId) {
    return JhaFetch.fetch({ api: UsersApi.authzUser(), urlArguments: { institutionId, userId } });
  }
  static getUserNotification(institutionId, userId, emailId) {
    return JhaFetch.fetch({
      api: SentryApi.userNotificationFactory(),
      urlArguments: { userId, institutionId, emailId }
    });
  }
  static getUserNotifications(institutionId, userId, offset, count) {
    return JhaFetch.fetch({
      api: SentryApi.userNotificationHistoryFactory(),
      urlArguments: { userId, institutionId, offset, count }
    });
  }
  static getUserCheckSummary(institutionId, userId) {
    return JhaFetch.fetch({
      api: SentinelApi.checkSummaryFactory(),
      urlArguments: { institutionId, userId }
    });
  }
  static updateUserCheckSummary(institutionId, userId, payload) {
    return JhaFetch.fetch({
      api: SentinelApi.reportFactory(),
      urlArguments: { institutionId, userId },
      method: 'PUT',
      body: payload
    });
  }
  static updateAuthzUserProfile(institutionId, userId, body) {
    return JhaFetch.fetch({
      api: UsersApi.authzUser(),
      method: 'PUT',
      urlArguments: { institutionId, userId },
      body
    });
  }
  static getUser(userId) {
    return JhaFetch.fetch({ api: UsersApi.user(), urlArguments: { userId } });
  }
  static getUsers(institutionId) {
    return JhaFetch.fetch({ api: UsersApi.users(), urlArguments: { institutionId } });
  }
  static createUsers(body) {
    return JhaFetch.fetch({
      api: SentryApi.usersFactory(),
      method: 'POST',
      body
    });
  }
  static updateUserProfile(userId, body) {
    return JhaFetch.fetch({
      api: UsersApi.user(),
      method: 'PUT',
      urlArguments: { userId },
      body
    });
  }
  static getConsumerUser(institutionShortId, userId) {
    return JhaFetch.fetch({ api: MobileApi.getUser(), urlArguments: { institutionShortId, userId } });
  }
  static getConsumerUserFromConsumer(userId) {
    return JhaFetch.fetch({ api: UsersApi.userConsumer(), urlArguments: { userId } });
  }
  static unlockUser(userId) {
    return JhaFetch.fetch({ api: UsersApi.unlock(), urlArguments: { userId }, method: 'DELETE' });
  }
  static uploadUserAvatar(userId, imageFile) {
    return JhaFetch.fetch({
      api: SentryApi.userImageFactory(),
      method: 'PUT',
      urlArguments: { userId },
      body: imageFile,
      sendBodyAsIs: true
    });
  }
  static deleteAdminAvatar(userId) {
    return JhaFetch.fetch({
      api: SentryApi.userImageFactory(),
      method: 'DELETE',
      urlArguments: { userId }
    });
  }
  static adminAvatarExists(userId) {
    return JhaFetch.fetch({
      api: SentryApi.userImageExistsFactory(),
      method: 'GET',
      urlArguments: { userId },
      ignoreCache: true
    });
  }
  static deleteUserAvatar(userId) {
    return JhaFetch.fetch({
      api: UsersApi.deleteAvatar(),
      method: 'DELETE',
      urlArguments: { userId }
    });
  }
  static userAvatarExists(userId) {
    return JhaFetch.fetch({
      api: UsersApi.avatarExists(),
      method: 'GET',
      urlArguments: { userId },
      ignoreCache: true
    });
  }

  static createJhidUsers(body) {
    return JhaFetch.fetch({
      api: UsersApi.jhidUsers(),
      method: 'POST',
      body,
    });
  }

  static getJhidDomain(domain) {
    return JhaFetch.fetch({
      api: UsersApi.jhidDomains(),
      method: 'GET',
      urlArguments: {domain},
    });
  }

  static getJhidUserInstitutions(userId) {
    return JhaFetch.fetch({
      api: UsersApi.jhidUserInstitutions(),
      method: 'GET',
      urlArguments: {userId},
    });
  }

  static getJhidUserGroups(userId) {
    return JhaFetch.fetch({
      api: UsersApi.jhidUserGroups(),
      method: 'GET',
      urlArguments: {userId},
    });
  }

  static sendJhidPasswordResetAsAdmin(body) {
    return JhaFetch.fetch({
      api: UsersApi.jhidPasswordResetAsAdmin(),
      method: 'POST',
      body,
    });
  }
}
export default UsersController;
