import {html} from 'lit-element';
import '@banno/jha-wc/dist/migration/icons/jha-icon-people.js';
import '@banno/jha-wc/dist/migration/icons/jha-icon-support.js';
import '@banno/jha-wc/dist/migration/icons/jha-icon-marketing.js';
import '@banno/jha-wc/dist/migration/icons/jha-icon-monitor.js';
import '@banno/jha-wc/dist/migration/icons/jha-icon-browser.js';
import '@banno/jha-wc/dist/migration/icons/jha-icon-history.js';
import '@banno/jha-wc/dist/migration/icons/jha-icon-assets.js';
import '@banno/jha-wc/dist/migration/icons/jha-icon-reports.js';
import '@banno/jha-wc/dist/migration/icons/jha-icon-settings.js';
import '@banno/jha-wc/dist/migration/icons/jha-icon-help.js';
import '@banno/jha-wc/dist/migration/icons/jha-icon-plus.js';
import '@banno/jha-wc/dist/migration/icons/jha-icon-code.js';
import '@banno/jha-wc/dist/migration/icons/jha-icon-fi.js';
import '@banno/jha-wc/dist/migration/icons/jha-icon-fingerprint.js';
import '@banno/jha-wc/dist/migration/icons/jha-icon-tools.js';

const CORE_NAVIGATION = {
  projects: [
    {
      displayName: 'People',
      icon: 'PROJECT_PEOPLE',
      name: 'people',
      helpLink: '/people/',
      subNav: [
        {
          displayName: 'Dashboard',
          route: 'dashboard',
        },
        {
          displayName: 'Users',
          route: 'users',
          altActiveRoutes: ['users-cims'],
        },
        {
          displayName: 'Messages',
          route: 'messages',
          altActiveRoutes: 'messages/create',
        },
        {
          displayName: 'ACH',
          route: 'ach',
          isCreditUnion: true,
          institutionAbility: 'ach',
          privilegeRequired: {
            application: 'people',
            resource: 'ach',
            privilege: 'update',
          },
          subNav: [
            {
              displayName: 'Management',
              route: 'ach/management'
            },
            {
              displayName: 'Companies',
              route: 'ach/companies'
            }
          ]
        },
        {
          displayName: 'Security',
          route: 'security',
          featureFlag: 'default2faPhase1',
          subNav: [
            {
              displayName: '2-step verification',
              route: 'security/2fa',
              disableForAgg: true,
            },
            {
              displayName: 'High risk action methods',
              route: 'security/high-risk-methods',
              institutionAbility: 'default_2fa_enabled',
              disableForAgg: true,
            },
            {
              displayName: 'High risk action blocking',
              route: 'security/high-risk-blocking',
              institutionAbility: 'hra_device_blocking',
              disableForAgg: true,
            },
            {
              displayName: 'Initial 2-step enrollment',
              route: 'security/initial-enrollment',
              disableForAgg: true,
            },
          ],
        },
        {
          displayName: 'Settings',
          route: 'settings',
          subNav: [
            {
              displayName: 'General',
              route: 'settings/general',
            },
            {
              displayName: 'Accounts',
              featureFlag: 'accountConfiguration',
              route: 'settings/accounts',
            },
            {
              displayName: 'ACH',
              route: 'settings/ach',
              isCreditUnion: true,
              institutionAbility: 'ach',
              privilegeRequired: {
                application: 'people',
                resource: 'ach',
                privilege: 'update',
              },
            },
            {
              displayName: 'App keys',
              route: 'settings/app-keys',
              featureFlag: 'androidApiKeySettings',
              privilegeRequired: {
                application: 'people',
                resource: '*',
                privilege: 'update',
              }
            },
            {
              displayName: 'Dashboard configuration',
              route: 'settings/dashboard',
              privilegeRequired: {
                application: 'people',
                resource: 'dashboard',
                privilege: 'update',
              },
            },
            {
              displayName: 'Digital terms of use',
              route: 'settings/terms-of-use',
            },
            {
              displayName: 'Documents',
              institutionAbility: 'documents',
              route: 'settings/documents',
            },
            {
              displayName: 'Enrollment',
              institutionAbility: 'selfEnrollment',
              route: 'settings/enrollment-restrictions',
            },
            {
              displayName: 'External applications',
              route: 'settings/external-apps',
              altActiveRoutes: ['settings/edit-external-app', 'settings/create-external-app'],
            },
            {
              displayName: 'FDIC compliance',
              institutionAbility: 'account_fdic_config',
              route: 'settings/fdic-compliance',
            },
            {
              displayName: 'NuDetect',
              route: 'settings/nudetect',
              featureFlag: 'nudetect',
              hasNudetect: true
            },
            {
              displayName: 'Offline mode',
              institutionAbility: 'coreOfflineSupport',
              route: 'settings/offline-mode',
            },
            {
              displayName: 'Permissions segments',
              route: 'settings/segmented-permissions',
              altActiveRoutes: ['settings/view-segment', 'settings/assign-permissions'],
              featureFlag: 'segmentedPermissions',
            },
            {
              displayName: 'RDC',
              enterpriseFlag: {group: 'rdc', flag:'rdcEnsenta'},
              route: 'settings/rdc',
            },
            {
              displayName: 'Password reset',
              hasBannoOnline: true,
              route: 'settings/reset-password-expiration',
            },
            {
              displayName: 'Security',
              route: 'settings/security',
              disableForAgg: true,
              featureFlagOff: 'default2faPhase1',
            },
            {
              displayName: 'Stop payments',
              route: 'settings/stop-payments',
              institutionAbility: 'stopPayments',
            },
            {
              displayName: 'Transfers',
              institutionAbility: 'schedulableTransfers',
              institutionOverride: 'externalTransfers',
              route: 'settings/transfers',
            },
            {
              displayName: 'Themes',
              route: 'settings/themes',
            },
            {
              displayName: 'Third party loans',
              route: 'settings/third-party-loans',
              isCreditUnion: true,
              featureFlag: 'thirdPartyLoansSettings'
            },
            {
              displayName: 'Zelle',
              route: 'settings/zelle',
              featureFlag: 'zelleSettings',
              institutionAbility: 'zelle',
              institutionAbilityExcludeList: [undefined, 'None'],
            },
          ],
        },
      ],
    },
    {
      displayName: 'Identity',
      icon: 'PROJECT_IDENTITY',
      name: 'identity',
      route: 'identity',
      privilegeRequired: {
        application: 'identity',
        resource: '*',
      },
      enterpriseFlag: {group: 'featureFlags', flag: 'identityApp'},
      hideSubnavOnDesktop: true,
      subNav: [{
        displayName: 'Users',
        route: 'users',
        hideSubnavOnDesktop: true,
      }, {
        displayName: 'Settings',
        route: 'settings',
      }],
    },
    {
      displayName: 'Support',
      icon: 'PROJECT_SUPPORT',
      name: 'mailboxes',
      route: 'support',
      helpLink: '/support/',
      privilegeRequired: {
        application: 'mailboxes',
        resource: 'conversations',
      },
      hideSubnavOnDesktop: true,
      showFormsOnlyAccess: true,
      subNav: [
        {
          displayName: 'Inbox',
          route: 'conversations',
          hideSubnavOnDesktop: true,
        },
        {
          header: true,
          text: 'Settings',
          headerGroup: 'support-settings',
        },
        {
          displayName: 'Support hours',
          route: 'settings/support-hours',
          headerGroup: 'support-settings',
          hideFormsOnlyAccess: true,
          privilegeRequired: {
            application: 'mailboxes',
            resource: 'settings',
          },
        },
        {
          displayName: 'Holidays and closures',
          route: 'settings/support-closures',
          headerGroup: 'support-settings',
          hideFormsOnlyAccess: true,
          privilegeRequired: {
            application: 'mailboxes',
            resource: 'settings',
          },
        },
        {
          displayName: 'Email notifications',
          route: 'settings/email-notifications',
          headerGroup: 'support-settings',
          privilegeRequired: {
            application: 'mailboxes',
            resource: 'settings',
          },
        },
        {
          displayName: 'Saved replies',
          route: 'settings/saved-replies',
          headerGroup: 'support-settings',
          hideFormsOnlyAccess: true,
          privilegeRequired: {
            application: 'mailboxes',
            resource: 'settings',
          },
        },
        {
          displayName: 'Rule management',
          route: 'settings/rule-management',
          headerGroup: 'support-settings',
          privilegeRequired: {
            application: 'mailboxes',
            resource: 'settings',
          },
        },
        {
          displayName: 'Tags',
          route: 'settings/tags',
          headerGroup: 'support-settings',
          privilegeRequired: {
            application: 'mailboxes',
            resource: 'settings',
          },
        },
      ],
    },
    {
      displayName: 'Marketing',
      icon: 'PROJECT_MARKETING',
      name: 'marketing',
      helpLink: '/marketing/',
      subNav: [
        {
          displayName: 'Dashboard',
          route: 'overview',
        },
        {
          displayName: 'Campaigns',
          route: 'campaigns',
          altActiveRoutes: ['campaign', 'digital-banking-campaign', 'web-campaign'],
        },
        {
          displayName: 'Audience',
          route: 'audience',
          enterpriseConfig: 'marketing.marketingWebPermission',
        },
        {
          displayName: 'Segments',
          route: 'segments',
          enterpriseConfig: 'marketing.marketingDigitalPermission',
        },
        {
          displayName: 'Templates',
          route: 'templates',
          privilegeRequired: {
            application: 'marketing',
            resource: 'templates',
          },
          enterpriseConfig: ['marketing.adDevToolsEnabled'],
        },
        {
          displayName: 'Settings',
          route: 'setup',
          enterpriseConfig: 'marketing.marketingWebPermission',
        },
      ],
    },
    {
      displayName: 'Monitor',
      icon: 'PROJECT_MONITOR',
      name: 'monitor',
      helpLink: '/monitor/',
      subNav: [
        {
          displayName: 'Monitoring',
          route: 'monitors',
        },
        {
          displayName: 'Maintenance',
          route: 'maintenance',
        },
      ],
    },
    {
      displayName: 'Content',
      icon: 'PROJECT_CONTENT',
      name: 'cms',
      helpLink: '/cms/',
      subNav: [
        {
          displayName: 'Pages',
          route: 'pages',
          privilegeRequired: {
            application: 'cms',
            resource: 'pages',
          },
          siteFeature: 'PAGES',
        },
        {
          displayName: 'Menus',
          route: 'menus',
          privilegeRequired: {
            application: 'cms',
            resource: 'menu_builder',
          },
          siteFeature: 'MENU_BUILDER',
        },
        {
          displayName: 'Assets',
          route: 'assets',
          privilegeRequired: {
            application: 'cms',
            resource: 'assets',
          },
          siteFeature: 'ASSETS',
        },
        {
          displayName: 'Forms',
          route: 'forms/all',
          privilegeRequired: {
            application: 'cms',
            resource: 'forms',
          },
          siteFeature: 'FORMS',
        },
        {
          displayName: 'Rates',
          route: 'rates',
          privilegeRequired: {
            application: 'cms',
            resource: 'treasury',
          },
          siteFeature: 'TREASURY',
        },
        {
          displayName: 'Locations',
          route: 'locations',
          privilegeRequired: {
            application: 'cms',
            resource: 'location_manager',
          },
          siteFeature: 'LOCATION_MANAGER',
        },
        {
          displayName: 'Members only',
          route: 'members',
          privilegeRequired: {
            application: 'cms',
            resource: 'members_only',
          },
          siteFeature: 'MEMBERS_ONLY',
        },
        {
          displayName: 'Form builder',
          route: 'form-list',
          adminsCanView: true,
          privilegeRequired: {
            application: 'forms',
          },
          alwaysShowWithApp: 'forms',
          ignoreSelectedSite: true
        },
        {
          displayName: 'Settings',
          route: 'site-settings',
          siteRequired: true,
          subNav: [
            {
              displayName: 'General',
              route: 'site-settings/general',
              privilegeRequired: {
                application: 'cms',
                resource: 'site_settings',
              },
            },
            {
              displayName: 'Disclaimers',
              route: 'site-settings/disclaimers',
              privilegeRequired: {
                application: 'cms',
                resource: 'pages',
              },
            },
            {
              displayName: 'Redirects',
              route: 'site-settings/redirects',
              privilegeRequired: [
                {
                  application: 'cms',
                  resource: 'assets',
                },
                {
                  application: 'cms',
                  resource: 'pages',
                },
              ],
            },
            {
              displayName: 'Shared content',
              route: 'site-settings/shared-content',
              privilegeRequired: {
                application: 'cms',
                resource: 'pages',
              },
              siteFeatureConfig: 'showSharedContentFeature',
            },
            {
              displayName: 'Templates',
              route: 'site-settings/templates',
              privilegeRequired: {
                application: 'cms',
                resource: 'dev_tools',
                privilege: 'update',
              }
            },
            {
              header: true,
              text: 'Admin',
              headerGroup: 'content-admin',
            },
            {
              displayName: 'Admin settings',
              adminSettingsRoute: true,
              route: '',
              target: '_blank',
              internalOnly: true,
              headerGroup: 'content-admin',
            },
          ],
        },
      ],
    },
    {
      displayName: 'Content',
      icon: 'PROJECT_CONTENT',
      name: 'forms',
      route: 'cms',
      privilegeRequired: {
        application: 'forms',
        resource: 'admin',
      },
      subNav: [
        {
          displayName: 'Form builder',
          route: 'form-list',
        },
      ],
    },
    {
      displayName: 'Platform',
      icon: 'PROJECT_BANKING',
      name: 'platform',
      route: 'back-office',
      enterpriseFlag: {group: 'featureFlags', flag: 'newPlatformNav'},
      privilegeRequired: {
        application: 'platform',
        resource: '*',
      },
      hardRedirect: true,
    },
    {
      displayName: 'Activity',
      icon: 'PROJECT_ACTIVITY',
      name: 'history',
      route: 'history',
      secondary: true,
      privilegeRequired: {
        application: 'history',
        resource: 'history',
      },
      hideSubnavOnDesktop: true,
    },
    {
      displayName: 'Assets',
      icon: 'PROJECT_ASSETS',
      name: 'assets',
      route: 'assets',
      secondary: true,
      hideSubnavOnDesktop: true,
    },
    {
      displayName: 'Internal tooling',
      icon: 'PROJECT_TOOLING',
      name: 'tooling',
      route: 'tooling',
      internalOnly: true,
      enterpriseFlag: {group: 'featureFlags', flag: 'configsToToolingBanner'},
      secondary: true,
      hardRedirect: true,
    },
    {
      displayName: 'Reports',
      icon: 'PROJECT_REPORTS',
      name: 'reports',
      route: 'reports',
      secondary: true,
      helpLink: '/reports/',
      subNav: [
        {
          header: true,
          text: 'Engagement',
          headerGroup: 'reports-engagement',
        },
        {
          displayName: 'Activity and app usage',
          route: 'activity-and-app-usage',
          headerGroup: 'reports-engagement',
        },
        {
          header: true,
          text: 'Move money',
          headerGroup: 'reports-move-money',
        },
        {
          displayName: 'Bill pay',
          route: 'bill-pay',
          institutionAbility: ['billPay', 'p2pEnabled'],
          headerGroup: 'reports-move-money',
        },
        {
          displayName: 'Transfers',
          route: 'transfers',
          institutionAbility: ['schedulableTransfers', 'accountToAccount'],
          headerGroup: 'reports-move-money',
        },
        {
          displayName: 'RDC',
          route: 'rdc',
          institutionAbility: ['rdc', 'rdcOnboardingEnabled'],
          headerGroup: 'reports-move-money',
        },
        {
          header: true,
          text: 'Support',
          headerGroup: 'reports-support',
        },
        {
          displayName: 'Agents',
          route: 'agents',
          institutionAbility: 'conversations',
          headerGroup: 'reports-support',
        },
        {
          displayName: 'Conversations',
          route: 'conversations',
          institutionAbility: 'conversations',
          headerGroup: 'reports-support',
        },
        {
          header: true,
          text: 'Exports',
          headerGroup: 'reports-exports',
        },
        {
          displayName: 'Requested',
          route: 'exports',
          headerGroup: 'reports-exports',
        },
        {
          displayName: '+ Create request',
          route: 'exports/create',
          headerGroup: 'reports-exports',
        },
      ],
    },
    {
      displayName: 'Users & Groups',
      icon: 'PROJECT_SETTINGS',
      name: 'settings',
      route: 'settings',
      secondary: true,
      helpLink: '/settings/',
      subNav: [
        {
          header: true,
          text: 'Users',
          headerGroup: 'settings-users',
        },
        {
          displayName: 'User list',
          route: 'users',
          privilegeRequired: {
            application: 'settings',
            resource: 'users',
          },
          headerGroup: 'settings-users',
        },
        {
          displayName: '+ Invite user',
          route: 'users/invite',
          headerGroup: 'settings-users',
          privilegeRequired: {
            application: 'settings',
            resource: 'users',
            privilege: 'create'
          }
        },
        {
          header: true,
          text: 'Groups',
          headerGroup: 'settings-groups',
        },
        {
          displayName: 'Group list',
          route: 'groups',
          privilegeRequired: {
            application: 'settings',
            resource: 'groups',
          },
          headerGroup: 'settings-groups',
        },
        {
          displayName: '+ Create group',
          route: 'groups/new',
          headerGroup: 'settings-groups',
          privilegeRequired: {
            application: 'settings',
            resource: 'groups',
            privilege: 'create'
          }
        },
        {
          header: true,
          text: 'Developers',
          headerGroup: 'settings-developers',
          featureFlag: 'platformApiCredCreation'
        },
        {
          displayName: 'External apps',
          route: 'developers',
          privilegeRequired: {
            application: 'settings',
            resource: 'developers',
          },
          headerGroup: 'settings-developers',
          featureFlag: 'platformApiCredCreation'
        },
        {
          displayName: '+ Create external app',
          route: 'developers/create',
          headerGroup: 'settings-developers',
          privilegeRequired: {
            application: 'settings',
            resource: 'developers',
            privilege: 'create'
          },
          featureFlag: 'platformApiCredCreation'
        }
      ],
    },
    {
      displayName: 'Enterprise schemas',
      icon: 'PROJECT_SCHEMAS',
      name: 'schemas',
      route: 'schemas',
      secondary: true,
      internalOnly: true,
      subNav: [{
        displayName: 'Defaults',
        route: 'defaults',
        internalOnly: true
      }]
    }, {
      displayName: 'Status',
      icon: 'PROJECT_STATUS',
      name: 'status',
      route: 'status',
      enterpriseFlag: {group: 'featureFlags', flag: 'statusApp'},
      hideSubnavOnDesktop: true,
      secondary: true,
      subNav: [{
        displayName: 'Dashboard',
        route: '',
        hideSubnavOnDesktop: true,
      }],
    },
  ],
};

const NAVIGATION_ICONS = {
  PROJECT_PEOPLE: html`
    <jha-wc-icon-people icon></jha-wc-icon-people>`,
  PROJECT_IDENTITY: html`
    <jha-wc-icon-fingerprint icon></jha-wc-icon-fingerprint>`,
  PROJECT_SUPPORT: html`
    <jha-wc-icon-support icon></jha-wc-icon-support>`,
  PROJECT_MARKETING: html`
    <jha-wc-icon-marketing icon></jha-wc-icon-marketing>`,
  PROJECT_MONITOR: html`
    <jha-wc-icon-monitor icon></jha-wc-icon-monitor>`,
  PROJECT_CONTENT: html`
    <jha-wc-icon-browser icon></jha-wc-icon-browser>`,
  PROJECT_ACTIVITY: html`
    <jha-wc-icon-history small></jha-wc-icon-history>`,
  PROJECT_ASSETS: html`
    <jha-wc-icon-assets small></jha-wc-icon-assets>`,
  PROJECT_TOOLING: html`
    <jha-wc-icon-tools small></jha-wc-icon-tools>`,
  PROJECT_REPORTS: html`
    <jha-wc-icon-reports small></jha-wc-icon-reports>`,
  PROJECT_SETTINGS: html`
    <jha-wc-icon-settings small></jha-wc-icon-settings>`,
  PROJECT_SCHEMAS: html`
    <jha-wc-icon-code small></jha-wc-icon-code>`,
  PROJECT_HELP: html`
    <jha-wc-icon-help small></jha-wc-icon-help>`,
  PROJECT_BANKING: html`
    <jha-wc-icon-fi icon></jha-wc-icon-fi>`,
  PROJECT_STATUS: html`
    <jha-wc-icon-monitor small></jha-wc-icon-monitor>`,
  ADD: html`
    <jha-wc-icon-plus></jha-wc-icon-plus>`,
};

const HELP_LINK = {
  displayName: 'Banno Knowledge Base',
  displayNameMobile: 'Knowledge Base',
  icon: 'PROJECT_HELP',
  name: 'help',
  secondary: true,
  target: '_blank',
};

const getCopyOfCoreNav = () => JSON.parse(JSON.stringify(CORE_NAVIGATION));

const PEOPLE_USER_NAVIGATION = {
  Overview: {
    route: '',
  },
  Accounts: {
    route: 'accounts',
  },
  Activity: {
    route: 'activity',
  },
  Messages: {
    route: 'messages',
  },
  Permissions: {
    route: 'settings',
  },
  Security: {
    route: 'security',
  },
  Config: {
    route: 'config/abilities',
    internalOnly: true,
  },
};

const PEOPLE_CU_ORG_USER_NAVIGATION = {
  Overview: {
    route: '',
  },
  Accounts: {
    route: 'accounts',
  },
  Wires: {
    route: 'wires',
    enterpriseFlag: {group: 'featureFlags', flag: 'peopleViewWires'},
  },
  Activity: {
    route: 'activity',
  },
  Messages: {
    route: 'messages',
  },
  Permissions: {
    route: 'settings',
    altActiveRoutes: ['settings/accounts', 'settings/companies', 'settings/permissions'],
  },
  Security: {
    route: 'security',
  },
  Config: {
    route: 'config/abilities',
    internalOnly: true,
  },
};

const PEOPLE_USER_NAVIGATION_CIMS = {
  Overview: {
    route: 'overview-cims'
  },
  Activity: {
    route: 'activity-cims',
  },
  Security: {
    route: 'security-cims',
  }
};

const SETTINGS_USER_NAVIGATION = {
  Permissions: {
    tab: 'permissions',
  },
  Notifications: {
    tab: 'notifications',
  },
  Security: {
    tab: 'security',
    loggedInUserOnly: true,
  },
};

const PEOPLE_ORGANIZATION_NAVIGATION = {
  Overview: {
    route: ''
  },
  Users: {
    route: 'users'
  },
  ACH: {
    route: 'ach',
    isCreditUnion: true,
    institutionAbility: 'ach',
    privilegeRequired: {
      application: 'people',
      resource: 'ach',
      privilege: 'read',
    },
  },
  Activity: {
    route: 'activity',
  },
  Permissions: {
    route: 'settings',
    altActiveRoutes: ['accounts', 'settings/companies', 'settings/permissions'],
  },
  Config: {
    route: 'config',
    internalOnly: true,
  }
};

export {
  NAVIGATION_ICONS,
  getCopyOfCoreNav,
  HELP_LINK,
  PEOPLE_USER_NAVIGATION,
  PEOPLE_CU_ORG_USER_NAVIGATION,
  PEOPLE_USER_NAVIGATION_CIMS,
  SETTINGS_USER_NAVIGATION,
  PEOPLE_ORGANIZATION_NAVIGATION,
};
