import JhaFetch from '../services/jha-fetch.js';
const EnvironmentConfigController = {
  getConfig() {
    return JhaFetch.fetch({
      api: { url: '/a/api/config' },
      method: 'GET',
    }).then(data => {
      // The platform-server returns a larger config object and the actual file is placed in a "paths" property.
      return data.paths || {};
    });
  },
};
export default EnvironmentConfigController;
