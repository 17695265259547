/* istanbul ignore file */
import { css } from 'lit-element';

export const fillStyles = css`
  .u-fill-primary {
    fill: #006ee4;
    fill: var(--jha-color-primary, #006ee4);
  }

  .u-fill-success {
    fill: #4caf50;
    fill: var(--jha-color-success, #4caf50);
  }

  .u-fill-danger {
    fill: #db2012;
    fill: var(--jha-color-danger, #db2012);
  }

  .u-fill-warning {
    fill: #fca902;
    fill: var(--jha-color-warning, #fca902);
  }

  .u-fill-dark {
    fill: #191919;
    fill: var(--jha-color-dark, #191919);
  }

  .u-fill-base {
    fill: #707070;
    fill: var(--jha-text-base, #707070);
  }

  .u-fill-muted {
    fill: #b0b0b0;
    fill: var(--jha-color-muted, #b0b0b0);
  }

  .u-fill-light {
    fill: #8c989f;
    fill: var(--jha-color-light, #8c989f);
  }

  .u-fill-neutral {
    fill: #8d99a0;
    fill: var(--jha-color-neutral, #8d99a0);
  }
`;
