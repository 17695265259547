/* jshint ignore:start  */
import JhaFetch from '@banno/platform-ux-shared/services/jha-fetch.js';
/* jshint ignore:end */
const defaultSchema = 'master-1';
const url = (schema, institutionId) => `/a/conversations-configs/api/enterprise/schemas/${schema}/institutions/${institutionId}`;
const schemaURL = schema => `/a/conversations-configs/api/enterprise/schemas/${schema}`;

export default class EnterpriseConfigService {
  static getSchemas() {
    const config = {
      api: {
        url: `/a/conversations-configs/api/enterprise/schemas`
      },
      method: 'GET',
      ignoreCache: true
    };
    return JhaFetch.fetch(config);
  }
  static getInstitutionsValues(schema = defaultSchema) {
    const config = {
      api: {
        url: `/a/conversations-configs/api/enterprise/schemas/${schema}/institutions`
      },
      method: 'GET',
      ignoreCache: true
    };
    return JhaFetch.fetch(config);
  }
  static getRecursiveInstitution(institutionId, schema = defaultSchema) {
    const config = {
      api: {
        url: url(schema, institutionId) + '/recursive'
      },
      method: 'GET',
      ignoreCache: true
    };
    return JhaFetch.fetch(config);
  }
  static updateInstitution(institutionId, body, schema = defaultSchema) {
    const config = {
      api: {
        url: url(schema, institutionId)
      },
      method: 'PUT',
      body
    };
    return JhaFetch.fetch(config);
  }
  static patchInstitution(institutionId, body, schema = defaultSchema) {
    const config = {
      api: {
        url: url(schema, institutionId)
      },
      method: 'PATCH',
      body
    };
    return JhaFetch.fetch(config);
  }
  static getSchema(schema = defaultSchema) {
    const config = {
      api: {
        url: schemaURL(schema)
      },
      method: 'GET',
      ignoreCache: true
    };
    return JhaFetch.fetch(config);
  }
  static patchSchema(schema = defaultSchema, body) {
    const config = {
      api: {
        url: schemaURL(schema)
      },
      method: 'PATCH',
      body
    }
    return JhaFetch.fetch(config);
  }
  static updateGroups(body, schema = defaultSchema) {
    const config = {
      api: {
        url: schemaURL(schema)
      },
      method: 'PATCH',
      body
    };
    return JhaFetch.fetch(config);
  }
  static getUser(institutionId, userId, schema = defaultSchema) {
    const config = {
      api: {
        url: url(schema, institutionId) + `/users/${userId}/recursive`
      },
      method: 'GET',
      ignoreCache: true
    };
    return JhaFetch.fetch(config);
  }
  static patchUser(institutionId, userId, body, schema = defaultSchema) {
    const config = {
      api: {
        url: url(schema, institutionId) + `/users/${userId}`
      },
      method: 'PATCH',
      body
    };
    return JhaFetch.fetch(config);
  }
}
