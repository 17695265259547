import {LitElement, html, css} from 'lit-element';
import {jhaStyles} from '@banno/platform-ux-shared/styles/jha-styles';

import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-menu.js';

const styles = css`
  :host([dashboard]) {
    background-color: var(--jha-text-theme);
  }
  :host([dashboard]) jha-icon-menu {
    fill: var(--jha-text-white);
  }
`;

class JhaEnterpriseNavMenuButton extends LitElement {
  static get styles() {
    return [jhaStyles, styles];
  }

  static get properties() {
    return {
      dashboard: {type: Boolean, reflectToAttribute: true},
    };
  }

  _toggleMobileNav() {
    this.dispatchEvent(new CustomEvent('toggle-mobile-nav', {
      bubbles: true,
      composed: true,
    }));
  }

  render() {
    return html`
      <button @click="${() => this._toggleMobileNav()}">
        <jha-icon-menu></jha-icon-menu>
      </button>
    `;
  }
}

export {styles as JhaEnterpriseNavMenuButtonStyles};

customElements.define('jha-enterprise-nav-menu-button', JhaEnterpriseNavMenuButton); // eslint-disable-line @banno/ux/custom-elements-define
export default JhaEnterpriseNavMenuButton;
