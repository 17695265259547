import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import '@banno/platform-ux-shared/components/polymer3/jha/buttons/jha-button.js';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-close.js';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-warning.js';
import UsersController from '@banno/platform-ux-shared/controllers/users-controller.js';

/**
 * @polymer
 * @customElement
 */
export default class DeleteAvatarDialogElement extends PolymerElement {
  static get is() { return 'delete-avatar-dialog'; }
  static get properties() {
    return {
      userId: String,
      avatarReplacement: String,
      identifier: String,
      isEnterpriseUser: {
        type: Boolean,
        value: false
      }
    };
  }

  _close() {
    this.dispatchEvent(new CustomEvent('closeModal', {
      bubbles: true,
      composed: true
    }));
  }

  async _remove() {
    try {
      this._error = false;
      this._loading = true;
      if (this.isEnterpriseUser) {
        await UsersController.deleteAdminAvatar(this.userId);
      } else {
        await UsersController.deleteUserAvatar(this.userId);
      }
      window.dispatchEvent(new CustomEvent('avatarRemoved', {
        bubbles: true,
        composed: true
      }));
      this._close();
    } catch (err) {
      this._error = true;
    }
    this._loading = false;
  }

  static get template() {
    return html`
      <style>
        :host {
          display: block;
        }
        header {
          padding: 32px;
          border-bottom: 1px solid var(--jha-border-color);
          border-top-left-radius: 1px;
          border-top-right-radius: 1px;
          text-align: center;
          position: relative;
          font-size: 18px;
          font-weight: 400;
          color: var(--jha-text-dark, #455564);
        }
        jha-button[icon] {
          --jha-button-line-height: 12px;
          position: absolute;
          top: 30%;
          right: 20px;
          opacity: 0.6;
          transition: opacity 0.3s cubic-bezier(0.1,.5,.1,1);
        }
        jha-button[icon]:hover {
          opacity: 1;
        }
        jha-icon-close {
          width: 18px;
          height: 18px;
          fill: var(--jha-color-light, #8c989f);
        }
        jha-well {
          display: flex;
          align-items: center;
        }
        jha-icon-warning {
          fill: var(--jha-text-base, #6b757b);
          margin-right: 8px;
        }
        jha-well > div {
          flex: 1;
          color: var(--jha-text-base, #6b757b);
        }
        article {
          color: var(--jha-text-base, #6b757b);
          padding: 32px;
        }
        p {
          margin: 0;
        }
        footer {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          border-bottom-left-radius: 1px;
          border-bottom-right-radius: 1px;
          border-top: 1px solid var(--jha-border-color);
          padding: 32px;
        }
        jha-button[link] {
          --jha-button-link-text: var(--jha-text-base);
          margin-left: 24px;
        }
      </style>
      <header>
        <div>Remove profile photo</div>
        <jha-button type="button" on-click="_close" sync icon>
          <jha-icon-close></jha-icon-close>
        </jha-button>
      </header>
      <template is="dom-if" if="[[_error]]">
        <jha-well danger>
          <jha-icon-warning></jha-icon-warning>
          <div>Failed to remove profile photo.</div>
        </jha-well>
      </template>
      <article>
        <p>Are you sure you want to remove [[identifier]] profile photo? It will be replaced with [[avatarReplacement]].</p>
      </article>
      <footer>
        <jha-button type="button" on-click="_remove" danger is-loading="[[_loading]]">Remove</jha-button>
        <jha-button type="button" on-click="_close" sync link>Nevermind</jha-button>
      </footer>
    `;
  }
}
customElements.define(DeleteAvatarDialogElement.is, DeleteAvatarDialogElement);
