import {LitElement, html, css} from 'lit-element';
import {jhaStyles} from '@banno/platform-ux-shared/styles/jha-styles';
import '@banno/platform-ux-shared/components/polymer3/jha/buttons/jha-button';
import '@banno/platform-ux-shared/components/polymer3/jha/dropdowns/jha-dropdown';
import '@banno/platform-ux-shared/components/polymer3/jha/dropdowns/jha-dropdown-toggle';
import '@banno/platform-ux-shared/components/polymer3/jha/dropdowns/jha-dropdown-menu';
import '@banno/platform-ux-shared/components/polymer3/jha/dropdowns/jha-dropdown-menu-item';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-circle-user';

const styles = css`
  :host {
    display: block;
    font-size: 13px;
    background-color: transparent;
  }
  jha-dropdown {
    position: var(--user-details-jha-dropdown-position, relative);
  }
  jha-avatar {
    margin-right: -5px;
  }
  *[icon] {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
  a {
    display: flex;
    align-items: center;
    color: var(--jha-text-dark);
    line-height: 18px;
    padding: 10px;
  }
`;

/**
 * @polymer
 * @customElement
 * @extends {LitElement}
 */
class UserAvatarDropdown extends LitElement {
  static get styles() {
    return [jhaStyles, styles];
  }

  static get properties() {
    return {
      loggedInUserId: {type: String},
      selectedInstitutionShortId: {type: String},
      avatarUri: {type: String},
      avatarUriFull: {type: String},
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.boundUpdateAvatar_ = this._updateAvatarImg.bind(this);
    window.addEventListener('update-avatar', this.boundUpdateAvatar_);
    window.addEventListener('avatarRemoved', this.boundUpdateAvatar_);
    this.avatarUri = `/a/sentry/api/users/${this.loggedInUserId}/profile-image`;
    this._updateAvatarImg();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('update-avatar', this.boundUpdateAvatar_);
    window.removeEventListener('avatarRemoved', this.boundUpdateAvatar_);
  }

  _goToProfile() {
    window.location.href = '/a/settings/' + this.selectedInstitutionShortId + '/users/' + this.loggedInUserId;
  }

  _goToLogout() {
    window.location.href = '/a/login/logout';
  }

  _updateAvatarImg() {
    this.avatarUriFull = this._getAvatarFullSrc();
    this.requestUpdate();
  }

  _getAvatarFullSrc() {
    let src;
    if (this.avatarUri) {
      const timestamp = new Date().getTime();
      src = this.avatarUri + '?' + timestamp;
    } else {
      src = '/a/consumer/api/node/profile-photo/0';
    }
    return src;
  }

  render() {
    const userMenuLinks = [
      {
        click: this._goToProfile,
        icon: html`<jha-icon-circle-user icon></jha-icon-circle-user>`,
        text: 'View profile',
      },
      {
        click: this._goToLogout,
        icon: html`<jha-icon-logout icon></jha-icon-logout>`,
        text: 'Sign out',
      },
    ];
    const avatarStyle = `background-image: url(${this.avatarUriFull})`;
    return html`
      <jha-dropdown>
        <jha-dropdown-toggle slot="toggle">
          <jha-avatar small="" style="${avatarStyle}"></jha-avatar>
        </jha-dropdown-toggle>
        <jha-dropdown-menu>
          ${userMenuLinks.map((link) => html`
            <jha-dropdown-menu-item @click=${link.click}>
              ${link.icon}
              ${link.text}
            </jha-dropdown-menu-item>
          `)}
        </jha-dropdown-menu>
      </jha-dropdown>
    `;
  }
}

customElements.define('user-avatar-dropdown', UserAvatarDropdown); // eslint-disable-line @banno/ux/custom-elements-define
export default UserAvatarDropdown;
