import {LitElement, html, css} from 'lit-element';
import {jhaStyles} from '@banno/platform-ux-shared/styles/jha-styles';
import {NAVIGATION_ICONS} from './helpers/core-navigation';
import store from '@banno/platform-ux-shared/session-storage/store.js';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-popout';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-chevron-left';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-chevron-down';
import '../jha-enterprise-fi-name';

const styles = css`
  :host {
    display: block;
    height: 100vh;
    overflow-y: auto;
  }
  nav {
    width: 220px;
    padding: 8px 16px 32px 8px;
    position: relative;
  }
  nav::after {
    content: '';
    display: block;
    width: 18px;
    height: 100%;
    min-height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.06) 100%);
  }
  jha-enterprise-fi-name {
    margin-bottom: 4px;
  }
  jha-enterprise-fi-name:not([fi-display-only]) {
    height: 46px;
  }
  header {
    display: flex;
    margin-bottom: 13px;
  }
  header[mobile] {
    display: none;
  }
  h3 {
    flex: 1;
    color: var(--jha-text-dark);
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    margin: 4px 0 4px 8px;
  }
  a,
  .accordion-toggle {
    color: var(--jha-text-dark);
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-content: center;
    margin-bottom: 8px;
  }
  a:hover,
  .accordion-toggle:hover {
    color: var(--jha-text-dark);
    background-color: var(--jha-color-gray-light);
    font-weight: 400;
    border-radius: 6px;
    text-decoration: none;
  }
  a[active],
  a:active {
    color: var(--jha-text-theme);
    font-weight: 500;
    background-color: rgba(1, 111, 230, 0.1);
    border-radius: 6px;
  }
  a[active]:hover {
    color: var(--jha-text-dark);
    background-color: var(--jha-color-gray-light);
  }
  *[sub-nav] {
    display: flex;
    align-items: center;
    width: 100%;
  }
  *[sub-nav] > div {
    flex: 1;
  }
  .sub-header {
    font-size: 9px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    padding: 20px 0 4px 8px;
  }
  .accordion-toggle {
    padding: 8px;
  }
  .accordion {
    height: 0;
    overflow: hidden;
  }
  .accordion[open] {
    height: auto;
    padding: 0 0 8px 16px;
  }
  .accordion a {
    margin-bottom: 8px;
  }
  button jha-icon-chevron-down {
    transition: all linear 0.25s;
  }
  button[open] {
    outline: none;
  }
  button[open] jha-icon-chevron-down {
    transform: rotate(180deg);
  }
  jha-icon-popout {
    width: 16px;
    height: 16px;
    margin-left: 4px;
  }
  @media (max-width: 1024px) {
    nav {
      width: 270px;
      padding: 0 0 32px 16px;
    }
    nav::after {
      display: none;
    }
    jha-enterprise-fi-name {
      display: none;
    }
    header[desktop] {
      display: none;
    }
    header[mobile] {
      display: flex;
      border-bottom: 1px solid var(--jha-border-color);
      padding: 12px 0;
      cursor: pointer;
      margin-bottom: 20px;
    }
    header[mobile] *[icon],
    header[mobile] *[small] {
      margin-right: 12px;
    }
    header[mobile] jha-icon-chevron-left {
      margin-left: -10px;
      margin-right: 2px;
    }
    h3 {
      font-size: 16px;
      margin-bottom: 0;
    }
    a,
    .accordion-toggle {
      width: calc(100% - 16px);
    }
    button jha-icon-chevron-down {
      transition: all 0.25s cubic-bezier(.1,.5,.1,1);
    }
  }
`;

class JhaEnterpriseNavSecondary extends LitElement {
  static get styles() {
    return [jhaStyles, styles];
  }

  static get properties() {
    return {
      activeProjectNavigation: {type: Object},
      pathname: {type: String},
      routeChangedByAppUrl: {type: String}
    };
  }

  constructor() {
    super();
    this.pathname = '';
    this._boundNavigationEvent = this._handleNavigationEvent.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('tap', this._boundNavigationEvent, false);
    document.addEventListener('click', this._boundNavigationEvent, false);
    document.addEventListener('navigation-event', this._boundNavigationEvent, false);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('tap', this._boundNavigationEvent, false);
    document.removeEventListener('click', this._boundNavigationEvent, false);
    document.removeEventListener('navigation-event', this._handleNavigationEvent);
  }

  _handleNavigationEvent(subNavItem) {
    if (this.pathname !== window.location.pathname) {
      this.pathname = window.location.pathname;
    }
    if (subNavItem && subNavItem.navId) {
      this.dispatchEvent(new CustomEvent('reset-force-open-subnav', {
        bubbles: true,
        composed: true
      }));
    }
  }

  _handleReturnToPrimaryNav() {
    this.dispatchEvent(new CustomEvent('selected-project-change', {
      detail: {project: {}},
      bubbles: true,
      composed: true
    }));
  }

  toggleSubNav(navId) {
    // `toggleAttribute` is not supported in old chrome versions (i.e. Xperience browser)
    const navIdElem = this.shadowRoot.getElementById(navId);
    const navIdAccordianElem = this.shadowRoot.getElementById(`${navId}-accordion`);
    if (navIdElem.hasAttribute('open')) {
      navIdElem.removeAttribute('open');
      navIdAccordianElem.removeAttribute('open');
    } else {
      navIdElem.setAttribute('open', true);
      navIdAccordianElem.setAttribute('open', true);
    }
  }

  isActiveNavItem({route, hideSubnavOnDesktop, altActiveRoutes}, pathname) {
    const selectedInstitution = store.getSelectedInstitution();
    if (!selectedInstitution) return;
    const {institution: {shortId}} = selectedInstitution;
    const urlParts = pathname.split(`/${shortId}/`);
    let urlHasUUID = false;
    const activeRouteParts = [];
    (urlParts[1] || '').split('/').filter(p => {
      if (!this._isUUID(p)) {
        activeRouteParts.push(p);
        return true;
      }
      urlHasUUID = true;
      return false;
    });
    const subNavRoutePartsCount = route.split('/').length;
    let routesToCheck = [];
    routesToCheck.push(route);
    if (altActiveRoutes) {
      routesToCheck = routesToCheck.concat(Array.isArray(altActiveRoutes) ? altActiveRoutes : [altActiveRoutes]);
    }
    let isActive = false;
    const activeRoute = (urlHasUUID ? activeRouteParts.slice(0, subNavRoutePartsCount) : activeRouteParts).join('/');
    routesToCheck.forEach(route => {
      if (route === activeRoute) isActive = true;
    });
    if (isActive) {
      this._hideSecondaryNav(hideSubnavOnDesktop);
    }
    return isActive;
  }

  _isUUID(value) {
    return value.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i);
  }

  _hideSecondaryNav(hide) {
    this.dispatchEvent(new CustomEvent('hide-subnav', {
      detail: {hide},
      bubbles: true,
      composed: true
    }));
  }

  _subNavIsOpen({subNav}) {
    let isActive = false;
    subNav.forEach(navItem => {
      if (!navItem.header && this.isActiveNavItem(navItem, this.pathname)) {
        isActive = true;
      }
    });
    return isActive;
  }

  render() {
    return html`
      <nav>
        <jha-enterprise-fi-name></jha-enterprise-fi-name>
        <header desktop>
          <h3>
            ${this.activeProjectNavigation.displayName}
          </h3>
        </header>
        <header @click=${() => this._handleReturnToPrimaryNav()} mobile >
          <jha-icon-chevron-left></jha-icon-chevron-left>
          ${this.activeProjectNavigation.icon && html`${NAVIGATION_ICONS[this.activeProjectNavigation.icon]}`}
          <h3>
            ${this.activeProjectNavigation.displayName}
          </h3>
        </header>
        ${this.activeProjectNavigation.subNav.map(subNavItem => {
    if (!subNavItem.subNav) {
      if (subNavItem.header) {
        return html`<div class="sub-header">${subNavItem.text}</div>`;
      } else {
        return html`
        <a
          ?active="${this.isActiveNavItem(subNavItem, this.pathname)}"
          @click=${() => this._handleNavigationEvent(subNavItem)}
          href="${subNavItem.path}"
          target="_self">
          ${NAVIGATION_ICONS[subNavItem.icon]}
          ${subNavItem.displayName}
        </a>`;
      }
    }
    const subNavIsOpen = this._subNavIsOpen(subNavItem);
    return html`
            <button
              class="accordion-toggle"
              @click=${() => this.toggleSubNav(subNavItem.navId)}
              id="${subNavItem.navId}"
              ?open="${subNavIsOpen}"
              target="_self"
              sub-nav>
              <div>${subNavItem.displayName}</div>
              <jha-icon-chevron-down></jha-icon-chevron-down>
            </button>
            <div class="accordion" id="${subNavItem.navId}-accordion" ?open="${subNavIsOpen}">
              ${subNavItem.subNav.map(navItem => {
    if (navItem.header) {
      return html`<div class="sub-header">${navItem.text}</div>`;
    } else {
      return html`<a
        flex
        ?active="${this.isActiveNavItem(navItem, this.pathname)}"
        @click=${() => this._handleNavigationEvent(navItem)}
        href="${navItem.path}"
        target="${navItem.target || '_self'}">
        ${NAVIGATION_ICONS[navItem.icon]}
        <div>${navItem.displayName === 'Zelle' ? html`Zelle<sup>®</sup>` : navItem.displayName}</div>
        ${navItem.target && html`<jha-icon-popout></jha-icon-popout>`}
      </a>`;
    }
  })}
            </div>
          `;
  })}
      </nav>
    `;
  }
}

export {styles as JhaEnterpriseNavSecondaryStyles};

customElements.define('jha-enterprise-nav-secondary', JhaEnterpriseNavSecondary); // eslint-disable-line @banno/ux/custom-elements-define
export default JhaEnterpriseNavSecondary;
