/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconLogoutElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-logout';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M4.81,12.75H15a.75.75,0,0,0,0-1.5H4.81L7.53,8.53A.75.75,0,0,0,6.47,7.47L1.94,12l4.53,4.53a.75.75,0,1,0,1.06-1.06Z"/>
    <path d="M18.67,3.25H12.33a2.09,2.09,0,0,0-2.08,2.08V7a.75.75,0,0,0,1.5,0V5.33a.58.58,0,0,1,.58-.58h6.33a.58.58,0,0,1,.58.58V18.67a.58.58,0,0,1-.58.58H12.33a.58.58,0,0,1-.58-.58V17a.75.75,0,0,0-1.5,0v1.67a2.09,2.09,0,0,0,2.08,2.08h6.33a2.09,2.09,0,0,0,2.08-2.08V5.33A2.09,2.09,0,0,0,18.67,3.25Z"/>
    </svg>
  `;
  }
};

customElements.define(window.JhaIconLogoutElement.is,
    window.JhaIconLogoutElement);
export default window.JhaIconLogoutElement;
