/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconDevicesElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-devices';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M21,7.25H14a.75.75,0,0,0-.75.75V20a.75.75,0,0,0,.75.75h7a.75.75,0,0,0,.75-.75V8A.75.75,0,0,0,21,7.25Zm-.75,12h-5.5v-1.5h5.5Zm0-3H14.75V8.75h5.5v7.5Z"/>
    <path d="M6.75,4.75h9.5V6h1.5V4.75H19a.25.25,0,0,1,.25.25V6h1.5V5A1.75,1.75,0,0,0,19,3.25H4A1.75,1.75,0,0,0,2.25,5V15A1.75,1.75,0,0,0,4,16.75h8v-1.5H6.75ZM4,15.25A.25.25,0,0,1,3.75,15V5A.25.25,0,0,1,4,4.75H5.25v10.5Z"/>
    </svg>
  `;
  }
};

customElements.define(window.JhaIconDevicesElement.is,
    window.JhaIconDevicesElement);
export default window.JhaIconDevicesElement;
