/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconDeleteElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-delete';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24">
    <path d="M19,7.25H15.75V5A.75.75,0,0,0,15,4.25H9A.75.75,0,0,0,8.25,5V7.25H5a.75.75,0,0,0,0,1.5h.75V20A1.8,1.8,0,0,0,7.6,21.75h8.8A1.8,1.8,0,0,0,18.25,20V8.75H19a.75.75,0,0,0,0-1.5ZM9.75,5.75h4.5v1.5H9.75Zm7,14.25c0,.12-.14.25-.35.25H7.6c-.21,0-.35-.13-.35-.25V8.75h9.5Z"/>
    <path d="M10,18.75a.75.75,0,0,0,.75-.75V11a.75.75,0,0,0-1.5,0v7A.75.75,0,0,0,10,18.75Z"/>
    <path d="M14,18.75a.75.75,0,0,0,.75-.75V11a.75.75,0,0,0-1.5,0v7A.75.75,0,0,0,14,18.75Z"/>
    </svg>
  `;
  }
};

customElements.define(window.JhaIconDeleteElement.is,
    window.JhaIconDeleteElement);
export default window.JhaIconDeleteElement;
