import {LitElement, html, css} from 'lit-element';
import {jhaStyles} from '@banno/platform-ux-shared/styles/jha-styles';
import {NAVIGATION_ICONS} from './helpers/core-navigation';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-chevron-right.js';
import './jha-enterprise-nav-fi-logo';
import './jha-enterprise-nav-more-menu';
import './jha-enterprise-nav-secondary';
import './jha-enterprise-nav-user-menu';

const styles = css`
  @keyframes loading-animation {
    0% {
      background-position: 92% 0%;
    }
    50% {
      background-position: 9% 100%;
    }
    100% {
      background-position: 92% 0%;
    }
  }
  @keyframes secondary-nav-animation {
    0% {
      transform: translateX(30%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  :host {
    --jha-icon-color: #000;
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background: var(--jha-background-color);
    box-sizing: border-box;
  }
  nav {
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    width: 96px;
    border-right: 1px solid var(--jha-border-color);
    justify-content: space-between;
  }
  jha-enterprise-nav-fi-logo {
    padding-top: 16px;
  }
  #global-nav-items {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 96px;
  }
  #global-nav-items a {
    color: var(--jha-text-dark);
    cursor: pointer;
    margin-bottom: 8px;
  }
  #global-nav-items a:hover {
    background-color: var(--jha-color-gray-light);
    font-weight: 400;
    border-radius: 6px;
    text-decoration: none;
  }
  #global-nav-items a[active],
  #global-nav-items a:active {
    color: var(--jha-text-theme);
    font-weight: 500;
    background-color: rgba(1, 111, 230, 0.1);
    border-radius: 6px;
  }
  #global-nav-items a[active]:hover {
    color: var(--jha-text-dark);
    background-color: var(--jha-color-gray-light);
  }
  #global-nav-items a > div {
    width: 80px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 12px;
  }
  #global-nav-items a[loading] {
    animation: loading-animation 1.5s ease-in-out infinite;
    width: 100%;
    background: linear-gradient(45deg, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.10) 100%);
    background-size: 200% 200%;
    border-radius: 6px;
    color: var(--jha-text-theme);
  }
  #global-nav-items a[active]:hover div *[icon] {
    fill: var(--jha-text-dark);
  }
  #global-nav-items a[active] div *[icon],
  #global-nav-items a:active div *[icon],
  #global-nav-items a[loading] *[icon] {
    fill: var(--jha-text-theme);
  }
  #global-nav-items a jha-icon-chevron-right {
    display: none;
  }
  hr {
    display: none;
  }
  jha-enterprise-nav-secondary[mobile],
  jha-enterprise-nav-secondary[hide] {
    display: none;
  }
  @media (max-width: 1024px) {
    :host {
      width: 270px;
      padding: 0;
    }
    :host([projectselected]) #global-nav-items,
    :host([projectselected]) jha-enterprise-nav-more-menu {
      display: none;
    }
    jha-enterprise-nav-fi-logo {
      margin: 16px 12px 16px 16px;
      padding-top: 0;
    }
    hr {
      display: block;
      margin: 0 0 4px 8px;
      width: 100%;
    }
    hr.secondary-header {
      margin: 0 0 4px 16px
    }
    hr.secondary {
      margin: 16px 0 16px 8px;
    }
    nav {
      align-items: flex-start;
      position: relative;
      width: 270px;
    }
    #global-nav-items {
      flex: 0;
      width: 100%;
      align-items: flex-start;
      padding: 0 8px;
    }
    #global-nav-items a {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 0 8px;
      margin-bottom: 8px;
    }
    #global-nav-items a > div {
      flex: 1;
      height: 48px;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      font-size: 16px;
    }
    #global-nav-items a > div *[icon] {
      margin: 0 16px 0 0;
    }
    #global-nav-items a jha-icon-chevron-right {
      display: block;
    }
    jha-enterprise-nav-more-menu {
      padding-bottom: 60px;
    }
    jha-enterprise-nav-more-menu:hover {
      background-color: var(--jha-background-color);
      border-radius: 0;
    }
    jha-enterprise-nav-secondary[desktop],
    jha-enterprise-nav-secondary[hide] {
      display: none;
    }
    jha-enterprise-nav-secondary[mobile] {
      display: block;
    }
    :host([projectselected]) jha-enterprise-nav-secondary[mobile] {
      animation: secondary-nav-animation 0.3s cubic-bezier(.1,.5,.1,1);
    }
  }
`;

class JhaEnterpriseNavPrimary extends LitElement {
  static get styles() {
    return [jhaStyles, styles];
  }

  static get properties() {
    return {
      activeProjectName: {type: String},
      activeProjectNavigation: {type: Object},
      loggedInUser: {type: Object},
      mobileNavOpen: {type: Boolean},
      navigation: {type: Object},
      isSecondaryNavHidden: {type: Boolean},
      selectedProject: {type: String},
      routeChangedByAppUrl: {type: String}
    };
  }

  constructor() {
    super();
    this.isSecondaryNavHidden = false;
  }

  updated(changedProperties) {
    changedProperties.forEach((oldValue, propName) => {
      if (propName === 'activeProjectNavigation' && !oldValue && this.activeProjectNavigation) {
        if (this.activeProjectNavigation.hideSubnavOnDesktop) {
          this.dispatchEvent(new CustomEvent('hide-subnav', {
            detail: {hide: true},
            bubbles: true,
            composed: true
          }));
        }
      }
    });
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('selected-project-change', this._handleSelectedProjectChanged);
    this.addEventListener('hide-subnav', this._handleIsSecondaryNavHidden);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('selected-project-change', this._handleSelectedProjectChanged);
    this.removeEventListener('hide-subnav', this._handleIsSecondaryNavHidden);
  }

  calculatePathName(routeChangedByAppUrl) {
    return window.location.href;
  }

  _setSelectedProject(project) {
    if (project.hardRedirect) {
      location.href = project.path;
      return;
    }
    if (window.innerWidth > 1024) {
      location.href = this._getInitialProjectPath(project);
      return;
    }
    this.isSecondaryNavHidden = false;
    if (project.path && (!this.mobileNavOpen || (this.mobileNavOpen && !project.subNav))) {
      location.href = project.path;
      return;
    }
    if (this.activeProjectName !== (project.route || project.name)) {
      this.dispatchEvent(new CustomEvent('selected-project-change', {
        detail: {project},
        bubbles: true,
        composed: true
      }));
    }
  }

  _getInitialProjectPath(project) {
    if (project.subNav && project.subNav.length > 0) {
      return project.subNav.find(s => !s.header).path;
    }
    return project.path;
  }

  _handleSelectedProjectChanged() {
    // scroll to the top of the secondary menu when it's opened/changed
    this.shadowRoot.getElementById('nav-wrapper').scrollTop = 0;
    this.isSecondaryNavHidden = false;
  }

  _handleIsSecondaryNavHidden({detail: {hide}}) {
    this.isSecondaryNavHidden = hide;
  }

  render() {
    return html`
      <nav id="nav-wrapper">
        <jha-enterprise-nav-fi-logo></jha-enterprise-nav-fi-logo>
        ${this.navigation ? html`
          <div id="global-nav-items">
            <hr />
            ${this.navigation.projects.filter(p => !p.secondary).map(project => html`
              <a
                ?active="${(project.route || project.name) === this.activeProjectName}"
                @click=${() => this._setSelectedProject(project)}>
                <div>
                  ${NAVIGATION_ICONS[project.icon]}
                  <div>${project.displayName}</div>
                </div>
                ${project.subNav && html`
                  <jha-icon-chevron-right></jha-icon-chevron-right>`}
              </a>
            `)}
            ${this.activeProjectName ? null : html`<hr class="secondary" />`}
            <jha-enterprise-nav-more-menu
              .loggedInUser=${this.loggedInUser}
              .mobileNavOpen=${this.mobileNavOpen}
              .navigationProjects=${this.navigation.projects}>
            </jha-enterprise-nav-more-menu>
          </div>
        ` : html`
          <div id="global-nav-items">
            <a loading><div>&nbsp;</div></a>
          </div>
        `}
        <jha-enterprise-nav-user-menu
          .loggedInUser=${this.loggedInUser}>
        </jha-enterprise-nav-user-menu>
        ${(this.activeProjectNavigation || {}).subNav && html`
          <hr class="secondary-header"/>
          <jha-enterprise-nav-secondary
            .activeProjectNavigation=${this.activeProjectNavigation}
            .pathname=${this.calculatePathName(this.routeChangedByAppUrl)}
            mobile>
          </jha-enterprise-nav-secondary>`}
      </nav>
      ${(this.activeProjectNavigation || {}).subNav && html`
        <jha-enterprise-nav-secondary
          .activeProjectNavigation=${this.activeProjectNavigation}
          ?hide=${this.isSecondaryNavHidden}
          .pathname=${this.calculatePathName(this.routeChangedByAppUrl)}
          desktop>
        </jha-enterprise-nav-secondary>`}
    `;
  }
}

export {styles as JhaEnterpriseNavPrimaryStyles};

customElements.define('jha-enterprise-nav-primary', JhaEnterpriseNavPrimary); // eslint-disable-line @banno/ux/custom-elements-define
export default JhaEnterpriseNavPrimary;
