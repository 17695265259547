/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconReportsElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-reports';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M18.5,19V9a1,1,0,0,0-1-1h-1a1,1,0,0,0-1,1V19h-2V5a1,1,0,0,0-1-1h-1a1,1,0,0,0-1,1V19h-2V14a1,1,0,0,0-1-1h-1a1,1,0,0,0-1,1v5H3.25a.75.75,0,0,0-.75.75h0a.75.75,0,0,0,.75.75h17.5a.75.75,0,0,0,.75-.75h0a.75.75,0,0,0-.75-.75Z"/>
    </svg>
  `;
  }
};

customElements.define(window.JhaIconReportsElement.is,
    window.JhaIconReportsElement);
export default window.JhaIconReportsElement;
